const env = "prod";

const devConfig = Object.freeze({
  dev: true,
  COURSE_SVC_BASE_URL: "https://api-dev.cerah.co", //"http://localhost:48371",
  CONVERSATION_SVC_BASE_URL: "https://api-dev.cerah.co",
  USER_SVC_BASE_URL: "https://api-dev.cerah.co/users2", //"http://localhost:47186",
  ACCOUNTING_SVC_BASE_URL: "https://api-dev.cerah.co/accounting",
  ACCOUNTING2_SVC_BASE_URL: "https://api-dev.cerah.co/accounting2",
  TEACHER_SVC_BASE_URL: "https://api-dev.cerah.co", //"http://localhost:47390"
  DESK_SVC_BASE_URL: "https://api-dev.cerah.co/desk",
  WHATSAPP_SVC_BASE_URL: "https://api-dev.cerah.co/whatsapp", //"http://localhost:49221/whatsapp"
  QUIZ_SVC_BASE_URL: "https://api-dev.cerah.co",
  NOTIF_SVC_BASE_URL: "https://api-dev.cerah.co/notifications",
  CLASS_REQ_SVC_BASE_URL: "https://api-dev.cerah.co",
  PAYMENT_REQ_SVC_BASE_URL: "https://api-dev.cerah.co",
  CONFIG_SVC_BASE_URL: "https://api-dev.cerah.co/config",
  PRICING_SVC_BASE_URL: "https://api-dev.cerah.co",
});

const prodConfig = Object.freeze({
  dev: false,
  COURSE_SVC_BASE_URL: "https://api.cerah.co",
  CONVERSATION_SVC_BASE_URL: "https://api.cerah.co", // http://localhost:7113",
  USER_SVC_BASE_URL: "https://api.cerah.co/users2",
  ACCOUNTING_SVC_BASE_URL: "https://api.cerah.co/accounting",
  ACCOUNTING2_SVC_BASE_URL: "https://api.cerah.co/accounting2",
  TEACHER_SVC_BASE_URL: "https://api.cerah.co",
  DESK_SVC_BASE_URL: "https://api.cerah.co/desk",
  WHATSAPP_SVC_BASE_URL: "https://api.cerah.co/whatsapp",
  QUIZ_SVC_BASE_URL: "https://api.cerah.co",
  NOTIF_SVC_BASE_URL: "https://api.cerah.co/notifications",
  CLASS_REQ_SVC_BASE_URL: "https://api.cerah.co",
  PAYMENT_REQ_SVC_BASE_URL: "https://api.cerah.co",
  CONFIG_SVC_BASE_URL: "https://api.cerah.co/config",
  PRICING_SVC_BASE_URL: "https://api.cerah.co",
});

export const config = (env === "prod") ? prodConfig : devConfig;

import React from 'react';
import {usersStore} from "../store/usersStore";
import {observer} from "mobx-react";
import style from '../classRequests/classRequests.scss';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import * as moment from "moment";
import {Button} from "../controls/button";
import {Link} from "react-router-dom";
import {Spinner} from "../controls/spinner";
import {courseApi} from "../api/courseApi";
import {coursesStore} from "../store/coursesStore";

@observer
export class Renewals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      renewals: {},
    };
    this.loadState();
  }

  async loadState() {
    this.setState({
      loading: true,
      renewals: {},
    });
    const renewalsArray = await courseApi.getOpenRenewals();
    const renewals = {};
    renewalsArray.forEach(cr => renewals[cr._id] = cr);
    this.setState({
      renewals,
      loading: false
    });
  }

  render() {
    const st = this.state;
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.rightSidePanel}>
          {!this.props.closed &&
            <div className={style.topButtonPanel}>
              <Button className={style.classRequestButton}>
                <Link to="/renewals/new">
                  Add
                </Link>
              </Button>
            </div>
          }
          <div className={homeStyle.list}>
            <div className={style.classRequests}>
              {st.loading && <Spinner/>}

              {!st.loading &&
                  <>
                  {Object.values(st.renewals).map(renewal =>
                    <CourseRenewal key={renewal._id} renewal={renewal}/>
                  )}
                  </>
              }

              {!st.loading && Object.keys(st.renewals).length === 0 && <div>No pending course renewals</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CourseRenewal = observer(
  function CourseRenewal (props) {
    const renewal = props.renewal;
    const payer = renewal.userId && usersStore.getUserById(renewal.userId);
    const studentIds = (renewal.studentIds || [renewal.userId]);
    const students =  studentIds.map(stId => usersStore.getUserById(stId));
    const course = renewal.courseId && coursesStore.coursesById[renewal.courseId] || {};
    return (
      <div className={style.classRequest}>

        <div className={style.name}>{course.name} - {payer ? `${payer.name} ${payer.phone}` : '?'}</div>
        <div className={style.timestamp}>{moment(renewal.creationTime).format("DD-MMM HH:mm:ss")}</div>
        <div className={`${style.students} ${students.length > 1 ? style.multiple : ""}`}>
          {students.map(st => <div className={style.student}>{st ? st.name : '?'}</div>)}
        </div>

        <div>
          <a href={renewal.ticketUrl} target="_blank" onClick={e => e.stopPropagation()}>
            <Button className={style.classRequestButton} text="Ticket"/>
          </a>
          <Button className={style.classRequestButton}>
            <Link to={{pathname: `/renewals/${renewal._id}/withdrawal`, state: {courseRenewal: renewal}}}>
              Drop out
            </Link>
          </Button>
        </div>
      </div>
    );
  }
);
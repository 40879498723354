import React from 'react';
import style from './button.scss';

export function Button({onClick, className, text, children, noArrow, disabled}) {
  return (
    <div
       className={`${style.button} ${className} ${disabled ? style.disabled : ""}`}
       onClick={(ev) => {
         ev.stopPropagation();
         if (!disabled && onClick)
           onClick(ev);
       }}
    >
      {text && <div>{text}</div>}
      {children}
      {noArrow || <div className={style.arrow}/>}
    </div>
  );
}
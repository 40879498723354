import React from 'react';
import {Button} from "../controls/button";
import {Field, FieldArray} from "formik";
import style from './arrayField.scss';
import formStyle from './neatFormField.scss';

const ArrayFieldFormik = ({
  field: {name, value, onChange, onBlur}, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  values,
  ...props
}) => (
  <div className={formStyle.fieldContainer}>
    <div className={formStyle.label}>
      {props.label}
    </div>
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div className={style.arrayField}>
          {value && value.map((item, idx) => (
            <div key={idx} className={style.row}>
              <div className={style.itemWrapper}>
                {props.type ?
                  <Field type={props.type} name={`${name}[${idx}]`}/> :
                  (props.innerComp && <Field component={props.innerComp} name={`${name}[${idx}]`}/>)
                }
              </div>
              <Button className={style.addButton} onClick={() => arrayHelpers.remove(idx)} text="-" noArrow/>
              <Button className={style.addButton} onClick={() => arrayHelpers.insert(idx, props.defaultValue || '')} text="+" noArrow/>
            </div>
          ))}
          <Button className={style.addButton} onClick={() => arrayHelpers.push(props.defaultValue || '')} text="+" noArrow/>
        </div>
      )}
    />
  </div>
);

export function ArrayField(props) {
  return <Field {...props} component={ArrayFieldFormik} />;
}

import React from 'react';
import {usersStore} from "../store/usersStore";
import * as moment from 'moment-timezone';
import {useState, useEffect} from 'react';
import {useLocation, useParams} from "react-router";
import {Formik, Form} from "formik";
import {NeatFormField} from "../controls/neatFormField";
import {Button} from "../controls/button";
import {observer} from "mobx-react";
import history from "../history/history";
import {Spinner} from "../controls/spinner";
import {classRequestApi} from "../api/classRequestApi";
import {TestCallSchedule} from "../calendar/testCallSchedule";
import {logError} from "../errors/errorConsole";

export const TestCallEditor = observer(
  function TestCallEditor() {
    const [submitting, setSubmitting] = useState(false);
    const [suggestedCallMoment, setSuggestedCallMoment] = useState(moment());

    const {state} = useLocation();
    const student = usersStore.getUserById(state?.studentId);
    const classRequest = state?.classRequest;
    const trialTime = state?.trialTime;
    if (!classRequest) history.push('/courseProposals');

    useEffect(() => {calculateCallTime()}, [classRequest]);

    async function calculateCallTime() {
      let callMoment = moment();
      const existingOpenCalls = await classRequestApi.getActiveCallsForClassRequest(classRequest._id);
      if (existingOpenCalls.length > 0) {
        callMoment = moment(existingOpenCalls[0].startTime);
      } else if (trialTime) {
        callMoment = moment(trialTime).subtract(25, 'hours');
        if (callMoment.isBefore(moment())) {
          callMoment = moment();
        }
      }
      setSuggestedCallMoment(callMoment);
    }

    const call = {
      date: suggestedCallMoment.format("YYYY-MM-DD"),
      timezone: "Asia/Jakarta",
      time: suggestedCallMoment.format("HH:mm"),
    };

    return (<div style={{display: "flex", flexDirection: "horizontal"}}>
      <Formik
        initialValues={call}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({submitForm}) => (
          <Form style={{padding: 20, width: 200}}>
            TEST CALL with {student?.name}
            <NeatFormField name="timezone"
                           label="Timezone"
                           component="select">
              {moment.tz.names().map(tz =>
                <option value={tz}>{tz}</option>
              )}
            </NeatFormField>
            <NeatFormField name="date"
                           label="Date"
                           type="date" />
            <NeatFormField name="time"
                           label="Time"
                           type="time" />
            <Button onClick={!submitting && submitForm} noArrow>
              {submitting ? <Spinner/> : "Submit"}
            </Button>
          </Form>
        )}
      </Formik>

      <div style={{flex: 1}}>
        <TestCallSchedule />
      </div>

    </div>);

    async function submit({time, date, timezone}) {
      setSubmitting(true);
      try {
        const startTime = moment.tz(`${date} ${time}`, timezone);
        await classRequestApi.createTestCall({
          startTime: startTime.toISOString(),
          classRequestId: classRequest._id,
        });
        history.push('/courseProposals');
      } catch (e) {
        logError(`Failed to create testCall for ${student?.name}`, e);
      }
      setSubmitting(false);
    }
  }
);
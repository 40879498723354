import React from 'react';
import history from '../history/history';
import {UserFieldComponent} from "./userField";
import homeStyle from "../main/home.scss";
import {Menu} from "../menu/menu";


export function UserPicker(props) {
  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={homeStyle.rightSidePanel}>
        <UserFieldComponent label="Choose user to edit" onUserSelected={userId => {
          history.push(`/users/${userId}`)
        }}/>
      </div>
    </div>
  );
}
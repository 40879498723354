// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gPMvEQxn{padding:20px;max-width:1200px;position:relative}.gPMvEQxn .izBwtlO6{max-width:600px;position:relative}.gPMvEQxn .izBwtlO6 .WA4RB4C3{width:100px;height:160px;position:absolute;right:20px;top:-180px}.gPMvEQxn .izBwtlO6 .AmMv1T59{margin-top:160px}.gPMvEQxn .izBwtlO6 .vANDP7aN{margin:0;display:inline}.gPMvEQxn .izBwtlO6 .vANDP7aN input{display:inline;margin:5px;width:150px}.gPMvEQxn .vztz6ffN{margin:0;display:inline}.gPMvEQxn .vztz6ffN input{display:inline;margin:5px;width:calc(20% - 50px)}.gPMvEQxn .kGSQhH0r{display:block}.gPMvEQxn .mmEhKrUy{color:red}\n", "",{"version":3,"sources":["webpack://./src/teachers/teacherEditor.scss"],"names":[],"mappings":"AAAA,UACE,YAAa,CACb,gBAAiB,CACjB,iBAAkB,CAHpB,oBAMI,eAAgB,CAChB,iBAAkB,CAPtB,8BAUM,WAAY,CACZ,YAAa,CACb,iBAAkB,CAClB,UAAW,CACX,UAAW,CAdjB,8BAkBM,gBAAiB,CAlBvB,8BAsBM,QAAS,CACT,cAAe,CAvBrB,oCAyBQ,cAAe,CACf,UAAW,CACX,WAAY,CA3BpB,oBAkCI,QAAS,CACT,cAAe,CAnCnB,0BAqCM,cAAe,CACf,UAAW,CACX,sBAAuB,CAvC7B,oBA4CI,aAAc,CA5ClB,oBAgDI,SAAU","sourcesContent":[".teacherEditor {\n  padding: 20px;\n  max-width: 1200px;\n  position: relative;\n\n  .teacherInfo {\n    max-width: 600px;\n    position: relative;\n    \n    .teacherImg {\n      width: 100px;\n      height: 160px;\n      position: absolute;\n      right: 20px;\n      top: -180px;\n    }\n  \n    .form {\n      margin-top: 160px;\n    }\n  \n    .teacherExperience {\n      margin: 0;\n      display: inline;\n      input {\n        display: inline;\n        margin: 5px;\n        width: 150px;\n      }\n    }\n  }\n\n\n  .paymentDetails {\n    margin: 0;\n    display: inline;\n    input {\n      display: inline;\n      margin: 5px;\n      width: calc(20% - 50px);\n    }\n  }\n\n  .specialisation {\n    display: block;\n  }\n\n  .errorText {\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teacherEditor": "gPMvEQxn",
	"teacherInfo": "izBwtlO6",
	"teacherImg": "WA4RB4C3",
	"form": "AmMv1T59",
	"teacherExperience": "vANDP7aN",
	"paymentDetails": "vztz6ffN",
	"specialisation": "kGSQhH0r",
	"errorText": "mmEhKrUy"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f8rRerBU{position:fixed;left:0;right:0;top:0;bottom:0;display:flex;align-items:center;justify-content:center;background-color:rgba(0,0,0,0.5);z-index:1}.f8rRerBU .TrJdopoE{margin:20px;padding:40px;height:calc(100% - 40px);width:calc(100% - 40px);max-width:800px;max-height:600px;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:white;text-align:center}.f8rRerBU .SNi8BVE5{display:flex;justify-content:space-between;max-width:400px;width:100%;margin:20px}.f8rRerBU .DNCrSKSb,.f8rRerBU .egMvCVmJ{margin:0 20px;font-size:12px}.f8rRerBU .egMvCVmJ{background-color:#f00}\n", "",{"version":3,"sources":["webpack://./src/controls/confirmationDialog.scss"],"names":[],"mappings":"AAAA,UACE,cAAe,CACf,MAAO,CACP,OAAQ,CACR,KAAM,CACN,QAAS,CACT,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,gCAAoC,CACpC,SAAU,CAVZ,oBAaI,WAAY,CACZ,YAAa,CACb,wBAAyB,CACzB,uBAAwB,CACxB,eAAgB,CAChB,gBAAiB,CACjB,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CACvB,sBAAuB,CACvB,iBAAkB,CAxBtB,oBA4BI,YAAa,CACb,6BAA8B,CAC9B,eAAgB,CAChB,UAAW,CACX,WAAY,CAhChB,wCAoCI,aAAc,CACd,cAAe,CArCnB,oBAyCI,qBAAsB","sourcesContent":[".confirmationDialog {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 1;\n\n  .message {\n    margin: 20px;\n    padding: 40px;\n    height: calc(100% - 40px);\n    width: calc(100% - 40px);\n    max-width: 800px;\n    max-height: 600px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: white;\n    text-align: center;\n  }\n\n  .buttonPanel {\n    display: flex;\n    justify-content: space-between;\n    max-width: 400px;\n    width: 100%;\n    margin: 20px;\n  }\n\n  .btnYes, .btnNo {\n    margin: 0 20px;\n    font-size: 12px;\n  }\n\n  .btnNo {\n    background-color: #f00;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationDialog": "f8rRerBU",
	"message": "TrJdopoE",
	"buttonPanel": "SNi8BVE5",
	"btnYes": "DNCrSKSb",
	"btnNo": "egMvCVmJ"
};
export default ___CSS_LOADER_EXPORT___;

import {api} from "./api";
import * as moment from "moment";


export const accountingApi = {
  transferFunds: async (payment) => {
    return api.accountingSvcRequest('POST', '/fundTransfers', payment);
  },

  xrates: async() => {
    return api.courseSvcRequest("GET", `/exchangeRates?base=IDR&symbols=PHP,ZAR,GHS,USD,SGD,EUR,GBP`);
  },
  
  getUnrealizedSalesForPayer: async (userId, beforeMillis) => {
    return (await api.accounting2SvcRequest('GET', `/unrealizedSales?before=${moment(beforeMillis).toISOString()}&userIds=${userId}`))[userId];
  }
};

const sleep = async (ms) => new Promise((resolve, reject) =>  setTimeout(resolve, ms));

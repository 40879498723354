import React from 'react';
import {Field} from 'formik';

import style from './neatFormField.scss';

export function NeatFieldValue({label, value, ...props}) {
  return (
    <div className={style.fieldContainer}>
      <div className={style.label}>
        {label}
      </div>
      <div {...props}>
        {value}
        {props.children}
      </div>
    </div>
  );
}
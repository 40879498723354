import React, {useState} from 'react';
import {usersStore} from "../store/usersStore";
import {Button} from "../controls/button";
import {classRequestApi} from "../api/classRequestApi";
import history from "../history/history";
import {Spinner} from "../controls/spinner";
import style from "./teacherReplacementRequestWithdrawer.scss";
import {observer} from "mobx-react";
import {classRequestStates} from "../api/classRequestApi";
import {logError} from "../errors/errorConsole";
import {useLocation} from "react-router";
import {coursesStore} from "../store/coursesStore";

export const TeacherReplacementRequestWithdrawer = observer(
  function TeacherReplacementRequestWithdrawer() {
    const [submitting, setSubmitting] = useState(false);

    const {request} = useLocation().state;

    async function withdrawTeacherReplacementRequest() {
      setSubmitting(true);
      try {
        await classRequestApi.updateTeacherReplacementRequest({...request, state: classRequestStates.WITHDRAWN});
      } catch (e) {
        logError(`Failed to withdraw teacher replacement request ${request._id}`, e);
        setSubmitting(false);
        return;
      }
      history.push("/teacherReplacementRequests");
    }

    const studentName = request.userId && usersStore.getUserById(request.userId) && usersStore.getUserById(request.userId).name;
    const courseName = request.courseId && coursesStore.coursesById[request.courseId] && coursesStore.coursesById[request.courseId].name;
    return (
      <div>
        You're about to mark a teacher replacement request as <strong>WITHDRAWN</strong>:
        <br/>
        Course <strong>{courseName}</strong>
        <br/>
        Requested by {studentName}'s
        <br/>
        {submitting ?
          <Spinner/> :
          <Button className={style.reasonButton}
                  text="Confirm"
                  onClick={withdrawTeacherReplacementRequest}/>
        }
      </div>
    );
  }
);

import React, {useEffect, useState} from 'react';
import {userApi} from "../api/userApi";
import {logError} from "../errors/errorConsole";


export function GoogleContactsTokenUpdater() {
  const code = new URLSearchParams(window.location.search).get("code");
  const [sending, setSending] = useState(false);

  useEffect(() => postAuthCode(), []);

  async function postAuthCode() {
    setSending(true);
    try {
      await userApi.updateGoogleContactsApiToken(code);
    } catch (e) {
      logError("Failed to update google contacts token", e);
    }
    setSending(false);
  }

  return (
    <div>
      {sending && <div>I'm sending the token to user service...</div>}
      {!sending && <div>Token sent!</div>}
    </div>
  )
}
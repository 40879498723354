import {api} from './api';

export const deskApi = {
  searchDeskUserByEmail: async (email) => {
    return await api.deskSvcRequest('GET', `/users?email=${email}`);
  },

  mergeTickets: async (masterTicketId, slaveTicketIds) => {
    return await api.deskSvcRequest('POST', `/ticketMerges`, {
      masterTicketId,
      slaveTicketIds
    });
  },
};
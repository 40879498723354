import React from 'react';
import {courseApi} from "../api/courseApi";
import {teachersStore} from "../store/teachersStore";
import {observer} from "mobx-react";
import {coursesStore} from "../store/coursesStore";
import * as moment from "moment-timezone";
import style from './payslip.scss';
import logo from '../../img/cerah.png';
import {Button} from "../controls/button";
import {logError} from "../errors/errorConsole";
import {userSession} from "../api/userSession";
import {Spinner} from "../controls/spinner";
import history from "../history/history";
import {usersStore} from "../store/usersStore";
import {teacherApi} from "../api/teacherApi";

@observer
export class PayslipDisplay extends React.Component {
  constructor(props) {
    super(props);
    if (!props.match.params || !props.match.params.payslipId) {
      history.push('/teachers');
      return;
    }
    this.state = {
      isSubmitting: false
    };
    this.loadState();
    this.markPayslipAsPaid = this.markPayslipAsPaid.bind(this);
    this.cancelPayslip = this.cancelPayslip.bind(this);
    this.notifyTeacher = this.notifyTeacher.bind(this);
    this.payPayslip = this.payPayslip.bind(this);
  }

  async loadState() {
    const payslip = await courseApi.getPayslip(this.props.match.params.payslipId);
    let paymentAccounts;
    if (userSession.getUserGroups().includes("ADMINS")) {
      paymentAccounts = await teacherApi.getTeacherPaymentDetails(payslip.teacherId);
    }
    const meetingsByCourseId = {};
    payslip.meetings.forEach(m => {
      meetingsByCourseId[m.courseId] = meetingsByCourseId[m.courseId] || [];
      meetingsByCourseId[m.courseId].push(m);
    });
    this.setState({
      payslip,
      meetingsByCourseId,
      paymentAccounts
    });
  }

  render() {
    const st = this.state;
    if (!st.payslip) return null;
    const teacher = teachersStore.getTeacherById(st.payslip.teacherId);
    return (
      <div className={style.payslipDisplay}>
        <div className={style.inset}>
          {st.payslip.paid && <div className={style.paidStamp} onClick={() => history.push(`/payslips/${st.payslip._id}/invoice`)}>PAID</div>}
          <div className={style.mainTitle}>PAYMENT DETAILS FOR TUTOR</div>
          <img className={style.logo} src={logo}/>
          <div className={style.subTitle}>NAME: {teacher && teacher.name}</div>
          <div className={style.subTitle}>PAYSLIP ID: {st.payslip.identifier}</div>
          <table className={style.payslipTable}>
            <thead>
              <th>Course name</th>
              <th>Meeting time</th>
              <th>Roster</th>
              <th>Duration</th>
              <th>Payment (IDR)</th>
              <th>Total payment (IDR)</th>
            </thead>
            {Object.keys(st.meetingsByCourseId).map(cId => {
              const course = coursesStore.coursesById[cId];
              const courseMeetings = st.meetingsByCourseId[cId];
              return courseMeetings.map((m, idx) => <tr>
                {!idx && <td rowSpan={courseMeetings.length}>{course && course.name}</td>}
                <td className={style.numeric}>{course && moment(m.startTime).tz(course.timezone).format("YYYY-MM-DD HH:mm z")}</td>
                <td className={style.rosterCell}><Roster meeting={m}/></td>
                <td className={style.numeric}>{m.durationMins} min</td>
                <td className={style.numeric}>{m.fee.toLocaleString()}</td>
                {!idx && <td rowSpan={courseMeetings.length} className={`${style.numeric} ${style.total}`}>
                  {courseMeetings.reduce(totalFee, 0).toLocaleString()}
                </td>}
              </tr>);
            })}
            <tr>
              <td colSpan="5" className={style.bigTotal}>{st.payslip.paid ? "TOTAL TRANSFERRED" : "TOTAL TO TRANSFER"}</td>
              <td className={`${style.numeric} ${style.bigTotal}`}>
                {st.payslip.meetings.reduce(totalFee, 0).toLocaleString()}
              </td>
            </tr>
          </table>
        </div>
        {userSession.getUserGroups().includes("ADMINS") && <>
          {!st.payslip.notifiedToTeacher &&
            <Button noArrow={st.isSubmitting}
                    onClick={this.notifyTeacher}
                    disabled={st.isSubmitting}
                    text={!st.isSubmitting && "Notify teacher"}>
              {st.isSubmitting && <Spinner/>}
            </Button>
          }
          {!st.payslip.paid && <>
            {st.payslip.notifiedToTeacher && <>
              {st.paymentAccounts && st.paymentAccounts.accounts.length &&
                <Button noArrow
                        onClick={this.payPayslip}
                        disabled={st.isSubmitting}>
                  {st.isSubmitting && <Spinner/>}
                  {!st.isSubmitting && <>Pay <WiseLogo/></> }
                </Button>
              }
              <Button noArrow={st.isSubmitting}
                      onClick={this.markPayslipAsPaid}
                      disabled={st.isSubmitting}
                      text={!st.isSubmitting && "Mark as paid"}>
                {st.isSubmitting && <Spinner/>}
              </Button>
            </>}
            <Button noArrow
                    onClick={this.cancelPayslip}
                    disabled={st.isSubmitting}
                    className={style.cancelButton}
                    text={!st.isSubmitting && "Cancel payslip"}>
              {st.isSubmitting && <Spinner/>}
            </Button>
          </>}
        </>}
      </div>
    );
  }

  async cancelPayslip() {
    const st = this.state;
    if (!st.payslip) return;
    this.setState({isSubmitting: true});
    try {
      await courseApi.cancelPayslip(st.payslip._id);
      history.push(`/teachers`);
    } catch (e) {
      logError("Unable to cancel payslip", e);
    } finally {
      this.setState({isSubmitting: false});
    }
  }

  async notifyTeacher() {
    const st = this.state;
    if (!st.payslip) return;
    this.setState({isSubmitting: true});
    try {
      await courseApi.notifyPayslipToTeacher(st.payslip._id);
      history.push(`/teachers`);
    } catch (e) {
      logError("Unable to notify teacher", e);
    } finally {
      this.setState({isSubmitting: false});
    }
  }

  async payPayslip() {
    const st = this.state;
    if (!st.payslip) return;
    this.setState({isSubmitting: true});
    try {
      history.push("/transferConfirmation", {payslipId: st.payslip._id});
    } catch (e) {
      logError("Unable to pay payslip", e);
    } finally {
      this.setState({isSubmitting: false});
    }
  }

  async markPayslipAsPaid() {
    const st = this.state;
    if (!st.payslip) return;
    this.setState({isSubmitting: true});
    try {
      await courseApi.confirmPayslip(st.payslip._id);
      this.loadState();
    } catch (e) {
      logError("Unable to mark payslip as paid", e);
    } finally {
      this.setState({isSubmitting: false});
    }
  }
}

const Roster = observer(
  function Roster({meeting}) {
    const enrollees = coursesStore.coursesById[meeting.courseId]?.paidEnrolleeIdsByMeetingId[meeting._id];
    const attendees = meeting.report.attended;
    const attendeesNotEnrolledYet = attendees.filter(stId => !enrollees?.includes(stId));
    return <>
      {enrollees?.length > 0 && <div className={style.rosterHeader}>Paying students</div>}
      {enrollees?.map(stId =>
        <div className={style.student}>{attendees.includes(stId) ? "✔" : "❌"} {usersStore.getUserById(stId)?.name}</div>
      )}

      {attendeesNotEnrolledYet.length > 0 && <div className={style.rosterHeader}>Free trial students</div>}
      {attendeesNotEnrolledYet.filter(stId => !enrollees?.includes(stId)).map(stId =>
        <div className={style.student}>✔ {usersStore.getUserById(stId)?.name}</div>
      )}
    </>
  }
)

function WiseLogo() {
  return <div className={style.wiseLogo}/>
}

const totalFee = (acc, m) => acc + m.fee;

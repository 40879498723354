import React, { useState } from 'react';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import { quizApi } from '../api/quizApi';
import style from "./drills.scss";
import history from '../history/history';
import {Button} from "../controls/button";

export function Drills() {
  const [drills, setDrills] = useState([]);

  useState(() => {
    fetchDrills();
  });

  async function fetchDrills() {
    const drills = await quizApi.getDrillsInfo();
    setDrills(drills.sort((a, b) => a.difficulty - b.difficulty));
  }

  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={style.drills}>
        <span style={{fontSize: "24px"}}>Exercises</span>
        <span>
          <Button onClick={() => history.push("/drillEditor/new")}>add</Button>
        </span>
        <div className={style.drillsList}>
          {
            drills.map((drill, idx) => (
              <div key={idx} className={style.item} onClick={() => history.push(`/drillEditor/${drill._id}`)}>
                <span>{drill.difficulty} {drill.title}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
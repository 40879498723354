import React from 'react'
import {useFieldArray} from "react-hook-form";
import style from "../arrayField.scss";
import {Button} from "../button";

export function FieldArray({name, register, innerComp, control, defaultValue, label}) {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({name, control});
  const Comp = innerComp;
  
  return (
  <div className={style.arrayField}>
    {fields.map((field, index) => (
      <div key={field.id} className={style.row}>
        <Comp
          register={register}
          control={control}
          name={`${name}.${index}`}
        />
        <Button className={style.addButton} onClick={() => remove(index)} text="-" noArrow/>
        <Button className={style.addButton} onClick={() => insert(index, defaultValue || '')} text="+" noArrow/>
      </div>
    ))}
    <Button className={style.addButton} onClick={() => append(defaultValue || '')} text="+" noArrow/>
  </div>);
}

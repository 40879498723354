import React from 'react';
import {Formik, Form, Field} from 'formik';
import {courseApi} from "../api/courseApi";
import {logError} from "../errors/errorConsole";

export class PackageEditor extends React.Component {
  constructor(props) {
    super(props);
    if (!props.location.state || !props.location.state.course) {
      history.push('/courses');
      return;
    }
    this.course = this.props.location.state.course;

    this.pkg = (props.location.state && props.location.state.pkg) || {
      course: this.course._id,
      numHours: 12,
      price: 990000,
      currency: "IDR",
      visible: false
    };

    this.editing = !!this.pkg._id;
  }

  render() {
    return (
      <div>
        Price package for {this.course.name}
        <Formik
          initialValues={this.pkg}
          onSubmit={(pkg) => this.submit(pkg)}
        >
          <Form>
            <br/><label>Number of <strong>HOURS</strong></label><br/>
            <Field type="number" name="numHours" disabled={this.editing}/>
            <br/><label>price</label><br/>
            <Field type="number" name="price" disabled={this.editing}/>
            <br/><label>currency</label><br/>
            <Field component="select" name="currency" disabled={this.editing}>
              <option value="IDR">IDR</option>
              <option value="MYR">MYR</option>
              <option value="EUR">EUR</option>
              <option value="USD">USD</option>
              <option value="COP">COP</option>
              <option value="CLP">CLP</option>
              <option value="MXN">MXN</option>
              <option value="KRW">KRW</option>
              <option value="TWD">TWD</option>
              <option value="INR">INR</option>
              <option value="VND">VND</option>
              <option value="SGD">SGD</option>
            </Field>
            <br/><label>package type (optional)</label><br/>
            <Field type="text" name="type"/>
            <br/><label>package description (optional)</label><br/>
            <Field component="textarea" name="description"/>
            <br/><label>Publicly available</label><br/>
            <Field component="select" name="visible">
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </Field>
            <br/>
            <br/><label>Number of students in the course (required)</label><br/>
            <Field type="number" name="capacity" disabled={this.editing}/>
            <button type="submit">SUBMIT</button>
          </Form>
        </Formik>
      </div>
    );
  }

  async submit(pkg) {
    if (pkg.type === "") delete(pkg.type);
    if (pkg.description === "") delete(pkg.description);
    try {
      if (this.editing) {
        await courseApi.updatePackage(pkg);
      } else {
        await courseApi.createPackage(pkg);
      }
    } catch (e) {
      logError("Unable to create package", e);
    }
    history.back();
  }
}

import React, {useState, useEffect} from 'react';
import history from "../history/history";
import {usersStore} from "../store/usersStore";
import {observer} from "mobx-react";
import moment from "moment";
import style from "./trialEditor.scss";
import {classRequestApi} from "../api/classRequestApi";
import {Button} from "../controls/button";
import {Spinner} from "../controls/spinner";
import {logError} from "../errors/errorConsole";
import {courseApi} from "../api/courseApi";
import {extractUniquePayerAndStudents, PayerStudents} from './paymentRequestEditor';

export function TrialEditor({location}) {
  if (!location.state || !location.state.course) {
    history.push('/courses');
    return;
  }

  const course = location.state.course;
  const courseProposalId = course.courseProposalId;
  const [newRequesters, setNewRequesters] = useState([]);
  const [currentEnroleePayers, setCurrentEnroleePayers] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedPayerStudents, setSelectedPayerStudents] = useState(null);

  useEffect(() => {
    loadPayerStudents()
  }, []);

  return (
    <div>
      <p className={style.createTrialText}>Create a trial for <strong>{course.name}</strong></p>
      <p>Active Class Requests</p>
      {newRequesters.map(payerStudents =>
        <PayerStudents payerStudents={payerStudents}
                       onClick={() => setSelectedPayerStudents(payerStudents)}
                       selected={selectedPayerStudents && selectedPayerStudents._id === payerStudents._id}
        />
      )}
      <p>Former payers</p>
      {currentEnroleePayers.map(payerStudents =>
        <PayerStudents payerStudents={payerStudents}
                       onClick={() => setSelectedPayerStudents(payerStudents)}
                       selected={selectedPayerStudents && selectedPayerStudents._id === payerStudents._id}
        />
      )}

      {newRequesters.length === 0 && currentEnroleePayers.length === 0 &&
      <div style={{textAlign: "center"}}>
        Cannot find any Request for this Course
      </div>
      }

      <Button noArrow={isSubmitting}
              className={style.createTrialButton}
              onClick={submitCreateTrial}
              disabled={isSubmitting || selectedPayerStudents === null}
              text={!isSubmitting && "Create Trial"}>
        {isSubmitting && <Spinner/>}
      </Button>
    </div>
  );

  async function loadPayerStudents() {
    const openClassReqs = (await classRequestApi.findOpenClassRequestsForCourseProposal(courseProposalId))
    .map(cr => ({
      ...cr,
      studentIds: cr.studentIds || [cr.userId],
      type: "requester"
    }));
    const enrolments = await courseApi.getEnrolmentsByCourseId(course._id);
    const purchases = (await courseApi.getPurchasesForCourse(course._id)).filter(p => isEnrolled(enrolments.find(e => e.userId == p.studentIds[0]).dates));
    const uniquePayerStudents = extractUniquePayerAndStudents([...openClassReqs, ...purchases]);
    setNewRequesters(uniquePayerStudents.filter(tuple => tuple.type === "requester"));
    setCurrentEnroleePayers(uniquePayerStudents.filter(tuple => tuple.type !== "requester"));
  }

  function isEnrolled(enrolmentDates) {
    const idx = _.sortedIndex(enrolmentDates, new Date().toISOString());
    return !!(idx % 2);
  }

  async function submitCreateTrial() {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      await courseApi.createTrialPurchase({
        courseId: course._id,
        userId: selectedPayerStudents.userId,
        studentIds: selectedPayerStudents.studentIds || [selectedPayerStudents.userId]
      });
      history.push('/courses');
    } catch (e) {
      logError(`Error creating trial`, e);
    }
    this.setState({isSubmitting: false});
  }

}
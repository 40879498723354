import React from 'react';
import {courseApi} from "../api/courseApi";
import moment from 'moment-timezone';
import {Button} from "../controls/button";
import {Formik, Form, Field} from "formik";
import {Spinner} from "../controls/spinner";
import style from "./courseMeetings.scss";
import homeStyle from "../main/home.scss";
import {Menu} from "../menu/menu";
import history from "../history/history";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {logError} from "../errors/errorConsole";
import {usersStore} from "../store/usersStore";
import {observer} from "mobx-react";
import {teachersStore} from "../store/teachersStore";

export class CourseMeetings extends React.Component {
  constructor(props) {
    super(props);
    if (props.location.state && props.location.state.course) {
      this.course = props.location.state.course;
    } else if (!props.match.params.courseId) {
      history.push('/courses');
      return;
    }
    this.state = {
      loadingMeetings: true,
      addingMeetings: false
    };
    this.refreshMeetingList();
    this.addMeetings = this.addMeetings.bind(this);
    this.reschedulePlanned = this.reschedulePlanned.bind(this);
    this.submitBatch = this.submitBatch.bind(this);
  }

  async refreshMeetingList() {
    if (!this.course) {
      this.course = await courseApi.getCourse(this.props.match.params.courseId);
    }
    this.setState({
      loadingMeetings: true
    });
    const meetings = await courseApi.searchMeetingsByCourseId(this.course._id);
    const taughtCount = meetings && meetings.filter(m => m.state === "TAUGHT").length;
    const paidCount = meetings && meetings.filter(m => !!m.payslip).length;
    this.setState({
      loadingMeetings: false,
      meetings,
      taughtCount,
      paidCount
    });
  }

  render() {
    const st = this.state;
    const meetings = st.meetings || [];
    if (!this.course) {
      return <Spinner/>;
    }
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.list}>
          <div>Meetings for course {this.course.name}</div>
          <div>Meetings reported as <strong>taught</strong> from the start: {this.state.taughtCount}</div>
          <div>Meetings reportedly <strong>paid to teacher</strong>: {this.state.paidCount}</div>
          {meetings.map(m =>
            <Meeting
              meeting={m}
              tz={this.course.timezone}
              onResetReport={() => this.showPopupReportReset(m)}
              onReschedule={() => this.rescheduleMeeting(m, this.course.timezone)}
              onCancel={() => this.moveToEnd(m)}
              onDelete={() => this.showPopupMeetingDeletion(m)}
            />
          )}
          {!st.loadingMeetings && meetings.length === 0 && <div>No meetings yet</div>}
          {st.loadingMeetings && <Spinner/>}
          {!st.addingMeetings && !st.reschedulingMeetings && <Button text="Add" onClick={this.addMeetings}/>}
          {!st.addingMeetings && !st.reschedulingMeetings && <Button text="Reschedule planned" onClick={this.reschedulePlanned}/>}
          {st.addingMeetings && <MeetingBatch onSubmit={this.submitBatch}/>}
        </div>
        {this.state.meetingAwaitingDeletion &&
          <ConfirmationDialog
            message={this.getConfirmationMsgForMeeting(this.state.meetingAwaitingDeletion)}
            onConfirm={() => this.deleteMeeting(this.state.meetingAwaitingDeletion)}
            onReject={() => this.setState({meetingAwaitingDeletion: false})}
        />}
        {this.state.reportAwaitingReset &&
          <ConfirmationDialog
            message={this.getConfirmationMsgForReportReset(this.state.reportAwaitingReset)}
            onConfirm={() => this.resetMeetingReport(this.state.reportAwaitingReset)}
            onReject={() => this.setState({reportAwaitingReset: false})}
          />
        }
      </div>
    );
  }

  getConfirmationMsgForMeeting(m) {
    if (!m.startTime) {
      return `You're about to delete an unscheduled meeting. Are you sure??`;
    }
    const date = moment.tz(m.startTime, this.course.timezone).format("DD-MMM-YYYY");
    return `You're about to delete the ${date} meeting. Are you sure??`
  }

  getConfirmationMsgForReportReset(m) {
    delete m.report?.meetingId;
    delete m.report?.creationTime;
    const date = moment.tz(m.startTime, this.course.timezone).format("DD-MMM-YYYY");
    return `You are about to reset the report for the meeting on ${date}. Report: ${JSON.stringify(m.report)}. Are you sure?`;
  }

  addMeetings() {
    this.setState({addingMeetings: true});
  }

  rescheduleMeeting(m, tz) {
    history.push(`/meetings/${m._id}`, {meeting: m, timezone: tz});
  }

  async reschedulePlanned() {
    this.setState({reschedulingMeetings: true});
    await courseApi.batchReschedule({courseId: this.course._id});
    this.refreshMeetingList();
    this.setState({reschedulingMeetings: false});
  }

  async moveToEnd(m) {
    try {
      await courseApi.rescheduleMeeting({
        _id: m._id,
        durationMins: m.durationMins
        //no startTime so that the rescheduling is to the end of the list
      });
      this.refreshMeetingList();
    } catch (e) {
      logError(`Failed to move meeting ${m?._id} of ${m?.startTime?.toISOString()} to the end of the schedule`, e);
    }
  }

  showPopupMeetingDeletion(m) {
    this.setState({meetingAwaitingDeletion: m});
  }

  showPopupReportReset(m) {
    this.setState({reportAwaitingReset: m});
  }

  async deleteMeeting(m) {
    await courseApi.deleteMeeting(m);
    this.refreshMeetingList();
    this.setState({meetingAwaitingDeletion: false});
  }

  async resetMeetingReport(m) {
    delete m.report;
    m.state = "PLANNED";
    try {
      await courseApi.resetMeetingReport(m);
      this.refreshMeetingList();
    } catch (e) {
      logError(`Failed to reset report for meeting ${m?._id} of ${m?.startTime?.toISOString()}.`, e);
    }
    this.setState({reportAwaitingReset: false})
  }

  async submitBatch(batch) {
    await courseApi.createMeetingBatch({
      courseId: this.course._id,
      numMeetings: batch.numMeetings
    });
    await this.refreshMeetingList();
    this.setState({addingMeetings: false});
  }
}

@observer
class Meeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportShown: false
    };
    this.hideReport = this.hideReport.bind(this);
    this.showReport = this.showReport.bind(this);
    this.viewMeetingRecordings = this.viewMeetingRecordings.bind(this);
  }
  render () {
    const m = this.props.meeting;
    let day = "-";
    let date = "-";
    let time = "-";
    let zone = "-";
    if (m.startTime) {
      const mmnt = moment(m.startTime).tz(this.props.tz);
      day = mmnt.format("dddd");
      date = mmnt.format("DD-MMM-YY");
      time = mmnt.format("HH:mm");
      zone = mmnt.tz();
    }
    return (
      <div className={style.meeting}>
        <div>
          <div className={style.day}>{day}</div>
          <div className={style.date}>{date}</div>
        </div>
        <div>
          <div className={style.time}>{time} <small>{zone}</small></div>
          <div className={style.duration}>{m.durationMins} min</div>
        </div>
        {m.state === 'TAUGHT' && <>
            <div onMouseEnter={this.showReport}
                 onClick={this.showReport}
                 onMouseLeave={this.hideReport}
                 className={style.reported}>
              &#10004;
              {this.state.reportShown && <Report meeting={m}/>}
            </div>
            <div className={style.teacher}>{teachersStore.getTeacherById(m.teacherId)?.name}</div>
        </>}
        {m.report && m.report.attended && <div className={style.attendance}>
          <div>{m.report.attended.length} attendees</div>
          {m.report.attended.map(stId => {
            const u = usersStore.getUserById(stId);
            return <div>- {u && u.name && u.name.split(" ")[0]}</div>;
          })}
        </div>}
        {m.payslip && <div>PAID: payslip {m.payslip}</div>}
        {m.state === 'PLANNED' && moment().isAfter(moment(m.startTime)) && <div className={style.late}>
          <span className={style.symbol}>!</span>
        </div>}
        {m.state === 'CANCELLED' && <div className={style.cancelled}>&#10008;</div>}
        {m.state === 'RESCHEDULED' && <div className={style.rescheduled}>&gt;</div>}
        {m.state === 'PLANNED'
          && [
            <Button className={style.button} text="Reschedule" onClick={this.props.onReschedule}/>,
            <Button className={style.button} text="Move to last" onClick={this.props.onCancel}/>
          ]}
        {m.state === 'TAUGHT' && !m.payslip &&
          <Button className={style.button} text="Reset report" onClick={this.props.onResetReport}/>}
        {m.state !== 'TAUGHT' &&
          <Button className={style.button} text="Delete" onClick={this.props.onDelete}/>
        }
        {m.state === "TAUGHT" &&
          <Button className={style.button} text="View recordings" onClick={() => this.viewMeetingRecordings(m._id)}/>
        }
      </div>
    );
  }

  async viewMeetingRecordings(meetingId) {
    history.push(`/recordings/meetings/${meetingId}`);
  }

  showReport() {
    this.setState({reportShown: true});
  }

  hideReport() {
    this.setState({reportShown: false});
  }
}

function Report(props) {
  const r = props.meeting.report;
  return (
    <div className={style.report}>
      <p>{r.subject}</p>
      <p>{r.comments}</p>
      <p>Student's attention</p>
      <div style={{width: r.studentsAttention*25, height: 2, backgroundColor: "black"}}/>
      <p>Student's performance</p>
      <div style={{width: r.studentsPerformance*25, height: 2, backgroundColor: "#880000"}}/>
      <p>Student's participation</p>
      <div style={{width: r.studentsParticipation*25, height: 2, backgroundColor: "#000088"}}/>
    </div>
  );
}

function MeetingBatch(props) {
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={{numMeetings: ""}}
    >
      {({submitForm, isSubmitting}) =>
        <Form>
          <div>Enter the number of meetings to add</div>
          <Field type="number" name="numMeetings"/>
          <Button
              text={!isSubmitting && "Submit"}
              onClick={!isSubmitting && submitForm}
              disabled={isSubmitting}
              noArrow={isSubmitting}>
            {isSubmitting && <Spinner/>}
          </Button>
        </Form>
      }
    </Formik>
  );
}
import React from 'react';
import style from './removeButton.scss';

export function RemoveButton({onClick, children, disabled}) {
  return (
    <div
      className={style.button}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) onClick();
      }}
    >
      <div>X</div>
      {children}
    </div>
  );
}
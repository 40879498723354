import {config} from '../config';
import {userSession} from "./userSession";
import history from '../history/history';

export const api = {
  courseSvcRequest: apiRequest.bind(this, config.COURSE_SVC_BASE_URL),
  conversationSvcRequest: apiRequest.bind(this, config.CONVERSATION_SVC_BASE_URL),
  userSvcRequest: apiRequest.bind(this, config.USER_SVC_BASE_URL),
  accountingSvcRequest: apiRequest.bind(this, config.ACCOUNTING_SVC_BASE_URL),
  accounting2SvcRequest: apiRequest.bind(this, config.ACCOUNTING2_SVC_BASE_URL),
  teacherSvcRequest: apiRequest.bind(this, config.TEACHER_SVC_BASE_URL),
  deskSvcRequest: apiRequest.bind(this, config.DESK_SVC_BASE_URL),
  whatsappSvcRequest: apiRequest.bind(this, config.WHATSAPP_SVC_BASE_URL),
  quizSvcRequest: apiRequest.bind(this, config.QUIZ_SVC_BASE_URL),
  notificationsSvcRequest: apiRequest.bind(this, config.NOTIF_SVC_BASE_URL),
  classRequestSvcRequest: apiRequest.bind(this, config.CLASS_REQ_SVC_BASE_URL),
  paymentSvcRequest: apiRequest.bind(this, config.PAYMENT_REQ_SVC_BASE_URL),
  configSvcRequest: apiRequest.bind(this, config.CONFIG_SVC_BASE_URL),
  pricingSvcRequest: apiRequest.bind(this, config.PRICING_SVC_BASE_URL),
};

async function apiRequest(baseUrl, method, path, payload, headers) {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
    method
  };

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  const token = userSession.getToken();
  if (token) {
    options.headers["x-cerah-auth"] = `Bearer ${token}`
  }

  options.headers = {
    ...options.headers,
    ...headers   //passed-in headers override default ones
  };

  return fetch(new Request(`${baseUrl}${path}`, options))
  .then((response) => {
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        if (window.location.pathname !== "/signup")
          history.push('/login');
      }
      return Promise.reject(response);
    }
    if (response.status === 204) {
      return null;
    }
    const contentType = response.headers.get('Content-Type');
    if (contentType.includes("image/svg+xml") || contentType.includes("text/plain") ) {
      return response.text();
    }
    return response.json();
  });
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UvZlMD3D{position:absolute;bottom:0;width:100%;height:0}.UvZlMD3D .iWKxaoEs{position:absolute;bottom:0;left:4px;cursor:pointer}.UvZlMD3D .LaKvEuMz{position:absolute;bottom:0;right:4px;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/calendar/testCallSchedule.scss"],"names":[],"mappings":"AAAA,UACE,iBAAkB,CAClB,QAAS,CACT,UAAW,CACX,QAAS,CAJX,oBAOI,iBAAkB,CAClB,QAAS,CACT,QAAS,CACT,cAAe,CAVnB,oBAcI,iBAAkB,CAClB,QAAS,CACT,SAAU,CACV,cAAe","sourcesContent":[".rescheduleButtonsContainer {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  height: 0;\n\n  .selfRescheduleBtn {\n    position: absolute;\n    bottom: 0;\n    left: 4px;\n    cursor: pointer;\n  }\n\n  .rescheduleRedirectBtn {\n    position: absolute;\n    bottom: 0;\n    right: 4px;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rescheduleButtonsContainer": "UvZlMD3D",
	"selfRescheduleBtn": "iWKxaoEs",
	"rescheduleRedirectBtn": "LaKvEuMz"
};
export default ___CSS_LOADER_EXPORT___;

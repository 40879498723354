import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {courseApi} from "../api/courseApi";
import {EventsSchedule} from "./eventsSchedule";
import * as moment from "moment";
import {teachersStore} from "../store/teachersStore";
import {observer} from "mobx-react";
import {coursesStore} from "../store/coursesStore";
import {Button} from "../controls/button";
import {usersStore} from "../store/usersStore";

export const TeacherSchedule = observer(
  function TeacherSchedule() {
    const {teacherId} = useParams();
    const [weekOffset, setWeekOffset] = useState(0);
    const [meetings, setMeetings] = useState([]);

    const lastMonday = moment().startOf('isoWeek').add(weekOffset, 'week');
    const nextMonday = moment(lastMonday).add(1, 'week');

    async function loadMeetings() {
      const meetings = await courseApi.searchMeetingsByTeacherIdAndPeriod(teacherId, moment(lastMonday).subtract(1, 'millisecond'), nextMonday);
      setMeetings(meetings);
    }

    useEffect(() => {loadMeetings()}, [weekOffset]);

    const events = meetings.map(m => {
      const course = coursesStore.coursesById[m.courseId];
      const attendeeNames = course?.plannedMeetingsAttendeeIds[m.startTime]?.map(stId => {
        const attendee = usersStore.getUserById(stId)
        return `${attendee?.name.split(" ")[0] || ""} ${attendee?.phone || ""}`;
      }).join("\n") || "";
      return {
        startTime: m.startTime,
        durationMins: m.durationMins,
        name: `${course?.name}\n${attendeeNames}`
      }
    });

    return (
      <>
      <div style={{textAlign: "center"}}>{teachersStore.getTeacherById(teacherId)?.name}'s schedule {lastMonday.format("DD-MMM")} to {moment(nextMonday).subtract(1, 'day').format("DD-MMM")} (IN YOUR LOCAL TIME)</div>
      <Button text="Previous" onClick={() => setWeekOffset(weekOffset - 1)}/>
      <Button text="Next" onClick={() => setWeekOffset(weekOffset + 1)}/>
      <EventsSchedule events={events} startDate={lastMonday}/>
      </>
    );
  }
);
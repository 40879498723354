import {api} from './api';

export const configApi = {
  getWebappConfig: async () => {
    return api.configSvcRequest('GET', `/webappConfig/1`);
  },

  updateWebappConfig: async (config) => {
    return api.configSvcRequest('PUT', `/webappConfig/1`, config);
  }
};

import {api} from "./api";

export const classRequestStates = {
  ACTIVE: "ACTIVE",
  WITHDRAWN: "WITHDRAWN",
  PAID: "PAID"
};

export const courseProposalStates = classRequestStates;

export const withdrawalReasons = {
  PRICE_TOO_HIGH: "PRICE_TOO_HIGH",
  APP_COMPLICATED: "APP_COMPLICATED",
  NO_TEACHER: "NO_TEACHER",
  DISLIKES_METHOD: "DISLIKES_METHOD",
  TECHNICAL_ISSUE: "TECHNICAL_ISSUE",
  NO_CLASSMATES: "NO_CLASSMATES",
  WANTS_FREE: "WANTS_FREE",
  WANTS_OFFLINE: "WANTS_OFFLINE",
  SPEAKING_PANIC: "SPEAKING_PANIC",
  FOUND_ALTERNATIVE: "FOUND_ALTERNATIVE",
  WANTS_NATIVE: "WANTS_NATIVE",
  WANTS_UNICORN: "WANTS_UNICORN",
  OUR_BAD: "OUR_BAD",
  UNCONTACTABLE: "WE NEVER TALKED TO THIS PERSON (WRONG PHONE NUMBER OR SIMILAR)",
  JUST_LOOKING_AROUND: "JUST_LOOKING_AROUND",
};

export const classRequestApi = {
  findOpenClassRequests: async () => {
    return await api.classRequestSvcRequest('GET', `/classRequests?closed[$ne]=Y&creationTime[$gt]=${new Date("2020-02-03").getTime()}&$sort[creationTime]=1`);
  },

  findOpenClassRequestsForCourseProposal: async (courseProposalId) => {
    return await api.classRequestSvcRequest('GET', `/classRequests?closed[$ne]=Y&courseProposalId=${courseProposalId}&creationTime[$gt]=${new Date("2020-02-03").getTime()}&$sort[creationTime]=1`);
  },

  findClosedClassRequests: async () => {
    return await api.classRequestSvcRequest('GET', `/classRequests?closed=Y&creationTime[$gt]=${Date.now() - 86400000 * 90}&$sort[creationTime]=1`);
  },

  findOpenCourseProposals: async () => {
    return await api.classRequestSvcRequest('GET', '/courseProposals?finalState[$nin]=WITHDRAWN&finalState[$nin]=PAID&finalState[$nin]=TEACHER_REPLACED');
  },

  findClosedCourseProposals: async () => {
    return await api.classRequestSvcRequest('GET', '/courseProposals?finalState[$in]=WITHDRAWN&finalState[$in]=PAID&finalState[$in]=TEACHER_REPLACED');
  },

  findOpenGroupCourseProposals: async (searchStr) => {
    if (searchStr.length < 3) return [];
    return await api.classRequestSvcRequest('GET', `/courseProposals?finalState[$ne]=WITHDRAWN&contains=${searchStr}`);
  },

  getClassRequest: async (classRequestId) => {
    return await api.classRequestSvcRequest('GET', `/classRequests/${classRequestId}`);
  },

  updateClassRequest: async (classRequest) => {
    return await api.classRequestSvcRequest('PUT', `/classRequests/${classRequest._id}`, classRequest);
  },

  getClassRequestsByIds: async (classRequestIds) => {
    const classRequestIdsBatches = []
    for (let i = 0; i < classRequestIds.length; i += 100 ) {
      classRequestIdsBatches.push(classRequestIds.slice(i, i + 100));
    }
    const classRequests = []
    await Promise.all(classRequestIdsBatches.map(async idsBatch => {
      const classRequestBatch = await api.classRequestSvcRequest('GET', `/classRequests?ids=${idsBatch.join(',')}`);
      classRequests.push(...classRequestBatch);
    }));
    return classRequests;
  },

  createTestCall: async (testCall) => {
    return await api.classRequestSvcRequest('POST', `/testCalls`, testCall);
  },

  getNotCancelledTestCallsByClassRequestsIds: async (classRequestIds) => {
    let crIdsToBatch = classRequestIds.slice(0);
    let batches = [];
    do {
      batches.push(crIdsToBatch.slice(0, 100));
      crIdsToBatch = crIdsToBatch.slice(100);
    } while (crIdsToBatch.length > 0);
    return (await Promise.all(
      batches.map(bat => api.classRequestSvcRequest('GET', `/testCalls?success[$ne]=CANCELLED&classRequestId[$in]=dummy&${bat.map(crId => `classRequestId[$in]=${crId}`).join('&')}`))
    )).flat();
  },

  getActiveCallsForClassRequest: async (classRequestId) => {
    return await api.classRequestSvcRequest('GET', `/testCalls?classRequestId=${classRequestId}&success[$nin]=Y&success[$nin]=N&success[$nin]=CANCELLED`);
  },

  getTestNotCancelledCallsWithinTimeRange: async (from, to) => {
    return await api.classRequestSvcRequest('GET', `/testCalls?startTime[$gt]=${from}&startTime[$lte]=${to}&$sort[creationTime]=1&success[$ne]=CANCELLED`);
  },

  markCallAsSuccessful: async (call) => {
    return await api.classRequestSvcRequest('POST', `/testCallSuccesses`, {testCallId: call._id});
  },

  createClassRequest: async (classRequest) => {
    return await api.classRequestSvcRequest('POST', `/classRequests`, classRequest);
  },

  getCourseProposal: async (courseProposalId) => {
    return await api.classRequestSvcRequest('GET', `/courseProposals/${courseProposalId}`);
  },

  updateCourseProposal: async (courseProposal) => {
    return await api.classRequestSvcRequest('PUT', `/courseProposals/${courseProposal._id}`, courseProposal);
  },

  createCourseProposal: async (courseProposal) => {
    return await api.classRequestSvcRequest('POST', `/courseProposals`, courseProposal);
  },

  createTeacherReplacementRequest:async (trr) => {
    return await api.classRequestSvcRequest('POST', `/teacherReplacementRequests`, trr);
  },

  getTeacherReplacementRequest: async (teacherReplacementRequestId) =>  {
    return await api.classRequestSvcRequest('GET', `/teacherReplacementRequests/${teacherReplacementRequestId}`);
  },

  updateTeacherReplacementRequest: async (trr) => {
    return await api.classRequestSvcRequest('PUT', `/teacherReplacementRequests/${trr._id}`, trr);
  },

  findOpenTeacherReplacementRequests: async() => {
    return await api.classRequestSvcRequest('GET', `/teacherReplacementRequests?closed[$ne]=Y&$sort[creationTime]=1`);
  },

  acceptTeacher: async(courseProposalId, teacherId) => {
    return await api.classRequestSvcRequest('POST', '/my/teacherAcceptances', {courseProposalId, teacherId});
  },
  
  markClassRequestAsFake: async (cr, reason) => {
    return await api.classRequestSvcRequest('POST', `/spuriousClassRequests`, {classRequestId: cr._id, reason})
  },
  
  markClassRequestAsPromising: async (cr) => {
    return await api.classRequestSvcRequest('POST', `/promisingClassRequests`, {classRequestId: cr._id})
  },

  getClassRequestGuestTokens: async(classRequestId, expirationSeconds) => {
    return await api.classRequestSvcRequest('GET', `/classRequestGuestTokens?classRequestId=${classRequestId}&expirationSeconds=${expirationSeconds}`);
  },

  getShortenedUrl: async(url) => {
    return await api.classRequestSvcRequest('POST', '/shortUrls', {url});
  }
};

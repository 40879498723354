import React from 'react';
import {usersStore} from "../store/usersStore";
import {observer} from "mobx-react";
import style from '../classRequests/classRequests.scss';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import * as moment from "moment";
import {Button} from "../controls/button";
import {Link} from "react-router-dom";
import {Spinner} from "../controls/spinner";
import {useState, useEffect} from "react";
import {teacherApi} from "../api/teacherApi";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {logError} from "../errors/errorConsole";

export const TeacherApplications = observer(
  function TeacherApplications() {
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState([]);

    function loadState() {
      setLoading(true);
      (async () => {
        setApplications(await teacherApi.getOpenApplications());
        setLoading(false);
      })();
    }

    useEffect(() => loadState(), []);

    const {interviewAppointed, cvRequested, assessmentPending, testOngoing, prepCallRequested} = classifyApplications(applications);

    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.rightSidePanel}>
          <div className={homeStyle.list}>
            <div className={style.classRequests}>
              {loading && <Spinner/>}

              {!loading &&
                <>
                <div className={style.subsectionHeader}>Interview appointed - {interviewAppointed.length}</div>
                {interviewAppointed.map(app =>
                  <TeacherApplication key={app._id} application={app} onChange={loadState}/>
                )}
                <div className={style.subsectionHeader}>Prep Call - {prepCallRequested.length}</div>
                {prepCallRequested.map(app =>
                  <TeacherApplication key={app._id} application={app} onChange={loadState}/>
                )}
                <div className={style.subsectionHeader}>CV requested - {cvRequested.length}</div>
                {cvRequested.map(app =>
                  <TeacherApplication key={app._id} application={app} onChange={loadState}/>
                )}
                <div className={style.subsectionHeader}>Awaiting essay assessment - {assessmentPending.length}</div>
                {assessmentPending.map(app =>
                  <TeacherApplication key={app._id} application={app} onChange={loadState}/>
                )}
                <div className={style.subsectionHeader}>Test ongoing - {testOngoing.length}</div>
                {testOngoing.map(app =>
                  <TeacherApplication key={app._id} application={app} onChange={loadState}/>
                )}
                </>
              }

              {!loading && applications.length === 0 && <div>No pending teacher applications</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
);


function classifyApplications(applications) {
  const interviewAppointed = [], cvRequested = [], assessmentPending = [], testOngoing = [], prepCallRequested = [];
  applications.forEach(app => {
    if (app.demoChatUrl) {
      interviewAppointed.push(app);
    } else if (app.interviewChatUrl) {
      prepCallRequested.push(app);
    } else if (app.cvRequested) {
      cvRequested.push(app);
    } else if (app.multChoiceAnswered) {
      assessmentPending.push(app);
    } else {
      testOngoing.push(app);
    }
  });
  return {interviewAppointed, cvRequested, assessmentPending, testOngoing, prepCallRequested};
}


const TeacherApplication = observer(
  function TeacherApplication(props) {
    const [rejecting, setRejecting] = useState(false);
    const [rejectingForever, setRejectingForever] = useState(false);
    const [rejectingForPlagiarism, setRejectingForPlagiarism] = useState(false);
    const [movingToProbation, setMovingToProbation] = useState(false);
    const [hiring, setHiring] = useState(false);
    const [dropping, setDropping] = useState(false);
    const [requestingCV, setRequestingCV] = useState(false);
    const app = props.application;
    const user = app.userId && usersStore.getUserById(app.userId);
    return (
      <>
      <div className={style.classRequest}>
        <div className={style.name}>{user && user.name || '?'}</div>
        <div className={style.timestamp}> - {user && user.email || '?'} - </div>
        {app.multChoiceAnswered && <div className={style.level}>{`${app.multChoiceCorrect} / ${app.multChoiceAnswered}`}</div>}
        <div className={style.timestamp}>{moment(app.creationTime).format("DD-MMM HH:mm:ss")}</div>
        {user && user.campaign && <div className={style.campaign}>{user.campaign}</div>}
        <div className={style.buttonPanel}>
          <a href={app.ticketUrl} target="_blank" onClick={e => e.stopPropagation()}>
            <Button className={style.classRequestButton} text="Ticket"/>
          </a>
          {app.multChoiceAnswered && !app.demoChatUrl &&
            <>
            <Button className={style.classRequestButton}
                    text="Reject on test"
                    onClick={() => setRejecting(true)}
            />
            <Button className={style.plagiarismButton}
                    text="Plagiarised"
                    onClick={() => setRejectingForPlagiarism(true)}
            />
            {!app.cvRequested &&
              <Button className={style.classRequestButton}
                      onClick={requestCv}>
                {requestingCV ? <Spinner/> : "Request CV"}
              </Button>
            }
            {app.cvRequested && !app.interviewChatUrl &&
              <Button className={style.classRequestButton}>
                <Link to={{pathname: `/teacherApplications/${app._id}/teacherApplicationSchedule/new`, state: {application: app, isPrepCall: true}}}>
                  To prep call
                </Link>
              </Button>
            }
            </>
          }
          {app.interviewChatUrl && !app.demoChatUrl &&
            <>
            <Button className={style.classRequestButton}>
              <Link to={{pathname: `/teacherApplications/${app._id}/teacherApplicationSchedule/new`, state: {application: app}}}>
                To interview
              </Link>
            </Button>
            </>
          }
          {app.demoChatUrl &&
            <>
            <Button className={style.classRequestButton}
                    text="Reject candidate FOREVER"
                    onClick={() => {
                      app.rejectedForever = true;
                      setRejectingForever(true)
                    }}
            />
            <Button className={style.classRequestButton}
                    text="Reject and invite to reapply"
                    onClick={() => setRejecting(true)}
            />
            <Button className={style.classRequestButton}
                    text="To probation"
                    onClick={() => setMovingToProbation(true)}
            />
            <Button className={style.classRequestButton}
                    text="Hire"
                    onClick={() => setHiring(true)}
            />
            </>
          }
          <Button className={style.classRequestButton}
                  text="Drop silently"
                  onClick={() => setDropping(true)}
          />
        </div>
      </div>
      {rejecting &&
        <ConfirmationDialog
          message={`This will immediately send an email to ${user && user.name} notifying them that they've been REJECTED. Are you sure you want to continue?`}
          confirmText="YES"
          rejectText="NO"
          onConfirm={rejectApplication}
          onReject={() => setRejecting(false)}
        />
      }
      {rejectingForever &&
        <ConfirmationDialog
          message={`This will immediately send an email to ${user && user.name} notifying them that they've been REJECTED FOREVER. Are you sure you want to continue?`}
          confirmText="YES"
          rejectText="NO"
          onConfirm={rejectApplication}
          onReject={() => setRejectingForever(false)}
        />
      }
      {rejectingForPlagiarism &&
        <ConfirmationDialog
          message={`This will immediately send an email to ${user && user.name} notifying them that they have PLAGIARIZED THEIR ESSAY. Are you sure you want to continue?`}
          confirmText="YES"
          rejectText="NO"
          onConfirm={rejectForPlagiarsm}
          onReject={() => setRejectingForPlagiarism(false)}
        />
      }
      {dropping &&
        <ConfirmationDialog
          message={`This will close the teacher application without letting the candidate know. Are you sure you want to continue?`}
          confirmText="YES"
          rejectText="NO"
          onConfirm={dropApplication}
          onReject={() => setDropping(false)}
        />
      }
      {movingToProbation &&
        <ConfirmationDialog
          message={`This will immediately send an email to ${user && user.name} notifying them that they are in PROBATION. Are you sure you want to continue?`}
          confirmText="YES"
          rejectText="NO"
          onConfirm={moveToProbation}
          onReject={() => setMovingToProbation(false)}
        />
      }
      {hiring &&
        <ConfirmationDialog
          message={`This will immediately send an email to ${user && user.name} notifying them that they've been HIRED. Are you sure you want to continue?`}
          confirmText="YES"
          rejectText="NO"
          onConfirm={hire}
          onReject={() => setHiring(false)}
        />
      }
      </>
    );

    async function requestCv() {
      if (requestingCV) return;
      setRequestingCV(true);
      try {
        await teacherApi.requestCV(app);
        props.onChange && props.onChange();
      } catch (e) {
        logError(`Failed to request CV for application ${app._id}`, e);
      }
      setRequestingCV(false);
    }

    async function rejectApplication() {
      try {
        if (app.demoChatUrl) {
          await teacherApi.rejectAfterInterview(app);
        } else {
          await teacherApi.rejectBeforeInterview(app);
        }
        props.onChange && props.onChange();
      } catch (e) {
        logError(`Failed to reject application ${app._id}`, e);
      }
      setRejecting(false);
    }

    async function rejectForPlagiarsm() {
      try {
        await teacherApi.rejectForPlagiarsm(app);
        props.onChange && props.onChange();
      } catch (e) {
        logError(`Failed to reject application ${app._id}`, e);
      }
      setRejecting(false);
    }

    async function dropApplication() {
      try {
        await teacherApi.dropApplication(app);
        props.onChange && props.onChange();
      } catch (e) {
        logError(`Failed to drop application ${app._id}`, e);
      }
    }

    async function moveToProbation() {
      try {
        await teacherApi.moveToProbation(app);
        props.onChange && props.onChange();
      } catch (e) {
        logError(`Failed to move applicant to probation for application ${app._id}`, e);
      }
    }

    async function hire() {
      try {
        await teacherApi.hire(app);
        props.onChange && props.onChange();
      } catch (e) {
        logError(`Failed to hire applicant for application ${app._id}`, e);
      }
    }
  }
);

import React from 'react';
import {quizApi} from "../api/quizApi";
import {courseApi} from "../api/courseApi";
import * as moment from "moment";
import style from "../controls/blueTable.scss";

export class CourseNpsComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      npsAttempts: [],
      course: {}
    };
    this.loadState();
  }

  async loadState() {
    const courseId = this.props.match.params.courseId;
    const course = await courseApi.getCourse(courseId);
    const npsAttempts = await quizApi.getNpsAttemptsForCourse(courseId);
    const meetings = (await courseApi.searchMeetingsByCourseId(courseId)).filter(m => m.state === "TAUGHT");
    const meetingTimes = meetings.map(m => moment(m.startTime).valueOf());
    npsAttempts.forEach(a =>
      a.meetingsCompleted = meetingTimes.filter(mt => mt < a.startTimeMillis).length
    );
    npsAttempts.sort((a, b) => a.startTimeMillis - b.startTimeMillis);
    this.setState({
      course,
      npsAttempts,
      meetings
    });
  }

  render() {
    return (
      <div>
        NPS comments for course {this.state.course.name}
        <table className={style.blueTable}>
          <thead>
            <th>meetings</th>
            <th>date</th>
            <th>score</th>
            <th>comment</th>
            <th>user</th>
          </thead>
          {this.state.npsAttempts.map(att =>
            (<tr>
              <td>{att.meetingsCompleted}</td>
              <td style={{fontFamily: "sans-serif"}}>{moment(att.startTimeMillis).format("DD-MM-YYYY HH:mm:ss")}</td>
              <td>{att.answers[0].text}</td>
              <td>{att.answers[1] && att.answers[1].text}</td>
              <td>{att.params.userId}</td>
            </tr>)
          )}
        </table>
      </div>
    )
  }
}
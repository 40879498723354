import React from 'react';
import {courseApi} from "../api/courseApi";
import history from "../history/history";
import homeStyle from '../main/home.scss';
import style from './courseEditor.scss';
import {Menu} from "../menu/menu";
import {Schedule} from "./schedule";
import {Formik, Form, Field} from "formik";
import * as moment from "moment-timezone";
import {NeatFormField} from "../controls/neatFormField";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {TeacherSelector} from "./teacherSelector";
import {classRequestApi} from "../api/classRequestApi";
import {coursesStore} from "../store/coursesStore";
import {CoursePackages} from "./coursePackages";
import {logError} from "../errors/errorConsole";
import {NeatFieldValue} from "../controls/neatFieldValue";

export const availableSubjects = [
  {value: "english.bareng.A1", label: "Group: Gen. English - A1"},
  {value: "english.bareng.A2", label: "Group: Gen. English - A2"},
  {value: "english.bareng.B1", label: "Group: Gen. English - B1"},
  {value: "english.bareng.B2", label: "Group: Gen. English - B2"},
  {value: "english.bareng.C1", label: "Group: Gen. English - C1"},
  {value: "english.bareng.IELTS", label: "Group: IELTS"},
  {value: "english.conversation", label: "Conversation"},
  {value: "english.general", label: "General English"},
  {value: "english.business", label: "Business English"},
  {value: "english.IELTS", label: "IELTS prep"},
  {value: "english.TOEFL", label: "TOEFL prep"},
  {value: "english.APTIS", label: "APTIS prep"},
  {value: "english.essay", label: "Essay writing for applications"},
  {value: "bahasa.general", label: "Indonesian"},
  {value: "mandarin.general", label: "Mandarin"},
  {value: "german.general", label: "German"},
  {value: "event.IELTSsim", label: "Event - IELTS simulation"},
  {value: "event.study-abroad", label: "Event - Advice to study abroad"},
  {value: "event.speaking", label: "Event - Speaking activity"},
];

export const availableLevels = [
  {value: "A1", label: "A1"},
  {value: "A2", label: "A2"},
  {value: "B1", label: "B1"},
  {value: "B2", label: "B2"},
  {value: "C1", label: "C1"},
  {value: "C2", label: "C2"},
];

export class CourseEditor extends React.Component {
  constructor(props) {
    super(props);
    const initialCourse = {
      schedule: [],
      timezone: "Asia/Jakarta",
      type: "privat",
      location: {
        address: ""
      },
      teacher: "",
      subject: "english.bareng.A1",
      openForEnrolment: false,
      wontContinue: false,
      acceptedAges: null,
    };
    const st = props.location.state;
    if (st && st.course) {
      Object.assign(initialCourse, st.course);
      if (st.course.acceptedAges)
        initialCourse.acceptedAges = JSON.stringify(initialCourse.acceptedAges);
    }
    this.state = {
      course: initialCourse,
    };
    const courseId = props.match.params.courseId;
    if (courseId !== 'new') {
      (async () => {
        this.setState({
          course: this.dtoFromGet2ViewModel(await courseApi.getCourse(courseId)),
        });
      })();
    }
    this.submit = this.submit.bind(this);
  }

  render() {
    const course = this.state.course;
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.list}>
          <div className={style.courseEditor}>
            Course
            <Formik
              initialValues={course}
              onSubmit={this.submit}
              enableReinitialize={true}
            >
              <Form>
                <NeatFormField name="name" type="text" label="Name"/>

                <NeatFieldValue name="location"
                                label="Location"
                                value={course && course.location.name} />

                <NeatFormField name="acceptedAges"
                               label="Ages Accepted"
                               component="select">
                  <option value="null">unset</option>
                  <option value="[0,3]">0-3</option>
                  <option value="[4,7]">4-7</option>
                  <option value="[8,11]">8-11</option>
                  <option value="[12,17]">12-17</option>
                  <option value="[18,23]">18-23</option>
                  <option value="[24,99]">adult</option>
                </NeatFormField>

                <NeatFormField name="capacity"
                               label="Number of students (or maximum group size for public courses)"
                               type="number">
                </NeatFormField>

                <NeatFormField name="teacherId"
                               label="Teacher"
                               component={TeacherSelector}/>

                <NeatFormField name="startDate"
                               label="Start date"
                               type="date"/>

                <NeatFormField name="expectedDurationHours"
                               label="Expected duration (HOURS) (optional)"
                               type="number"/>

                <NeatFormField name="timezone"
                               label="Timezone"
                               component="select">
                  {moment.tz.names().map(tz =>
                    <option value={tz}>{tz}</option>
                  )}
                </NeatFormField>

                <NeatFormField name="type"
                               label="Type"
                               component="select">
                  <option value="onlineGroup">CerahOnline public group</option>
                  <option value="onlinePrivate">CerahOnline private (group or individual)</option>
                </NeatFormField>

                <NeatFormField name="subject"
                               label="Subject"
                               component="select">
                  {availableSubjects.map(subj =>
                    <option value={subj.value}>{subj.label}</option>)}
                </NeatFormField>

                <NeatFormField name="openForEnrolment"
                               label="Open for enrolment"
                               component="select">
                  <option value={true}>Open</option>
                  <option value={false}>Closed</option>
                </NeatFormField>

                <NeatFormField name="promoMsg"
                               label="Promo message"
                               type="text"/>

                <NeatFormField name="wontContinue"
                               label="Customer has notified termination"
                               component="select">
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </NeatFormField>

                <NeatFormField name="schedule"
                               label="Schedule"
                               component={Schedule}/>

                <button type="submit">SAVE</button>
              </Form>
            </Formik>
            <CoursePackages course={this.state.course}/>
          </div>
          {this.state.showTrialConfirmation &&
            <ConfirmationDialog
              message="Do you want a trial to be created for all requesters?"
              confirmText="YES"
              rejectText="NO"
              onConfirm={() => this.createCourseAndFreeTrial()}
              onReject={() => this.createCourse()}
            />
          }
          {this.state.showUpdateConfirmation &&
            <ConfirmationDialog
              message="You're about to modify a course that has already been purchased by some students. Are you sure??"
              onConfirm={() => this.updateCourse()}
              onReject={() => this.setState({showUpdateConfirmation: false})}
            />
          }
        </div>
      </div>
    );
  }

  async submit(course) {
    const acceptedAges = course.acceptedAges === "unset" ? undefined : JSON.parse(course.acceptedAges);
    const courseToSubmit = {...course, acceptedAges: acceptedAges};
    if (!courseToSubmit.acceptedAges) {
      delete courseToSubmit.acceptedAges;
    }

    this.formCourse = courseToSubmit;
    if (!courseToSubmit._id) {
      this.setState({ showTrialConfirmation: true });
    } else {
      const courseSales = await courseApi.searchPurchasesByCourseIds([courseToSubmit._id]);
      if (courseSales.length > 0) {
        this.setState({ showUpdateConfirmation: true });
      } else {
        this.updateCourse();
      }
    }
  }

  async createCourseAndFreeTrial() {
    try {
      const createdCourse = await courseApi.createCourse(this.formCourse);
      const classRequests = await classRequestApi.findOpenClassRequestsForCourseProposal(this.formCourse.courseProposalId);
      classRequests.forEach(cr =>
        courseApi.createTrialPurchase({
          courseId: createdCourse._id,
          userId: cr.userId,
          studentIds: cr.studentIds || [cr.userId],
        }));
    } catch (e) {
      logError("Failed to create course or free trials", e);
      this.setState({showTrialConfirmation: false});
      return;
    }
    coursesStore.retrieveCourses();
    history.push('/courses');
  }

  async createCourse() {
    try {
      await courseApi.createCourse(this.formCourse);
    } catch (e) {
      logError("Failed to create course", e);
      this.setState({showTrialConfirmation: false});
      return;
    }
    coursesStore.retrieveCourses();
    history.push('/courses');
  }

  async updateCourse() {
    try {
      await courseApi.updateCourse(this.formCourse);
    } catch (e) {
      logError("Failed to create course", e);
      this.setState({showUpdateConfirmation: false});
      return;
    }
    coursesStore.retrieveCourses();
    history.push('/courses');
  }

  dtoFromGet2ViewModel(course) {
    const copy = JSON.parse(JSON.stringify(course));
    copy.teacherId = course.teacher._id;
    copy.acceptedAges = copy.acceptedAges ? JSON.stringify(copy.acceptedAges) : "null";
    return copy;
  }
}

import React from 'react';
import style from './menu.scss';
import {Link} from "react-router-dom";
import {config} from "../config";
import {userSession} from "../api/userSession";

const davidId = "5cab079e61d19a59173f7b3f";
const sarahId = "5fbb000d5d3fc92a0351461c";
const luckyId = "6196a9c35b2a9705189f44bf";
const johanaId = "6502f9fbdf7ef1eeaf411320";
const sintaId = "638e9656fd3e609a0ab0ba4b";
const fatmahId = "65c9b742af082be6b7e9642d";
const isaacId = "65ca23b5af082be6b7e9652d";

const editors = [davidId, sarahId, luckyId, johanaId, sintaId, fatmahId, isaacId];
const accountants = [davidId, sintaId];
const drillEditors = [davidId, sintaId];

function MenuItem(props) {
  return (
    <div className={style.item}>
      <Link to={props.path}>
        {props.label}
      </Link>
    </div>
  )
}

export class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
    this.toggleExpansion = this.toggleExpansion.bind(this);
  }

  toggleExpansion() {
    this.setState(curSt => {return {expanded: !curSt.expanded}});
  }

  render() {
    return (
      <div className={`${style.menu} ${this.state.expanded && style.expanded}`}
           style={config.dev ? {backgroundColor: "#faa"} : {}}
           onClick={this.toggleExpansion}>
        <MenuItem path="/" label="Active class requests"/>
        <MenuItem path="/teacherReplacementRequests" label="Teacher replacement requests"/>
        <MenuItem path="/courseProposals" label="Active course proposals"/>
        <MenuItem path="/testCallSchedule" label="Test calls"/>
        <MenuItem path="/trialsSchedule" label="Trials"/>
        <MenuItem path="/courses" label="Active courses"/>
        <MenuItem path="/renewals" label="Renewals"/>
        <MenuItem path="/teacherApplications" label="Teacher applications"/>
        <MenuItem path="/teachers" label="Teachers"/>
        <MenuItem path="/payslips" label="Payslips"/>
        <MenuItem path="/schedules" label="Schedule heat map"/>
        <MenuItem path="/users/new" label="Create user"/>
        <MenuItem path="/users" label="Edit user"/>
        <MenuItem path="/waQrCode" label="WhatsApp QR"/>
        <MenuItem path="/closedClassRequests" label="Closed class requests"/>
        <MenuItem path="/allCourses" label="All courses"/>
        <MenuItem path="/login" label="Login"/>
        <MenuItem path="/dashboard" label="Dashboard"/>
        {drillEditors.includes(userSession.getUserId()) && <MenuItem path="/drills" label="Drills"/>}
        {editors.includes(userSession.getUserId()) && <MenuItem path="/scenarios" label="Scenarios"/>}
        {editors.includes(userSession.getUserId()) && <MenuItem path="/itineraries" label="Itineraries"/>}
        {editors.includes(userSession.getUserId()) && <MenuItem path="/characters" label="Characters"/>}
        {userSession.getUserGroups().includes("ADMINS") && <MenuItem path="/config" label="Config"/>}
        {accountants.includes(userSession.getUserId()) && <MenuItem path="/unearnedRevenues" label="Unearned revs"/>}
      </div>
    )
  }
}
import React from 'react';
import {usersStore} from "../store/usersStore";
import * as moment from 'moment-timezone';
import {useState} from 'react';
import {useLocation, useParams} from "react-router";
import {Formik, Form} from "formik";
import {NeatFormField} from "../controls/neatFormField";
import {Button} from "../controls/button";
import {teacherApi} from "../api/teacherApi";
import {observer} from "mobx-react";
import history from "../history/history";
import {Spinner} from "../controls/spinner";
import {logError} from "../errors/errorConsole";

const prepCallDescription =
`Please confirm your availability VIA SKYPE

Check your inbox, there should be another email apart from the Google Calendar notification, let us know if you didn't receive it :-)
`;

export const TeacherApplicationScheduleEditor = observer(
  function TeacherApplicationScheduleEditor(props) {
    const [submitting, setSubmitting] = useState(false);

    const {state} = useLocation();
    const app = state?.application;
    const isPrepCall = state?.isPrepCall
    if (!app) history.push('/teacherApplications');
    const user = app.userId && usersStore.getUserById(app.userId);
    const applicationId = app._id;
    const interview = {
      applicationId,
      date: "",
      timezone: "Asia/Manila",
      time: ""
    };

    return (<div style={{padding: 20}}>
      Interview with {user?.name}
      <Formik
        initialValues={interview}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({submitForm}) => (
          <Form>
            <NeatFormField name="timezone"
                           label="Timezone"
                           component="select">
              {moment.tz.names().map(tz =>
                <option value={tz}>{tz}</option>
              )}
            </NeatFormField>
            <NeatFormField name="date"
                           label="Date"
                           type="date" />
            <NeatFormField name="time"
                           label="Time"
                           type="time" />
            <Button onClick={!submitting && submitForm} noArrow>
              {submitting ? <Spinner/> : "Submit"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>);


    async function submit({applicationId, time, date, timezone}) {
      setSubmitting(true);
      try {
        const startTime = moment.tz(`${date} ${time}`, timezone);
        if (isPrepCall) {
          await teacherApi.createPrepCall({
            applicationId,
            startTime: startTime.toISOString(),
            timezone
          });
          window.open(googleCalendarLink(startTime, (name) => `Prep call ${name} with Cerah`, prepCallDescription), "_blank");
        } else {
          await teacherApi.createInterview({
            applicationId,
            startTime: startTime.toISOString(),
            timezone
          });
          window.open(googleCalendarLink(startTime, (name) => `Interview ${name} with Cerah`), "_blank");
        }
        history.push('/teacherApplications');
      } catch (e) {
        if (isPrepCall)
          logError(`Failed to create a prep call for teacher application ${applicationId} of teacher ${user?.name}`, e.message);
        else
          logError(`Failed to create interview for teacher application ${applicationId} of teacher ${user?.name}`, e.message);
      }
      setSubmitting(false);
    }

    function googleCalendarLink(startTime, eventNameTemplate, description) {
      const isoFormat = "YYYYMMDDTHHmmss";
      const name = user.name;
      const startTimeStr = startTime.utc().format(isoFormat);
      const endTimeStr = startTime.add(isPrepCall ? 0.5 : 1, "hours").utc().format(isoFormat);
      const dates = encodeURI(`${startTimeStr}Z/${endTimeStr}Z`);
      const text = encodeURI(eventNameTemplate(name));
      const guests = encodeURI(`gntlwnd_mariel@yahoo.com,${user.email}`);
      const location = encodeURI(`Online via Skype`);
      const src = encodeURI("frj9u1gce9p669prpooh1oqhmk@group.calendar.google.com");
      const details = encodeURI(description || "");
      return `http://www.google.com/calendar/event?action=TEMPLATE&dates=${dates}&text=${text}&location=${location}&add=${guests}&src=${src}&details=${details}`;
    }
  }
);
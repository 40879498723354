import React, {useState} from 'react';
import {CoursePicker} from "../courses/coursePicker";
import {useLocation} from 'react-router-dom';
import {observer} from "mobx-react";
import {courseApi} from "../api/courseApi";
import * as logger from "../errors/errorConsole";
import {Button} from "../controls/button";
import history from "../history/history";
import {Spinner} from "../controls/spinner";
import {usersStore} from "../store/usersStore";
import {coursesStore} from "../store/coursesStore";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {UserFieldComponent} from '../users/userField';

export const CreditTransferEditor = observer(
  function CreditTransferEditor() {
    const {studentId, srcCourseId} = useLocation().state;
    const course = coursesStore.coursesById[srcCourseId];
    const remainingCredit = course.paidHoursPerStudent[studentId] - course.attendedHoursPerStudent[studentId];
    
    const [destCourse, setDestCourse] = useState(null);
    const [transferring, setTransferring] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [numHours, setNumHours] = useState(remainingCredit);
    const [inputError, setInputError] = useState(null);
    const [toStudentId, setToStudentId] = useState(studentId);

    const studentName = usersStore.getUserById(studentId)?.name;
    const courseName = coursesStore.coursesById[srcCourseId]?.name;

    if (course.amountPaidPerStudent[studentId] <= 0) {
      return (
        <div style={{
            height: "100%", 
            display: "flex", 
            alignContent: "center", 
            alignItems: "center",
            maxWidth: "600px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "24px"}}>
          {studentName} has never paid for classes in course {courseName}. Maybe what you want to do is removing the request from this course and attaching it to another one?
        </div>
      );
    }
    
    return (
      <div>
        <div>You're transferring credit of {studentName} in course {courseName}</div>
        <UserFieldComponent label={"Student who will receive the credit:"} value={studentId} onUserSelected={async (userId) => {
          setToStudentId(userId);
        }}/>
        <div>Choose destination course</div>
        <CoursePicker onCourseSelected={setDestCourse}/>
        <div>Number of hours to transfer</div>
        <div>
          <input type="number" min="1" 
            value={numHours}
            onChange={({target: {value}}) => {
              setInputError(null);
              Number(value) ? setNumHours(Number(value)) : setInputError("Please enter a valid number");
            }}/> 
          {inputError && <div>{inputError}</div>}
        </div>
        <div style={{marginTop: 20}}>
          <Button onClick={destCourse && (() => setConfirming(true))}>
            {transferring ? <Spinner/> : "Transfer credit"}
          </Button>
        </div>
        {confirming && <ConfirmationDialog
          message={`You're going to transfer ${numHours} hours of credit for student ${studentName} in course ${courseName} to ${usersStore.getUserById(toStudentId)?.name || `user ${toStudentId}`} in course ${destCourse.name}. Are you sure that's what you want to do??`}
          onReject={() => setConfirming(false)}
          rejectText="No, cancel"
          onConfirm={transferCredit}
          confirmText="Yes, I'm sure"
        />}
      </div>
    );

    async function transferCredit() {
      setConfirming(false);
      setTransferring(true);
      try {
        await courseApi.transferCredit({
          sourceCourseId: srcCourseId,
          destinationCourseId: destCourse._id,
          fromStudentId: studentId,
          toStudentId: toStudentId,
          numHours
        });
        history.goBack();
      } catch (e) {
        logger.logError("Failed to transfer credit", e);
      }
      setTransferring(false);
    }
  }
);
import React, {useEffect, useState} from 'react';
import style from './transferConfirmation.scss';
import {Button} from "../controls/button";
import {logError} from "../errors/errorConsole";
import * as moment from "moment-timezone";
import {teacherApi} from "../api/teacherApi";
import {Spinner} from "../controls/spinner";
import {useLocation} from "react-router";
import {courseApi} from "../api/courseApi";
import {paymentApi} from "../api/paymentApi";

export function TransferConfirmation() {
  const {payslipId} = useLocation().state;

  if (!payslipId) return null;

  const [isSending, setSending] = useState(false);
  const [teacher, setTeacher] = useState(null);
  const [payslip, setPayslip] = useState(null);
  const [accounts, setAccounts] = useState({});
  const [recipientAccount, setRecipientAccount] = useState();
  const [xrates, setXrates] = useState(null);

  useEffect(() => {
    loadState();
  }, []);

  async function loadState() {
    try {
      const payslip = await courseApi.getPayslip(payslipId);
      setPayslip(payslip);
      const teacherId = payslip.teacherId;
      setTeacher(await teacherApi.getTeacher(teacherId));
      const accounts = (await teacherApi.getTeacherPaymentDetails(teacherId)).accounts;
      setAccounts(accounts);
      setXrates({rates: {...(await paymentApi.xrates()).rates, IDR: 1}});
      setRecipientAccount(accounts[0]);
    } catch (e) {
      logError("Failed to load teacher profile and payment details", e);
    }
  }

  if (!xrates) return <Spinner/>;

  const originalAmount = payslip.meetings.reduce(totalFee, 0);
  const xrate = xrates.rates[recipientAccount?.currency];

  const fundTransfer = {
    sourceCurrency: "SGD",
    targetCurrency: recipientAccount?.currency,
    targetAmount: originalAmount * xrate,
    targetAccountId: recipientAccount?._id,
    reference: `Cerah ${moment(payslip.creationTime - 48 * 3600000).format("MMM YYYY")}`,
    idempotencyKey: payslip.identifier
  };

  return (
    <div className={style.transferConfirmation}>
      <div className={style.title}>Transfer to <span className={style.name}>{teacher.name}</span></div>
      {/**<div>Source: Wise {fundTransfer.sourceCurrency} account</div>**/}
      Choose {teacher.name}'s account: <select onChange={ev => setRecipientAccount(accounts.filter(acct => acct._id === ev.target.value)[0])}>
        {accounts.map(acct => <option value={acct._id}>{acct.currency} {acct.alias || acct.accountId || acct.channelCode}</option>)}
      </select>

      {xrate !== 1 && <div className={style.amount}>IDR {originalAmount.toLocaleString("id", {minimumFractionDigits:2, maximumFractionDigits: 2})}</div>}
      <div className={style.amount}>{fundTransfer.targetCurrency} {fundTransfer.targetAmount.toLocaleString("id", {minimumFractionDigits:2, maximumFractionDigits: 2})}</div>

      {xrate !== 1 && <div className={style.transferDetails}>X-rate: {(1/xrate).toLocaleString("id", {minimumFractionDigits:2, maximumFractionDigits: 2})} IDR/{fundTransfer.targetCurrency}</div>}
      <div className={style.transferDetails}>Reference: {fundTransfer.reference}</div>
      {recipientAccount &&
        <div className={style.confirmationBtnPanel}>
          <Button noArrow
                  disabled={isSending}
                  onClick={sendMoney}>
            {!isSending ? "Confirm" : <Spinner/>}
          </Button>
        </div>
      }
    </div>
  );

  async function sendMoney() {
    setSending(true);
    try {
      await paymentApi.transferFunds(fundTransfer);
      if (payslipId) {
        await courseApi.confirmPayslip(payslipId);
      }
      history.back();
      //TODO: send notification to teacher that the money is on the way, with the x-rate applied, the final amount (we should display the amount in the destination currency in the teacher's app itself)
    } catch (e) {
      logError("Failed to transfer funds", e);
    }
    setSending(false);
  }
}

const totalFee = (acc, m) => acc + m.fee;

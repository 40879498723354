import React from 'react';
import {Formik, Form, Field, FieldArray} from "formik";
import * as moment from "moment-timezone";
import style from "./schedule.scss";

const Class = ({
  field: {name, value, onChange, onBlur}, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  function parseTime(startTime) {
    const timeFields = startTime.split(':');
    return timeFields[1] / 60 + timeFields[0] * 1;
  }

  const hours = Math.floor(value.startHours);
  const minutes = Math.round((value.startHours - hours) * 60);
  const startTime = moment(`${hours}:${minutes}`, "H:m").format("HH:mm");

  return (
    <Form onChange={onChange} className={style.scheduleEntry}>
      <Field component="select" name={`${name}.dayOfWeek`}>
        {new Array(7).fill().map((v, idx) =>
          <option key={idx+1} value={idx + 1}>{moment().isoWeekday(idx + 1).format('dddd')}</option>
        )}
      </Field>
      <input type="time"
             value={startTime}
             className={style.scheduleEntryPart}
             onChange={ev => {setFieldValue(`${name}.startHours`, parseTime(ev.target.value))}}/>
      <Field type='number'
             name={`${name}.durationMins`}
             className={style.scheduleEntryPart}/>
    </Form>
  )
};

const defaultSchedule = {
  durationMins: 60,
  dayOfWeek: 3,
  startHours: 19
};

export const Schedule = ({
  field: {name, value, onChange, onBlur}, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  values,
  ...props
}) => (
    <Form>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <div>
            {value.map((cls, index) => (
              <div key={index}>
                <Field component={Class} name={`${name}[${index}]`}/>
                <button type="button" onClick={() => arrayHelpers.remove(index)}>-</button>
              </div>
            ))}
            {!editing(value) &&
                <button type="button" onClick={() => arrayHelpers.push(defaultSchedule)}>+</button>}
          </div>
        )}
      />
    </Form>
);

function editing(classes) {
  return classes.length > 0 && !isClassComplete(classes[classes.length - 1]);
}

function isClassComplete(cl) {
  return cl.startHours >= 0 && cl.durationMins && cl.dayOfWeek;
}

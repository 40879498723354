import React from 'react';
import {Field} from 'formik';

import style from './neatFormField.scss';

export function NeatFormField({label, ...props}) {
  return (
    <div className={style.fieldContainer}>
      <div className={style.label}>
        {label}
      </div>
      <Field {...props}>
        {props.children}
      </Field>
    </div>
  );
}
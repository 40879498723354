// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t4kp29em{position:fixed;bottom:0;width:100%;background-color:transparent;display:flex;flex-direction:column-reverse;z-index:100}.t4kp29em .XUmcxo7F{background-color:#ffdddd;color:red;border:2px solid red;border-radius:6px;margin:5px;padding:20px;position:relative}.t4kp29em .XUmcxo7F .pm1iIqYa{position:absolute;right:0;width:30px;top:0;bottom:0;font-size:30px;display:flex;align-items:center;cursor:pointer}\n", "",{"version":3,"sources":["webpack://./src/errors/errorConsole.scss"],"names":[],"mappings":"AAAA,UACE,cAAe,CACf,QAAS,CACT,UAAW,CACX,4BAA6B,CAC7B,YAAa,CACb,6BAA8B,CAC9B,WAAY,CAPd,oBAUI,wBAAyB,CACzB,SAAU,CACV,oBAAqB,CACrB,iBAAkB,CAClB,UAAW,CACX,YAAa,CACb,iBAAkB,CAhBtB,8BAmBM,iBAAkB,CAClB,OAAQ,CACR,UAAW,CACX,KAAM,CACN,QAAS,CACT,cAAe,CACf,YAAa,CACb,kBAAmB,CACnB,cAAe","sourcesContent":[".errorConsole {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  background-color: transparent;\n  display: flex;\n  flex-direction: column-reverse;\n  z-index: 100;\n\n  .error {\n    background-color: #ffdddd;\n    color: red;\n    border: 2px solid red;\n    border-radius: 6px;\n    margin: 5px;\n    padding: 20px;\n    position: relative;\n\n    .close {\n      position: absolute;\n      right: 0;\n      width: 30px;\n      top: 0;\n      bottom: 0;\n      font-size: 30px;\n      display: flex;\n      align-items: center;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorConsole": "t4kp29em",
	"error": "XUmcxo7F",
	"close": "pm1iIqYa"
};
export default ___CSS_LOADER_EXPORT___;

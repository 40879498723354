import React, {useEffect, useState} from 'react';
import {courseApi} from "../api/courseApi";
import homeStyle from '../main/home.scss';
import {teacherApi} from "../api/teacherApi";
import {Menu} from "../menu/menu";
import {Link, useParams} from "react-router-dom";

export function TeacherPayslips() {
  const {teacherId} = useParams();

  function loadState() {
    (async() => {
      setPayslips(await courseApi.getPayslipsForTeacher(teacherId));
      setTeacher(await teacherApi.getTeacher(teacherId));
    })();
  }

  const [payslips, setPayslips] = useState([]);
  const [teacher, setTeacher] = useState({});
  useEffect(loadState, []);

  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={homeStyle.list}>
        <div>Payslips for teacher {teacher.name}</div>
        {payslips.map(slip => <div>
          <Link to={`/payslips/${slip._id}`}>
            {slip.identifier}
          </Link>
          &nbsp;
          <Link to={`/payslips/${slip._id}/unearnedRevenue`}>
             unearned revenue
          </Link>
        </div>)}
      </div>
    </div>
  );
}
import React from 'react';
import {classRequestApi} from "../api/classRequestApi";
import {Schedule} from "../courses/schedule";
import {availableSubjects, availableLevels} from "../courses/courseEditor";
import {onlineLocation} from "../controls/locationSelector";
import {NeatFormField} from "../controls/neatFormField";
import {NeatFieldValue} from "../controls/neatFieldValue";
import history from "../history/history";
import homeStyle from '../main/home.scss';
import style from './courseProposalEditor.scss';
import {Menu} from "../menu/menu";
import {Formik, Form, Field} from "formik";
import * as moment from "moment-timezone";
import {ArrayField} from "../controls/arrayField";
import {TeacherSelector} from "../courses/teacherSelector";
import {observer} from "mobx-react";
import {Button} from "../controls/button";
import * as _ from "lodash";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {teachersStore} from "../store/teachersStore";
import {logError} from "../errors/errorConsole";
import {courseApi, getForeignTeacherIds, getIndonesianTeacherIds} from '../api/courseApi';
import {Spinner} from "../controls/spinner";
import {debounce} from "lodash-decorators/debounce";


export const courseProposalTypes = {
  bareng: "bareng",
  privat: "privat",
  onlinePrivate: "onlinePrivate",
  onlineGroup: "onlineGroup"
};

@observer
export class CourseProposalEditor extends React.Component {
  previousSchedule = [];
  previousTimezone = "Asia/Jakarta";
  availableTeachers;

  constructor(props) {
    super(props);
    this.state = {
      courseProposal: {
        schedule: [],
        timezone: "Asia/Jakarta",
        visibleTo: [],
        location: onlineLocation
      },
      loadingAvailableTeachers: false
    };
    setTimeout(() => this.loadState(), 0); //delaying loadState bcs it might be synchronous, and hence have no effect since this.state has just been initialized
    this.submit = this.submit.bind(this);
    this.addIndonesians = this.addIndonesians.bind(this);
    this.addPinoys = this.addPinoys.bind(this);
    this.isTeacherAvailable = this.isTeacherAvailable.bind(this);
  }

  async loadState() {
    const courseProposalId = this.props.match.params.courseProposalId;
    let courseProposal;
    if (courseProposalId === "new") {
      const routerState = this.props.location.state;
      courseProposal = routerState && routerState.courseProposal;
    } else {
      try {
        courseProposal = await classRequestApi.getCourseProposal(courseProposalId);
      } catch (e) {
        logError("Failed to load course proposal", e);
        history.push('/courseProposals');
        return;
      }
    }
    if (courseProposal) {
      if (courseProposal.age) courseProposal.age = JSON.stringify(courseProposal.age);
      if (!courseProposal.type) courseProposal.type = courseProposalTypes.onlinePrivate;
      if (!courseProposal.timezone) courseProposal.timezone = "Asia/Jakarta";
      if (!courseProposal.subject) courseProposal.subject = "english.conversation";
      if (!courseProposal.level) courseProposal.level = availableLevels[0].value;
      if (!courseProposal.location) courseProposal.location = onlineLocation;
      this.previousTimezone = courseProposal.timezone;
      this.previousSchedule = courseProposal.schedule;
      await this.retrieveAvailableTeachers(courseProposal.schedule, courseProposal.timezone);
      this.setState({courseProposal});
    }
  }

  addPinoys(form) {
    const values = new Set(form.values["visibleTo"].concat(
      getForeignTeacherIds().filter(this.isTeacherAvailable))
    );
    form.setFieldValue("visibleTo", Array.from(values));
  }

  addIndonesians(form) {
    const values = new Set(form.values["visibleTo"].concat(
      getIndonesianTeacherIds().filter(this.isTeacherAvailable))
    );
    form.setFieldValue("visibleTo", Array.from(values));
  }

  isTeacherAvailable(t) {
    const {courseProposal} = this.state;
    return this.availableTeachers.includes(t) && (!courseProposal.teacherReplacementRequestId || !courseProposal.invisibleTo || !courseProposal.invisibleTo.includes(t) );
  }

  render() {
    const proposal = this.state.courseProposal;
    const proposalToSubmit = this.state.proposalToSubmit;
    const newTeachersNames = _.difference((proposalToSubmit || proposal).visibleTo, proposal.visibleTo)
      .map(tId => (teachersStore.getTeacherById(tId) || {}).name);


    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.list}>
          <div className={style.classRequestEditor}>
            Course proposal
            <Formik
              validateOnChange={true}
              initialValues={proposal}
              onSubmit={this.submit}
              enableReinitialize={true}
            >
              <Form>
                <NeatFormField name="name"
                               label="Course name"
                               type="text"/>

                <NeatFormField name="age"
                               label="Age"
                               component="select">
                  <option value="null">unset</option>
                  <option value="[0,3]">0-3</option>
                  <option value="[4,7]">4-7</option>
                  <option value="[8,11]">8-11</option>
                  <option value="[12,17]">12-17</option>
                  <option value="[18,23]">18-23</option>
                  <option value="[24,99]">adult</option>
                </NeatFormField>

                <NeatFieldValue name="location"
                                label="Location"
                                value={proposal && proposal.location.name} />

                <NeatFormField name="startDate"
                               label="Start date"
                               type="date" />

                <Field>
                  {({ form }) =>
                    <NeatFormField name="schedule"
                      label="Schedule"
                      component={Schedule}
                      validate={async updatedSchedule => {
                        if (JSON.stringify(updatedSchedule) !== JSON.stringify(this.previousSchedule)) {
                          if (form.values.visibleTo.length !== 0) form.setFieldValue('visibleTo', [])
                          await this.retrieveAvailableTeachers(updatedSchedule, form.values.timezone);
                          this.previousSchedule = updatedSchedule
                        }
                      }} />
                  }
                </Field>

                <Field>
                  {({ form }) =>
                    <NeatFormField name="timezone"
                      label="Timezone"
                      component="select"
                      validate={async updatedTimezone => {
                        if (updatedTimezone !== this.previousTimezone) {
                          if (form.values.visibleTo.length !== 0) form.setFieldValue('visibleTo', [])
                          await this.retrieveAvailableTeachers(form.values.schedule, updatedTimezone);
                          this.previousTimezone = updatedTimezone
                        }
                      }} >
                      {moment.tz.names().map(tz =>
                        <option value={tz}>{tz}</option>
                      )}
                    </NeatFormField>
                  }
                </Field>

                <Field>
                  {({ form }) => (
                    <NeatFieldValue name="visibleTo"
                                    label="Visible to teachers">
                      {this.state.loadingAvailableTeachers ?
                        <Spinner/> :
                        <>
                          <Button text="Pinoys"
                                  onClick={() => this.addPinoys(form)}/>
                          <Button text="Indonesians"
                                  onClick={() => this.addIndonesians(form)}/>
                          <ArrayField name="visibleTo"
                                      innerComp={TeacherSelector} />
                        </>
                      }
                    </NeatFieldValue>
                  )}
                </Field>

                <NeatFormField name="type"
                               label="Type"
                               component="select" >
                  <option value={courseProposalTypes.onlineGroup}>CerahOnline public group</option>
                  <option value={courseProposalTypes.onlinePrivate}>CerahOnline private (group or individual)</option>
                </NeatFormField>

                <NeatFormField name="capacity"
                               label="Group Size"
                               type="number">
                </NeatFormField>

                <NeatFormField name="subject"
                               label="Subject"
                               component="select">
                  {availableSubjects.map(subj =>
                    <option value={subj.value}>{subj.label}</option>)}
                </NeatFormField>

                <NeatFormField name="level"
                               label="Level"
                               component="select">
                  {availableLevels.map(subj =>
                    <option value={subj.value}>{subj.label}</option>)}
                </NeatFormField>

                <NeatFormField name="comments"
                               label="Comments (optional)"
                               component="textarea" />

                <button type="submit">SAVE</button>

              </Form>
            </Formik>
          </div>
        </div>
        {proposalToSubmit &&
          <ConfirmationDialog onReject={() => this.setState({proposalToSubmit: false})}
                              message={newTeachersNames && `Saving the course proposal will immediately send a notification to ${newTeachersNames.join(', ')}. Are you sure?`}
                              onConfirm={() => this.save(this.state.proposalToSubmit)}/>
        }
      </div>
    );
  }

  async submit(proposal) {
    const visibleToDiff = _.difference(proposal.visibleTo, this.state.courseProposal.visibleTo);
    if (visibleToDiff.length === 0) {
      this.save(proposal);
      return;
    }
    this.setState({proposalToSubmit: proposal});
  }

  @debounce(800, {trailing: true})
  async retrieveAvailableTeachers(schedule, timezone) {
    let availableTeachers = [];
    this.setState({loadingAvailableTeachers: true});
    try {
      availableTeachers = await courseApi.getAvailableTeacherIds(schedule, timezone);
      this.availableTeachers = availableTeachers;
    } catch (e) {
      logError("Failed to retrieve available teachers for the chosen schedule", e);
    }
    this.setState({loadingAvailableTeachers: false});
  }

  async save(proposal) {
    const proposalToSubmit = {...proposal, age: JSON.parse(proposal.age)};
    if (!proposalToSubmit.age) {
      delete proposalToSubmit.age;
    }
    try {
      if (proposalToSubmit._id) {
        await classRequestApi.updateCourseProposal(proposalToSubmit);
      } else {
        await classRequestApi.createCourseProposal(proposalToSubmit);
      }
      history.push('/courseProposals');
    } catch (e) {
      this.setState({proposalToSubmit: false});
      logError("Failed to create course proposal", e);
    }
  }
}
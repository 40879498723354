import React from 'react';
import {Field} from "formik";

export function PackageField(props) {
  const packages = props.pkgs.sort((p1, p2) => p1.price > p2.price ? 1 : -1);
  return (
    <Field component="select" name={props.field.name}>
      <option value="">Select package</option>
      {packages.map(p =>
        <option value={p._id}>{p.numHours}h at {p.currency} {p.price}</option>
      )}
    </Field>
  );
}
import React from 'react';
import style from './courses.scss';
import {Button} from "../controls/button";
import {userApi} from "../api/userApi";
import {observer} from "mobx-react";
import {teachersStore} from "../store/teachersStore";
import * as moment from "moment-timezone";
import {usersStore} from "../store/usersStore";
import {availableSubjects} from "./courseEditor";
import * as _ from "lodash";
import classRequestStyle from "../classRequests/classRequests.scss";
import {courseApi} from "../api/courseApi";
import {coursesStore} from "../store/coursesStore";

const minToPixRatio = 0.4;

@observer
export class Course extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      usersById: {},
      meetings: props.course.meetings.filter(m => m.state !== "RESCHEDULED")
    };
    this.expand = this.expand.bind(this);
    this.storeMeetingsElRef = this.storeMeetingsElRef.bind(this);
  }

  storeMeetingsElRef(ref) {
    this.meetingsElRef = ref;
  }

  render() {
    this.meetingDates = this.state.meetings.map(m => moment(m.startTime).valueOf());
    let accruedMin = 0;
    this.accruedMins = this.state.meetings.map(m => accruedMin += m.durationMins);
    this.scheduledDurationMins = accruedMin;
    
    
    const availableSubjectsMap = {};
    availableSubjects.forEach(({value, label}) => availableSubjectsMap[value] = label)

    const props = this.props;
    const now = moment();
    const c = props.course;
    const studentIds = Object.keys(c.purchasesByStudentId)
      .sort((st1, st2) => {
        const dates1 = c.enrolmentDates[st1];
        const dates2 = c.enrolmentDates[st2];
        if (dates1 && (dates1.length % 2 === 0) && dates2 && (dates2.length % 2 === 0)) {
          return dates1[dates1.length - 1].isAfter(dates2[dates2.length - 1]) ? -1 : 1;
        } else {
          return c.studentIsActive[st1] ? -1 : 1;
        }
      });
    const teacher = teachersStore.getTeacherById(c.teacherId);
    const numEnrolledStudents = Object.keys(c.purchasesByStudentId).length || 0;
    
    return (
      <div className={style.course}>
        <div>
          <div>
            {c.type === 'onlinePrivate' && <div className={`${style.badge} ${style.badgePrivate}`}>private</div>}
            {c.type === 'onlineGroup' && <div className={`${style.badge} ${style.badgePublic}`}>public</div>}
            {c.name} <span className={style.teacherName}>{teacher && teacher.name}</span>
            {c.subject && c.type !== 'onlineGroup' && c.type !== "bareng" && <span className={style.subjectName}>{availableSubjectsMap[c.subject]}</span>}
            {c.type === 'onlineGroup' && <div className={classRequestStyle.acceptedAges}>
              {(!c.acceptedAges || c.acceptedAges[0] >= 18) ? "adults" : `${c.acceptedAges[0]} - ${c.acceptedAges[1]}`}
            </div>}
            <div style={{display: "flex", paddingTop: 3}}>
              <Schedule course={c}/>
              <div>
                <div className={style.level}>- {c.subject.startsWith("english.bareng") && c.subject.split("english.bareng.")[1]}</div>
                {c.startDate && <div className={style.startDate}>{c.startDate}</div>}
              </div>
            </div>
          </div>
          {(numEnrolledStudents > 1 || this.state.expanded) && <div>
            {c.activeStudentIds.length} enrolled students <span className={style.enrolledStudents}>({numEnrolledStudents} total)</span>
          </div>}
        </div>
        {this.state.expanded && <>
          <div className={style.meetingsScroller}>
            <div className={style.names}>
              <div className={style.row}/>
              {studentIds.map(stId =>
                <Row isEnrolled={c.studentIsActive[stId]}>
                  {usersStore.getUserById(stId)?.name}
                </Row>
              )}
            </div>
            <div className={style.phones}>
              <div className={style.row}/>
              {studentIds.map(stId =>
                <div className={`${style.row} ${c.studentIsActive[stId] ? "" : style.dropOut}`}>
                  <small>{usersStore.getUserById(stId)?.phone}</small>
                </div>
              )}
            </div>
            <div className={style.totalHours}>
              <div className={style.row}>total</div>
              {studentIds.map(stId =>
              <Row isEnrolled={c.studentIsActive[stId]}>
                {c.paidHoursPerStudent[stId]} h
              </Row>
              )}
            </div>
            <div className={style.totalHours}>
              <div className={style.row}>left</div>
              {studentIds.map(stId =>
              <Row isEnrolled={c.studentIsActive[stId]}>
                {c.paidHoursPerStudent[stId] - c.attendedHoursPerStudent[stId]} h
              </Row>
              )}
            </div>
            <div className={style.totalHours}>
              <div className={style.row}/>
              {studentIds.map(stId =>
                <Row isEnrolled={c.studentIsActive[stId]}>
                  {c.purchasesByStudentId[stId].length} pkg
                </Row>
              )}
            </div>
            <div className={style.totalHours}>
              <div className={style.row}>crdt</div>
              {studentIds.map(stId =>
              <Row isEnrolled={c.studentIsActive[stId]}>
                <small>${c.unearnedRevenueByStudent[stId].toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</small>
              </Row>
              )}
            </div>
            <div className={style.phones}>
              <div className={style.row}/>
              {studentIds.map(stId =>
                <div className={style.row}>
                  <Button className={style.enrolmentDatesButton}
                          text="Purchases"
                          onClick={() => props.onShowPurchases(stId)}
                  />
                  <Button className={style.enrolmentDatesButton}
                          text="Enrol dates"
                          onClick={() => props.onEditEnrolments(stId)}/>
                  <Button className={style.enrolmentDatesButton}
                          text="transfer"
                          onClick={() => props.onTransferStudent(stId)}/>
                </div>
              )}
            </div>
            <div style={{width: (this.scheduledDurationMins + 130) * minToPixRatio}}
                 ref={this.storeMeetingsElRef}
                 className={style.meetings}>
              {this.state.meetings.map(m => {
                const startMoment = moment(m.startTime);
                const isFuture = startMoment.isAfter(now);
                return (
                  <span className={`${style.meeting} ${isFuture ? style.future : ""}`}
                        style={{width: m.durationMins * minToPixRatio - 2, margin: 1}}>
                    {startMoment.format("D.M")}
                  </span>
                );
              })}
              {studentIds.map((uId, idx) => this.renderEnrolmentPeriodsStudent(uId, (idx + 1) * 18))}
            </div>
          </div>

        </>}
        <a href={c.channelUrl} target="_blank">
          <Button className={style.courseButton} text="Skype"/>
        </a>
        <Button className={style.courseButton} text="Create trial" onClick={props.onCreateTrial}/>
        <Button className={style.courseButton} text="Payment link" onClick={props.onPaymentRequest}/>
        <Button className={style.courseButton} text="Purchase" onClick={props.onPurchase}/>
        <Button className={style.courseButton} text="Reimbursement" onClick={props.onReimbursement}/>
        <Button className={style.courseButton} text="Edit" onClick={props.onEdit}/>
        <Button className={style.courseButton} text="Enrolment" onClick={this.expand}/>
        <Button className={style.courseButton} text="Meetings" onClick={props.onEditMeetings}/>
        <Button className={style.courseButton} text="NPS" onClick={props.onShowNps}/>
        <Button className={style.courseButton} text="Change teacher" onClick={props.onTeacherReplacement}/>
      </div>
    );
  }

  renderEnrolmentPeriodsStudent(studentId, top) {
    const c = this.props.course;
    if (!c) return;

    const enrolmentDates = c.enrolmentDates;
    const dates = (enrolmentDates[studentId] || [moment("2010-01-01")]).map(d => moment(d).valueOf());

    if (!dates) return;

    const enrolmentPeriods = [];
    let totalEnrolledMins = 0;
    const totalPaidMins = c.paidHoursPerStudent[studentId] * 60;
    for (let i = 0; i < dates.length; i += 2) {
      const leftIdx = _.sortedIndex(this.meetingDates, dates[i]) - 1;
      const leftAccruedMins = leftIdx < 0 ? 0 : this.accruedMins[leftIdx];

      let rightAccruedMins;
      if (i + 1 < dates.length) {
        const rightIdx = _.sortedIndex(this.meetingDates, dates[i + 1]) - 1;
        rightAccruedMins = rightIdx < 0 ? 0 :this.accruedMins[rightIdx];
      } else {
        rightAccruedMins = this.scheduledDurationMins + 120;
      }

      const width = Math.max(rightAccruedMins - leftAccruedMins, 5) * minToPixRatio;

      enrolmentPeriods.push(
        <div key={studentId}
             className={style.enrolmentPeriod}
             style={{
               top,
               left: leftAccruedMins * minToPixRatio,
               width,
             }}
        />
      );

      totalEnrolledMins += (rightAccruedMins - leftAccruedMins);

      if (totalEnrolledMins > totalPaidMins) {
        const leftEnd = Math.max(rightAccruedMins - (totalEnrolledMins - totalPaidMins), leftAccruedMins);
        enrolmentPeriods.push(
          <div key={studentId}
               className={style.enrolmentPeriod}
               style={{
                 top,
                 left: leftEnd * minToPixRatio,
                 width: (rightAccruedMins - leftEnd) * minToPixRatio,
                 backgroundColor: "red"
               }}
          />
        );

      }
    }
    return enrolmentPeriods;
  }

  async expand() {
    await coursesStore.reloadMeetingsOntoCourse(this.props.course);
    const purchasesByStudentId = this.props.course.purchasesByStudentId;
    if (!purchasesByStudentId) {
      return;
    }
    const usersArray = await userApi.searchUsersByIds(Object.keys(purchasesByStudentId));
    const usersMap = {};
  
    usersArray.forEach(u => usersMap[u._id] = u);
    this.setState({
      usersById: usersMap,
      expanded: true,
      meetings: this.props.course.meetings.filter(m => m.state !== "RESCHEDULED")
    });
    setTimeout(() => {
      this.meetingsElRef?.scrollTo(100000, 0);
    }, 0);
  }
}

export function Schedule({course, courseProposal}) {
  function formatSchedule(schedule, tz) {
    function formatMeetingSched(mSch) {
      const hours = Math.floor(mSch.startHours);
      const minutes = 60 * (mSch.startHours - hours);
      const startTime = moment().tz(tz).hour(hours).minutes(minutes).day(mSch.dayOfWeek);
      const finishTime = moment(startTime).add(mSch.durationMins, 'minutes');
      return `${startTime.format('ddd HH:mm')} - ${finishTime.format('HH:mm z')}`;
    }
    return schedule.map(formatMeetingSched);
  }

  const c = course || courseProposal;

  return (<div className={style.schedule}>
    {formatSchedule(c.schedule, c.timezone).map(s => <div className={style.scheduleEntry}>{s}</div>)}
  </div>);
}

function Row({isEnrolled, children}) {
  return (<div className={`${style.row} ${isEnrolled ? "" : style.dropOut}`}>
    {children}
  </div>);
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ESbsqihG{width:100%}.ESbsqihG .ZmQltAbJ{display:flex;align-items:center}.ESbsqihG .ZmQltAbJ .K0HQWUcs{flex:1}.ESbsqihG .tWUcIU7Z{padding:5px;width:20px;height:20px}\n", "",{"version":3,"sources":["webpack://./src/controls/arrayField.scss"],"names":[],"mappings":"AAAA,UACE,UAAW,CADb,oBAII,YAAa,CACb,kBAAmB,CALvB,8BAQM,MAAO,CARb,oBAaI,WAAY,CACZ,UAAW,CACX,WAAY","sourcesContent":[".arrayField {\n  width: 100%;\n\n  .row {\n    display: flex;\n    align-items: center;\n\n    .itemWrapper {\n      flex: 1;\n    }\n  }\n\n  .addButton {\n    padding: 5px;\n    width: 20px;\n    height: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrayField": "ESbsqihG",
	"row": "ZmQltAbJ",
	"itemWrapper": "K0HQWUcs",
	"addButton": "tWUcIU7Z"
};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect, useState} from "react";
import {Button} from "../../controls/button";
import {conversationApi} from "../../api/conversationApi";
import {logError} from "../../errors/errorConsole";
import {Spinner} from "../../controls/spinner";
import homeStyle from "../../main/home.scss";
import {Menu} from "../../menu/menu";
import {NeatFieldValue} from "../../controls/neatFieldValue";
import {useForm} from "react-hook-form";

export function CharacterEditor(props) {
  const {characterId} = props.match.params;
  const [character, setCharacter] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const {register, handleSubmit, control, formState: {errors, isDirty}} = useForm({values: character});
  const [voiceId, setVoiceId] = useState();  
  
  useEffect(() => {
    loadCharacter();
  }, []);
  
  async function loadCharacter() {
    const character = await conversationApi.getCharacter(characterId); 
    setCharacter(character);
    setVoiceId(character.voiceId)
  }
  
  return (
  <div className={homeStyle.home}>
    <Menu/>
    <div className={homeStyle.rightSidePanel}>
      <div style={{flexDirection: "row", display: "flex", alignItems: "flex-start"}}>
        <div style={{maxWidth: "900px", flex: 1}}>
          <form onSubmit={handleSubmit(save)}>
            <NeatFieldValue label={"Name"}>
              <input {...register('name', {required: true})}/>
            </NeatFieldValue>
            {errors.name && <p>Name is required.</p>}
            
            <NeatFieldValue label={"Voice"}>
              <input {...register('voiceId', {required: true, onChange: (e) => setVoiceId(e.target.value)})}/>
            </NeatFieldValue>
            {errors.label && <p>Voice is required.</p>}
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              {!isDirty && <div style={{color: "green"}}>&#x2713; Saved!</div>}
              {isDirty &&
                <Button
                  disabled={isSubmitting}
                >
                  {!isSubmitting && <input type="submit" value="Save"/>}
                  {isSubmitting && <Spinner/>}
                </Button>
              }
            </div>
          </form>
          <Button text="Test voice" onClick={() => window.open(`https://api.cerah.co/utterances.mp3?text=Please%20sit%20down.%20Let's%20start%20the%20interview&voice=${voiceId}`)}/>
        </div>
      </div>
    </div>
  </div>);
  
  async function save(character) {
    setSubmitting(true);
    try {
      await conversationApi.updateCharacter(characterId, character);
      setCharacter(character);
    } catch (e) {
      logError("Failed to save character", e);
    }
    setSubmitting(false);
  }
}
import React, {useEffect, useState} from 'react';
import {coursesStore, monthIdx} from "../store/coursesStore";
import style from './unearnedRevenues.scss';
import {observer} from "mobx-react";
import {usersStore} from "../store/usersStore";
import {accountingApi} from "../api/accountingApi";
import {autorun} from "mobx";
import moment from "moment";
import {courseApi} from "../api/courseApi";
import {Button} from "../controls/button";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {logError} from "../errors/errorConsole";

const monthsToSkip = 48;

export const UnearnedRevenuesByPayer = observer(
  function UnearnedRevenuesByStudent(props) {
    const [purchasesToSgByCourseAndMonth, setPurchasesToSgByCourseAndMonth] = useState({});
    const [unearnedRevenueInAccounting, setUnearnedRevenueInAccounting] = useState();
    const [unearnedRevenueByCourseAndMonth, setUnearnedRevenueByCourseAndMonth] = useState({});
    const [consumedCreditByCourseAndMonth, setConsumedCreditByCourseAndMonth] = useState({});
    const [showOnlyMismatches, setShowOnlyMismatches] = React.useState(false);
    const [payerIds, setPayersIds] = useState([]);
    const [payerIdx, setPayersIdx] = useState(0);
    const [isShowReconciliationConfirmation, setShowReconciliationConfirmation] = useState(false);
  
    const financialYearEndMillis = moment("2023-08-31T23:59:59.999+08:00");
    const currMonth = monthIdx(financialYearEndMillis);
    const initialMonth = moment(financialYearEndMillis).subtract(currMonth, "months");
    const monthLabels = new Array(currMonth + 1)
      .fill("")
      .map((dum, idx) => moment(initialMonth).add(idx, "months").format("MM-YYYY"))
      .slice(monthsToSkip);
  
    useEffect(() => {
      loadAllPayerIds()
    }, []);
  
    useEffect(() => {
      autorun(async () => {
        if (coursesStore.coursesLoading) return;
  
        const payerId = payerIds[payerIdx];
        if (!payerId) return;
      
        const purchasesToSg = await courseApi.getPurchasesToSgForPayer(payerId);
        const courseIds = Array.from(new Set(purchasesToSg.map(p => p.courseId)));
        await Promise.all(courseIds.map(cId => coursesStore.reloadMeetingsOntoCourse(coursesStore.coursesById[cId])));
    
        const purchasesToSgByCourseAndMonth = {};
        const unearnedRevenueByCourseAndMonth = {};
        const consumedCreditByCourseAndMonth = {};
        courseIds.forEach((cId) => {
          const course = coursesStore.coursesById[cId];
          if (cId === "6157cd9a44fd446cfc8e5009") {
            return;
          }
        
          purchasesToSgByCourseAndMonth[cId] = course.purchasesToSgByMonth.purchasesToSgByPayerAndMonth[payerId];
          unearnedRevenueByCourseAndMonth[cId] = course.unearnedRevenueByPayerAndMonth[payerId];
          consumedCreditByCourseAndMonth[cId] = course.earnedRevenueByPayerAndMonth[payerId];
        });
        setPurchasesToSgByCourseAndMonth(purchasesToSgByCourseAndMonth);
        setUnearnedRevenueByCourseAndMonth(unearnedRevenueByCourseAndMonth);
        setConsumedCreditByCourseAndMonth(consumedCreditByCourseAndMonth);
      
        loadAccounting()
      });
    }, [payerIds, payerIdx]);
  
  
    async function loadAllPayerIds() {
      const payerIds = (await courseApi.getPotentiallyActiveUsersInFinancialYear(financialYearEndMillis)).map(u => u.userId);
      console.log("num of payers", payerIds.length);
      setPayersIds(payerIds);
//      [2nd iteration: only purchases in the financial year (if we store the remaining credit by the end of the previous FY)] 
    }
  
    async function loadAccounting() {
      setUnearnedRevenueInAccounting(await accountingApi.getUnrealizedSalesForPayer(payerIds[payerIdx], financialYearEndMillis.add(8, "hours").valueOf()));
    }
  

    return (
      <div>
        <label>
          <input type="checkbox" checked={showOnlyMismatches} onChange={ev => setShowOnlyMismatches(v => !v)}/>
          mismatches only
        </label>
        <div className={style.mainTitle}>UNEARNED REVENUE BY PAYER - {payerIds.length} payers to go</div>
        <table className={style.unearnedRevTable}>
          <thead>
          <tr>
            <th/>
            {monthLabels.map(mthLabel => <>
              <th colSpan={3}>{mthLabel}</th>
            </>)}
          </tr>
          <tr>
            <th>Course</th>
            {monthLabels.map(label => <>
              <th>credit purchased</th>
              <th>credit consumed</th>
              <th>unearned rev</th>
            </>)}
            <th>In books</th>
          </tr>
          </thead>
          {(() => {
            const payerId = payerIds[payerIdx];
            if (!payerId) return;
            const payer = usersStore.getUserById(payerId);
            const courseIds = Object.keys(unearnedRevenueByCourseAndMonth);
            const creditXero = unearnedRevenueInAccounting;
            const creditOps = Object.values(unearnedRevenueByCourseAndMonth).map(crByMonth => crByMonth && crByMonth[currMonth] || []).flat().reduce((a, b) => a + b, 0);
            const mismatch = Math.abs(creditXero - creditOps) > 0.05 || creditOps !== creditOps; //highlight also if credit is NaN (because (NaN !== NaN) is true)
            if (!mismatch && typeof creditXero !== "undefined" && !isShowReconciliationConfirmation) {
              setShowReconciliationConfirmation(true);
            }
            return (<>
              <tr className={style.payer}>
                <td colSpan={999}>{payer?.name} - {payer?._id}</td>
              </tr>
              {courseIds.map((cId, idx) => {
                const creditSpentPerMonth = consumedCreditByCourseAndMonth[cId] || {};
                const course = coursesStore.coursesById[cId];
                return (
                <tr>
                  <td>{course?.name}</td>
                  {monthLabels.map((label, idx) => {
                    const month = idx + monthsToSkip;
                    return <>
                      <td style={{backgroundColor: "f0f0f0"}} className={style.numeric}>
                        {purchasesToSgByCourseAndMonth[cId] && purchasesToSgByCourseAndMonth[cId][month]?.toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }) || ""}
                      </td>
                      <td style={{backgroundColor: "e0e0e0"}} className={style.numeric}>
                        {creditSpentPerMonth[month]?.toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }) || ""}
                      </td>
                      <td style={{backgroundColor: "d0d0d0"}} className={style.numeric}>
                        {unearnedRevenueByCourseAndMonth[cId] && unearnedRevenueByCourseAndMonth[cId][month]?.toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </td>
                    </>
                  })}
                  {(idx === 0) && <td className={style.numeric} style={mismatch ? {color: "red", fontWeight: "800"} : {}}
                                      rowSpan={courseIds.length}>
                    {creditXero?.toLocaleString("en", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </td>}
                </tr>
                );
              })}
            </>);
          })()}
        </table>
        <Button onClick={previousPayer}>previous</Button>
        <Button onClick={nextPayer}>next</Button>
        <div style={{position: "absolute", bottom: 0}}>
          <Button onClick={() => setShowReconciliationConfirmation(true)}>mark as reconciled</Button>
        </div>

        {isShowReconciliationConfirmation &&
          <ConfirmationDialog
            message={`You're about to mark ${usersStore.getUserById(payerIds[payerIdx])?.name} as reconciled with an accounting balance of ${calculateBalanceRecordedOnReconciliation(unearnedRevenueInAccounting)}. Are you sure??`}
            onConfirm={() => markUserAsReconciled(payerIds[payerIdx], unearnedRevenueInAccounting)}
            onReject={() => setShowReconciliationConfirmation(false)}
          />
        }
      </div>
    );
  
    function previousPayer() {
      setUnearnedRevenueByCourseAndMonth({});
      setUnearnedRevenueInAccounting(undefined);
      setPayersIdx(idx => Math.max(idx - 1, 0));
    }
  
    function nextPayer() {
      setUnearnedRevenueByCourseAndMonth({});
      setUnearnedRevenueInAccounting(undefined);
      setPayersIdx(idx => Math.min(idx + 1, payerIds.length - 1));
    }
    
    async function markUserAsReconciled(userId, balance) {
      try {
        await courseApi.markUserAsReconciled(userId, calculateBalanceRecordedOnReconciliation(balance), financialYearEndMillis);
        setUnearnedRevenueByCourseAndMonth({});
        setUnearnedRevenueInAccounting(undefined);
        setPayersIds(payerIds => payerIds.toSpliced(payerIdx, 1) );
      } catch (e) {
        logError("Failed to mark user as reconciled", e);
      }
      setShowReconciliationConfirmation(false);
    }
    
    function calculateBalanceRecordedOnReconciliation(balance) {
      const roundedBalance = Math.round(balance * 100) / 100;
      return (Math.abs(roundedBalance) < 0.05) ? 0 : roundedBalance;
    }
  }
);

import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useParams} from "react-router";
import {courseApi} from "../api/courseApi";
import {teachersStore} from "../store/teachersStore";
import history from "../history/history";
import {PayslipInvoiceDisplay} from "./payslipInvoiceDisplay";

export const PayslipInvoicePage = observer(
  function PayslipInvoicePage(props) {
    const {payslipId} = useParams();
    if (!payslipId) {
      history.push('/teachers');
      return;
    }
    
    const [payslip, setPayslip] = useState(null);
    
    useEffect(() => {
      loadState()
    }, []);
    
    async function loadState() {
      const payslip = await courseApi.getPayslip(payslipId);
      setPayslip(payslip);
    }
    
    if (!payslip) return null;
    const teacher = teachersStore.getTeacherById(payslip.teacherId);
    
    return <PayslipInvoiceDisplay payslip={payslip} teacher={teacher}/>
  });
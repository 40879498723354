// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mvomA0oA{border:5px solid #f3f3f3;border-radius:50%;border-top:5px solid #455d89;width:20px;height:20px;animation:yv3TOiyC 1s linear infinite}@keyframes yv3TOiyC{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}\n", "",{"version":3,"sources":["webpack://./src/controls/spinner.scss","webpack://./src/controls/palette.scss"],"names":[],"mappings":"AAEA,UACE,wBAAyB,CACzB,iBAAkB,CAClB,4BCEsB,CDDtB,UAAW,CACX,WAAY,CACZ,qCAAkC,CAYpC,oBACE,GACE,sBAAuB,CAEzB,KACE,wBAAyB,CAAA","sourcesContent":["@import \"./palette\";\n\n.loader {\n  border: 5px solid #f3f3f3;\n  border-radius: 50%;\n  border-top: 5px solid $accent1-shade2;\n  width: 20px;\n  height: 20px;\n  animation: spin 1s linear infinite;\n}\n\n@-webkit-keyframes spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}","$main-color1: #fee773;\n$main-color2: #ffa23e;\n\n$accent1-tint2: #e6effc;\n$accent1-tint1: #bedbff;\n$accent1: #a5c0e5;\n$accent1-shade1: #84a5d1;\n$accent1-shade2: #455d89;\n$accent1-shade3: #2d3037;\n\n$accent2-tint2: #c1d7f4;\n$accent2-tint1: #77a5e1;\n$accent2: #4783d0;\n$accent2-shade1: #1b65c3;\n$accent2-shade2: #09448f;\n\n$accent3-tint2: #f9fbff;\n$accent3-tint1: #9dc1f0;\n$accent3: #5c95de;\n$accent3-shade1: #286fcb;\n$accent3-shade2: #09448f;\n\n$accent4-tint2: #e0e0e0;\n$accent4-tint1: #d6d6d6;\n$accent4: #bfc8e1;\n$accent4-shade1: #7281ae;\n$accent4-shade2: #47568a;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "mvomA0oA",
	"spin": "yv3TOiyC"
};
export default ___CSS_LOADER_EXPORT___;

import {classRequestApi} from "../api/classRequestApi";
import React, {useEffect, useState} from 'react';
import * as moment from 'moment-timezone';
import {EventsSchedule} from "./eventsSchedule";
import {observer} from "mobx-react";
import {usersStore} from "../store/usersStore";
import {Button} from "../controls/button";
import history from '../history/history';
import styles from './testCallSchedule.scss';
import {sendRescheduleLinkToStudent} from '../courseProposals/courseProposals';
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {logError} from "../errors/errorConsole";

export const TestCallSchedule = observer(
  function TestCallSchedule() {
    const [weekOffset, setWeekOffset] = useState(0);
    const [classRequests, setClassRequests] = useState([]);
    const [latestTestCallsByCrId, setLatestTestCallsByCrId] = useState({});
    const [sendingRescheduleLink, setSendingRescheduleLink] = useState();

    const lastMonday = moment().add(0 + weekOffset, 'week').startOf('isoWeek');
    const nextMonday = moment(lastMonday).add(1, 'week');

    async function loadWeekTestCalls() {
      const allWeekTestCalls = await classRequestApi.getTestNotCancelledCallsWithinTimeRange(lastMonday.toISOString(), nextMonday.toISOString());
      const latestTestCallsByCrId = {};
      allWeekTestCalls.forEach(c => latestTestCallsByCrId[c.classRequestId] = c); //Since they're sorted by creation time, the latest trumps all the previous ones: FIXME, this won't work, as there may be test calls trumping these ones in a *different* week
      setClassRequests(await classRequestApi.getClassRequestsByIds(Object.keys(latestTestCallsByCrId)));
      setLatestTestCallsByCrId(latestTestCallsByCrId);
    }

    const events = classRequests.map(cr => {
      const u = usersStore.getUserById(cr.userId);
      return ({
        name: u && `${u.name} ${u.phone}`,
        startTime: latestTestCallsByCrId[cr._id]?.startTime,
        durationMins: 30,
        displayComponent: <RescheduleTestCallButton onSendRescheduleLink={() => setSendingRescheduleLink({classRequest: cr})} onReschedule={() => directToReschedulePage(cr)}/>
      });
    });

    useEffect(() => {
      loadWeekTestCalls();
    }, [weekOffset]);

    return (<div>
      <div style={{textAlign: "center"}}>Calls from {lastMonday.format("DD-MMM")} to {moment(nextMonday).subtract(1, 'day').format("DD-MMM")} (YOUR LOCAL TIME)</div>
      <Button text="Previous" onClick={() => setWeekOffset(weekOffset - 1)}/>
      <Button text="Next" onClick={() => setWeekOffset(weekOffset + 1)}/>
      <div style={{height: "80vh", overflowY: "scroll"}}>
        <div style={{height: 1200}}>
          <EventsSchedule hourRange={[8, 24]} events={events} startDate={lastMonday}/>
        </div>
      </div>
      {sendingRescheduleLink &&
        <ConfirmationDialog message={`This will send a self-rescheduling test call link to the student. Are you sure?`}
                            onConfirm={() => sendSelfRescheduleLink()}
                            onReject={() => setSendingRescheduleLink(false)}/>
      }
    </div>);

    function directToReschedulePage(classRequest) {
      history.push("/testCalls/new", {studentId: classRequest.userId, classRequest});
    }

    async function sendSelfRescheduleLink() {
      const cr = sendingRescheduleLink.classRequest;
      try {
        await sendRescheduleLinkToStudent(latestTestCallsByCrId[cr._id], cr);
      } catch (e) {
        logError("Failed to send self-rescheduling link to student", e);
      }
      setSendingRescheduleLink(false);
    }
  }
);

function RescheduleTestCallButton({onSendRescheduleLink, onReschedule}) {
  return (
    <div className={styles.rescheduleButtonsContainer}>
      <span onClick={onSendRescheduleLink}
            className={styles.selfRescheduleBtn}>📲</span>
      <span onClick={onReschedule}
            className={styles.rescheduleRedirectBtn}>⏰</span>
    </div>
  )
}
import React, {useState} from 'react';
import {coursesStore} from "../store/coursesStore";
import style from "../users/userField.scss";

export function CoursePicker({onCourseSelected, initialSearchString}) {
  const [searchString, setSearchString] = useState(initialSearchString || "");
  const [courses, setCourses] = useState([]);

  function updateOptions(ev) {
    const input = ev.target.value;
    if (input.length >= 3) {
      setSearchString(input);
      setCourses(coursesStore.activeCourses.filter(c => c.name.toLowerCase().includes(input.toLowerCase())));
    } else {
      setSearchString(input);
      setCourses([]);
    }
  }

  function optionSelected(course) {
    setSearchString(course.name);
    setCourses([]);
    onCourseSelected(course);
  }

  function formSubmitted(ev) {
    ev.preventDefault();
    if (courses.length === 1)
      optionSelected(courses[0]);
  }

  return (
    <div>
      <form onSubmit={formSubmitted}>
        <input type="text" autoFocus onChange={updateOptions} value={searchString}/>
        {courses && courses.length > 0 &&
          <div className={style.suggestions}>
            {courses.map(c =>
              <div className={style.suggestion} onClick={() => optionSelected(c)}>{c.name}</div>
            )}
          </div>
        }
      </form>
    </div>
  );
}

import React from 'react';
import {usersStore} from "../store/usersStore";
import {withdrawalReasons} from "../api/classRequestApi";
import {Button} from "../controls/button";
import {classRequestApi} from "../api/classRequestApi";
import history from "../history/history";
import {Spinner} from "../controls/spinner";
import style from "./classRequestWithdrawer.scss";
import {observer} from "mobx-react";
import {classRequestStates} from "../api/classRequestApi";
import {logError} from "../errors/errorConsole";

@observer
export class ClassRequestWithdrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  async withdrawClassRequest(reason) {
    const cr = this.props.location.state.classRequest;
    this.setState({submitting: true});
    try {
      await classRequestApi.updateClassRequest({...cr, state: classRequestStates.WITHDRAWN, withdrawalReason: reason});
    } catch (e) {
      logError(`Failed to withdraw class request ${cr._id}`, e);
      this.setState({submitting: false});
    }
    history.push("/classRequests");
  }

  render() {
    const cr = this.props.location.state.classRequest;
    const studentName = cr.userId && usersStore.getUserById(cr.userId) && usersStore.getUserById(cr.userId).name;
    return (
      <div>
        You're marking {studentName}'s class request as <strong>WITHDRAWN</strong>.
        <br/>
        Select the suspected reason:
        <div className={style.reasonsList}>
          {
            this.state.submitting ?
              <Spinner/> :
              Object.keys(withdrawalReasons).map(reason =>
                <Button className={style.reasonButton}
                        text={withdrawalReasons[reason]}
                        onClick={() => this.withdrawClassRequest(reason)}/>
              )
          }
        </div>
      </div>
    );
  }
}


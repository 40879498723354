import React, { useEffect, useState } from "react";
import {courseApi} from "../api/courseApi";
import {Spinner} from "../controls/spinner";

export function MeetingRecordings(props) {
  const meetingId = props.match.params.meetingId;
  const [recordings, setRecordings] = useState(null);

  useEffect(() => {
    fetchMeetingRecordingsFromAWS();
  }, []);

  async function fetchMeetingRecordingsFromAWS() {
    let recs;
    if (meetingId) {
      recs = await courseApi.getRecordingsForMeeting(meetingId);
    } else {
      recs = await courseApi.listAllRecordings();
    }
    setRecordings(recs);
  }

  return (
    <div style={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", marginTop: "48px"}}>
      {!recordings && <Spinner/>}
      {recordings && recordings.length > 0 &&
        <table style={{border: "1px solid black"}}>
          {
            recordings.map((r) => (
              <tr>
                <td style={{padding: "24px"}}>{r.name}</td>
                <td><a href={r.url} target={"_blank"}>Play</a></td>
              </tr>
            ))
          }
        </table> 
      }
      {recordings && recordings.length === 0 &&
        <>
          No recordings found for meeting {meetingId}
        </>
      }
    </div>
  )
}
import React from "react";
import {userApi} from "../api/userApi";
import style from "./login.scss";
import history from '../history/history';
import {Button} from "../controls/button";
import {Spinner} from "../controls/spinner";

const styleInput = {
  marginBottom: "10px", 
  marginTop: "10px",
  fontSize: "20px",
}

export class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submitClicked = this.submitClicked.bind(this);
    this.emailChanged = this.emailChanged.bind(this);
    this.pwdChanged = this.pwdChanged.bind(this);
  }

  render() {
    return (
      <div className={style.login}>
        <div>Create your ninja account</div>
        <input type="text"
               style={styleInput}
               value={this.state.email}
               onChange={this.emailChanged}
               placeholder="E-mail"/>
        <input type="password"
               style={styleInput}
               value={this.state.password}
               onChange={this.pwdChanged}
               placeholder="Password"/>
        <Button onClick={this.submitClicked}>
          {this.state.submitting && <Spinner/> || "Create account"}
        </Button>
      </div>
    );
  }

  emailChanged(evt) {
    this.setState({email: evt.target.value});
  }

  pwdChanged(evt) {
    this.setState({password: evt.target.value});
  }

  async submitClicked() {
    this.setState({submitting: true})
    const creds = {email: this.state.email, password: this.state.password};
    await userApi.createUser(creds);
    history.push('/login');
    this.setState({submitting: false})
  }
}
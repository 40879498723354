// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MXrnoDwo{position:absolute;transition:all 100ms ease-in-out;width:17px;height:17px;border-radius:50%;background-color:indianred;display:inline-flex;align-items:center;justify-content:center;text-align:center;cursor:pointer;font-weight:bold;font-size:12px;color:white;right:10px;top:10px;z-index:100}.MXrnoDwo:hover{background-color:red;transform:scale(1.2)}\n", "",{"version":3,"sources":["webpack://./src/controls/removeButton.scss"],"names":[],"mappings":"AAAA,UACE,iBAAkB,CAClB,gCAAiC,CACjC,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,0BAA2B,CAC3B,mBAAoB,CACpB,kBAAmB,CACnB,sBAAuB,CACvB,iBAAkB,CAClB,cAAe,CACf,gBAAiB,CACjB,cAAe,CACf,WAAY,CACZ,UAAW,CACX,QAAS,CACT,WAAY,CAjBd,gBAoBI,oBAAqB,CACrB,oBAAqB","sourcesContent":[".button {\n  position: absolute;\n  transition: all 100ms ease-in-out;\n  width: 17px;\n  height: 17px;\n  border-radius: 50%;\n  background-color: indianred;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  cursor: pointer;\n  font-weight: bold;\n  font-size: 12px;\n  color: white;\n  right: 10px;\n  top: 10px;\n  z-index: 100;\n\n  &:hover {\n    background-color: red;\n    transform: scale(1.2);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "MXrnoDwo"
};
export default ___CSS_LOADER_EXPORT___;

import {api} from './api';
import {teachersData} from "./teachersData";

export const teacherApi = {
  searchTeachers: async (teacherIds) => {
    //TODO search only those with the Ids specified
    return await api.teacherSvcRequest('GET', `/teachers`);
  },

  getHardcodedTeachers: async () => {
    return teachersData;
  },

  createTeacher: async (newTeacher) => {
    return api.teacherSvcRequest('POST', '/teachers', newTeacher);
  },

  updateTeacher: async (updatedTeacher) => {
    return api.teacherSvcRequest('PUT', `/teachers/${updatedTeacher._id}`, updatedTeacher);
  },

  getTeacher: async (id) => {
    return api.teacherSvcRequest('GET', `/teachers/${id}`);
  },

  createTeacherPaymentDetails: async (paymentDetails) => {
    return api.teacherSvcRequest('POST', `/teacherPaymentDetails`, paymentDetails);
  },

  updateTeacherPaymentDetails: async (paymentDetails) => {
    return api.teacherSvcRequest('PUT', `/teacherPaymentDetails/${paymentDetails._id}`, paymentDetails);
  },

  getTeacherPaymentDetails: async (teacherId) => {
    return (await api.teacherSvcRequest('GET', `/teacherPaymentDetails?teacherId=${teacherId}`))[0];
  },

  getOpenApplications: async () => {
    return api.teacherSvcRequest('GET', `/teacherApplications`);
  },

  createInterview: async (interview) => {
    return api.teacherSvcRequest('POST', `/teacherInterviews`, interview);
  },

  createPrepCall: async (prepCall) => {
    return api.teacherSvcRequest('POST', `/teacherPrepCalls`, prepCall);
  },

  rejectBeforeInterview: async(application) => {
    return api.teacherSvcRequest('POST', `/teacherAssessmentRejections`, {applicationId: application._id});
  },

  rejectForPlagiarsm: async(application) => {
    return api.teacherSvcRequest('POST', `/teacherAssessmentRejections`, {applicationId: application._id, plagiarised: true});
  },

  requestCV: async(application) => {
    return api.teacherSvcRequest('POST', `/teacherCvRequests`, {applicationId: application._id});
  },

  rejectAfterInterview: async(application) => {
    return api.teacherSvcRequest('POST', `/teacherInterviewRejections`, {applicationId: application._id, rejectedForever: application.rejectedForever});
  },

  dropApplication: async(application) => {
    return api.teacherSvcRequest('POST', `/teacherApplicationDrops`, {applicationId: application._id});
  },

  moveToProbation: async(application) => {
    return api.teacherSvcRequest('POST', `/teacherProbations`, {applicationId: application._id});
  },

  hire: async(application) => {
    return api.teacherSvcRequest('POST', `/teacherHires`, {applicationId: application._id});
  },
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gWlwh1Bm{margin:10px 0;padding:10px;border:1px solid #bedbff;border-radius:5px}.gWlwh1Bm .SQ7deGWG{font-size:12px;padding:5px 20px}.gWlwh1Bm .x8gkN3_7{border:1px solid;margin:10px;border-radius:10px;padding:10px;display:inline-block}.gWlwh1Bm .RlhkEvQ6{border:0.5px solid #aaa;cursor:pointer;border-radius:6px;padding:6px 10px}\n", "",{"version":3,"sources":["webpack://./src/courses/coursePackages.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAEA,UACE,aAAc,CACd,YAAa,CACb,wBCDqB,CDErB,iBAAkB,CAJpB,oBAOI,cAAe,CACf,gBAAiB,CARrB,oBAYI,gBAAiB,CACjB,WAAY,CACZ,kBAAmB,CACnB,YAAa,CACb,oBAAqB,CAhBzB,oBAoBI,uBAAwB,CACxB,cAAe,CACf,iBAAkB,CAClB,gBAAiB","sourcesContent":["@import \"../theme.scss\";\n\n.coursePackages {\n  margin: 10px 0;\n  padding: 10px;\n  border: 1px solid $accent1-tint1;\n  border-radius: 5px;\n\n  .addPkgBtn {\n    font-size: 12px;\n    padding: 5px 20px;\n  }\n\n  .package {\n    border: 1px solid;\n    margin: 10px;\n    border-radius: 10px;\n    padding: 10px;\n    display: inline-block;\n  }\n\n  .deletePackageButton {\n    border: 0.5px solid #aaa;\n    cursor: pointer;\n    border-radius: 6px;\n    padding: 6px 10px;\n  }\n}","$main-color1: #fee773;\n$main-color2: #ffa23e;\n\n$accent1-tint2: #e6effc;\n$accent1-tint1: #bedbff;\n$accent1: #a5c0e5;\n$accent1-shade1: #84a5d1;\n$accent1-shade2: #455d89;\n$accent1-shade3: #2d3037;\n\n$accent2-tint2: #c1d7f4;\n$accent2-tint1: #77a5e1;\n$accent2: #4783d0;\n$accent2-shade1: #1b65c3;\n$accent2-shade2: #09448f;\n\n$accent3-tint2: #f9fbff;\n$accent3-tint1: #9dc1f0;\n$accent3: #5c95de;\n$accent3-shade1: #286fcb;\n$accent3-shade2: #09448f;\n\n$accent4-tint2: #e0e0e0;\n$accent4-tint1: #d6d6d6;\n$accent4: #bfc8e1;\n$accent4-shade1: #7281ae;\n$accent4-shade2: #47568a;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coursePackages": "gWlwh1Bm",
	"addPkgBtn": "SQ7deGWG",
	"package": "x8gkN3_7",
	"deletePackageButton": "RlhkEvQ6"
};
export default ___CSS_LOADER_EXPORT___;

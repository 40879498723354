import React, {useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {Button} from "../controls/button";
import {NeatFormField} from "../controls/neatFormField";
import {useLocation} from 'react-router';
import history from "../history/history";
import {logError} from "../errors/errorConsole";
import {Spinner} from "../controls/spinner";
import {coursesStore} from "../store/coursesStore";
import style from "./paymentRequestEditor.scss";
import {observer} from "mobx-react";
import {usersStore} from "../store/usersStore";
import moment from "moment/moment";
import {NeatFieldValue} from "../controls/neatFieldValue";
import {courseApi} from "../api/courseApi";

export function ReimbursementEditor() {
  const {state} = useLocation();
  const course = state && state.course;
  if (!course) {
    history.push('/courses');
    return;
  }

  const [isSubmitting, setSubmitting] = useState(false);
  const [reimbursement, setReimbursement] = useState(null)

  
  const eCourse = coursesStore.coursesById[course._id];
  if (!eCourse) return null;
  const {remainingCreditPerPayer} = eCourse.unearnedRevenues
  const payersWithCredit = Object.entries(remainingCreditPerPayer)
    .filter(([_, credit]) => credit > 0)
    .map(([uId]) => uId);

  return (
    <div style={{padding: 10}}>
      You're creating a REIMBURSEMENT for course {course.name}
      
      {!reimbursement && <>
        <div className={style.boldText}>SELECT PAYER</div>
        {payersWithCredit.map(payerId =>
            <Payer payerId={payerId} onClick={() => {
              const reimbursement = {
                courseId: course._id,
                userId: "",
                numHours: 0,
                amount: 0,
                studentIds: []
              }
              const latestPurchase = eCourse.latestPaidPurchasePerUserId[payerId]; 
              reimbursement.currency = latestPurchase.currency;
              reimbursement.studentIds = latestPurchase.studentIds.map(sId => sId);
              reimbursement.userId = payerId;
              setReimbursement(reimbursement);
            }}/>
        )}
      </>}
      
      {reimbursement &&
        <Formik
          initialValues={reimbursement}
          enableReinitialize={true}
          onSubmit={submit}
        >
          {({submitForm}) => (
            <Form>
              <NeatFieldValue value={usersStore.getUserById(reimbursement.userId)?.name}
                              label="Payer"/>
              <NeatFieldValue value={reimbursement.studentIds.map(stId => usersStore.getUserById(stId)?.name).join(", ") }
                              label="Students"/>
              <NeatFormField name="numHours"
                             label="# hours"
                             type="number"/>
              <NeatFormField name="amount"
                             label="Amount to reimburse"
                             type="number"/>
              <NeatFormField name="currency"
                             label="Currency"
                             component="select">
                <option value="MYR">MYR</option>
                <option value="IDR">IDR</option>
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
                <option value="COP">COP</option>
                <option value="CLP">CLP</option>
                <option value="MXN">MXN</option>
                <option value="KRW">KRW</option>
                <option value="TWD">TWD</option>
                <option value="INR">INR</option>
                <option value="VND">VND</option>
                <option value="SGD">SGD</option>
              </NeatFormField>
              <div>
                <Button noArrow={isSubmitting}
                        onClick={submitForm}
                        disabled={isSubmitting}
                        text={!isSubmitting && "Reimburse"}>
                  {isSubmitting && <Spinner/>}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    </div>
  );

  async function submit(reimbursement) {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      await courseApi.createReimbursement(reimbursement);
      history.push('/courses');
    } catch (e) {
      logError("error creating reimbursement", e);
    }
    setSubmitting(false);
  }
}

export const Payer = observer(
  function Payer({payerId, onClick}) {
    const payer = usersStore.getUserById(payerId);
    return (
      <div className={
        `${style.payerStudents} ${onClick ? style.clickable : ""}`
      } onClick={onClick}>
        <div className={style.payer}>
          {payer ? <>
            {payer.name} <span className={style.country}>({payer.country})</span>
          </> : "..."}
        </div>
        <div className={style.creationTime}>{moment(payer?.creationTime).format("DD-MM-YYYY HH:mm:ss")}</div>
      </div>
    );
  }
);
import React from 'react';
import homeStyle from '../main/home.scss';
import {Menu} from "../menu/menu";
import {teacherApi} from "../api/teacherApi";
import style from './teachers.scss';
import {Link} from "react-router-dom";
import history from "../history/history";
import {Button} from "../controls/button";
import {coursesStore} from "../store/coursesStore";
import {observer} from "mobx-react";
import {courseApi, getForeignTeacherIds, getIndonesianTeacherIds} from "../api/courseApi";
import {userSession} from "../api/userSession";
import {validSpecialisations} from "./teacherEditor";


@observer
export class Teachers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unpaidTeachers: [],
      allTeachers: [],
      activeTeachers: []
    };
    setTimeout(async () => {
      const allTeachers = (await teacherApi.searchTeachers()).sort((t1, t2) => t1.name > t2.name ? 1 : -1);
      const unpaidTeacherIds = await courseApi.getTeacherIdsWithUnpaidMeetings();
      const unpaidTeachers = allTeachers.filter(t => unpaidTeacherIds.includes(t._id));
      let activeTeachers = await courseApi.getActiveTeachers();
      activeTeachers = activeTeachers.concat([...getForeignTeacherIds(), ...getIndonesianTeacherIds()].filter(tId => !activeTeachers.includes(tId)));
      this.setState({
        unpaidTeachers,
        allTeachers,
        activeTeachers
      });
    })
  }

  getMailtoLink() {
    return this.mailToLink = `mailto:?bcc=${this.state.activeTeachers.map(teacher => teacher.email).join(',')}`;
  }

  render() {
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.list}>
          <div className={style.teachers}>
            <Link to="/teachers/new">
              <Button className={style.headerBtn}>Add</Button>
            </Link>
            {this.state.activeTeachers.length !== 0 && userSession.getUserGroups().includes("ADMINS") &&
              <a href={this.getMailtoLink()}>
                <Button className={style.headerBtn} text="Mass mail"/>
              </a>
            }
            <div className={style.subsectionHeader}>Active since last payslip</div>
            {this.state.unpaidTeachers.map(t =>
              (t && <Teacher key={t._id}
                       teacher={t}
                       onEdit={editTeacher.bind(null, t)}
                       onPay={payTeacher.bind(null, t)}
                       onNotify={notifyTeacher.bind(null, t)}
                       onProfile={copyProfileLinkToClipboard.bind(null, t)}
              />)
            )}
            <div className={style.subsectionHeader}>All teachers</div>
            {this.state.allTeachers.map(t =>
              (t && <Teacher key={t._id}
                             teacher={t}
                             onEdit={editTeacher.bind(null, t)}
                             onPay={payTeacher.bind(null, t)}
                             onNotify={notifyTeacher.bind(null, t)}
                             onProfile={copyProfileLinkToClipboard.bind(null, t)}
              />)
            )}
          </div>
        </div>
      </div>
    );
  }
}

@observer
class Teacher extends React.Component {
  render() {
    const teacher = this.props.teacher;
    const courses = coursesStore.activeCoursesByTeacherId[teacher._id] || [];
    return (
      <div className={style.teacher}>
        <div className={style.namebar}>{teacher.name} {teacher.specialisations?.map(s => <div className={style.specialisation}>{validSpecialisations[s]}</div>)} </div>
        <div className={style.courses}>· {courses.map(c => c.name +' · ')}</div>
        <Button className={style.teacherButton} text="Edit" onClick={this.props.onEdit}/>
        <Button className={style.teacherButton} text="Pay" onClick={this.props.onPay}/>
        <Button className={style.teacherButton} text="Send notif" onClick={this.props.onNotify}/>
        <Button className={style.teacherButton}>
          <Link to={`/teachers/${teacher._id}/payslips`}>
            Payslips
          </Link>
        </Button>
        <Button className={style.teacherButton} text="Profile " noArrow onClick={this.props.onProfile}>
            &#x1f4cb;
        </Button>
        <Button className={style.teacherButton}>
          <Link to={`/teachers/${teacher._id}/schedule`}>
            Schedule
          </Link>
        </Button>
      </div>
    );
  }
}

function editTeacher(teacher) {
  history.push(`/teachers/${teacher._id}`, {teacher});
}

function payTeacher(teacher) {
  history.push(`/teachers/${teacher._id}/payslips/new`, {teacher});
}

function notifyTeacher(teacher) {
  history.push(`/teachers/${teacher._id}/notification`, {teacher});
}

function copyProfileLinkToClipboard(teacher) {
  window.navigator.clipboard.writeText(`cerah.co/teachers/${teacher._id}`);
}

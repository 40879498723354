export const idChannelCodes = [
  { channelCode: 'ID_ACEH', type: 'Bank', bankName: 'BPD Aceh' },
  { channelCode: 'ID_ACEH_UUS', type: 'Bank', bankName: 'BPD Aceh UUS' },
  {
    channelCode: 'ID_AGRONIAGA',
    type: 'Bank',
    bankName: 'Bank Raya Indonesia (formerly called Bank BRI Agroniaga)'
  },
  {
    channelCode: 'ID_ALADIN',
    type: 'Bank',
    bankName: 'Bank Aladin Syariah (formerly Bank Maybank Syariah Indonesia)'
  },
  {
    channelCode: 'ID_ALLO',
    type: 'Bank',
    bankName: 'Allo Bank Indonesia (formerly Bank Harda Internasional)'
  },
  {
    channelCode: 'ID_AMAR',
    type: 'Bank',
    bankName: 'Bank Amar Indonesia (formerly called Anglomas International Bank)'
  },
  {
    channelCode: 'ID_ANZ',
    type: 'Bank',
    bankName: 'Bank ANZ Indonesia'
  },
  {
    channelCode: 'ID_ARTHA',
    type: 'Bank',
    bankName: 'Bank Artha Graha International'
  },
  { channelCode: 'ID_BALI', type: 'Bank', bankName: 'BPD Bali' },
  {
    channelCode: 'ID_BAML',
    type: 'Bank',
    bankName: 'Bank of America Merill-Lynch'
  },
  {
    channelCode: 'ID_BANTEN',
    type: 'Bank',
    bankName: 'BPD Banten (formerly called Bank Pundi Indonesia)'
  },
  {
    channelCode: 'ID_BCA',
    type: 'Bank',
    bankName: 'Bank Central Asia (BCA)'
  },
  {
    channelCode: 'ID_BCA_DIGITAL',
    type: 'Bank',
    bankName: 'Bank Central Asia Digital (BluBCA)'
  },
  {
    channelCode: 'ID_BCA_SYR',
    type: 'Bank',
    bankName: 'Bank Central Asia (BCA) Syariah'
  },
  { channelCode: 'ID_BENGKULU', type: 'Bank', bankName: 'BPD Bengkulu' },
  {
    channelCode: 'ID_BISNIS_INTERNASIONAL',
    type: 'Bank',
    bankName: 'Bank Bisnis Internasional'
  },
  { channelCode: 'ID_BJB', type: 'Bank', bankName: 'Bank BJB' },
  {
    channelCode: 'ID_BJB_SYR',
    type: 'Bank',
    bankName: 'Bank BJB Syariah'
  },
  {
    channelCode: 'ID_BNC',
    type: 'Bank',
    bankName: 'Bank Neo Commerce (formerly Bank Yudha Bhakti)'
  },
  {
    channelCode: 'ID_BNI',
    type: 'Bank',
    bankName: 'Bank Negara Indonesia (BNI)'
  },
  {
    channelCode: 'ID_BNP_PARIBAS',
    type: 'Bank',
    bankName: 'Bank BNP Paribas'
  },
  {
    channelCode: 'ID_BOC',
    type: 'Bank',
    bankName: 'Bank of China (BOC)'
  },
  {
    channelCode: 'ID_BRI',
    type: 'Bank',
    bankName: 'Bank Rakyat Indonesia (BRI)'
  },
  {
    channelCode: 'ID_BSI',
    type: 'Bank',
    bankName: 'Bank Syariah Indonesia (BSI)'
  },
  {
    channelCode: 'ID_BTN',
    type: 'Bank',
    bankName: 'Bank Tabungan Negara (BTN)'
  },
  {
    channelCode: 'ID_BTN_UUS',
    type: 'Bank',
    bankName: 'Bank Tabungan Negara (BTN) UUS'
  },
  {
    channelCode: 'ID_BTPN_SYARIAH',
    type: 'Bank',
    bankName: 'BTPN Syariah (formerly called BTPN UUS and Bank Sahabat Purba Danarta)'
  },
  { channelCode: 'ID_BUKOPIN', type: 'Bank', bankName: 'Bank Bukopin' },
  {
    channelCode: 'ID_BUKOPIN_SYR',
    type: 'Bank',
    bankName: 'Bank Syariah Bukopin'
  },
  {
    channelCode: 'ID_BUMI_ARTA',
    type: 'Bank',
    bankName: 'Bank Bumi Arta'
  },
  {
    channelCode: 'ID_CAPITAL',
    type: 'Bank',
    bankName: 'Bank Capital Indonesia'
  },
  {
    channelCode: 'ID_CCB',
    type: 'Bank',
    bankName: 'China Construction Bank Indonesia (formerly called Bank Antar Daerah and Bank Windu Kentjana International)'
  },
  {
    channelCode: 'ID_CHINATRUST',
    type: 'Bank',
    bankName: 'Bank Chinatrust Indonesia'
  },
  { channelCode: 'ID_CIMB', type: 'Bank', bankName: 'Bank CIMB Niaga' },
  {
    channelCode: 'ID_CIMB_UUS',
    type: 'Bank',
    bankName: 'Bank CIMB Niaga UUS'
  },
  { channelCode: 'ID_CITIBANK', type: 'Bank', bankName: 'Citibank' },
  {
    channelCode: 'ID_COMMONWEALTH',
    type: 'Bank',
    bankName: 'Bank Commonwealth'
  },
  {
    channelCode: 'ID_DAERAH_ISTIMEWA',
    type: 'Bank',
    bankName: 'BPD Daerah Istimewa Yogyakarta (DIY)'
  },
  {
    channelCode: 'ID_DAERAH_ISTIMEWA_UUS',
    type: 'Bank',
    bankName: 'BPD Daerah Istimewa Yogyakarta (DIY) UUS'
  },
  { channelCode: 'ID_DANAMON', type: 'Bank', bankName: 'Bank Danamon' },
  {
    channelCode: 'ID_DANAMON_UUS',
    type: 'Bank',
    bankName: 'Bank Danamon UUS'
  },
  {
    channelCode: 'ID_DBS',
    type: 'Bank',
    bankName: 'Bank DBS Indonesia'
  },
  {
    channelCode: 'ID_DEUTSCHE',
    type: 'Bank',
    bankName: 'Deutsche Bank'
  },
  {
    channelCode: 'ID_DINAR_INDONESIA',
    type: 'Bank',
    bankName: 'Bank Dinar Indonesia'
  },
  { channelCode: 'ID_DKI', type: 'Bank', bankName: 'Bank DKI' },
  { channelCode: 'ID_DKI_UUS', type: 'Bank', bankName: 'Bank DKI UUS' },
  {
    channelCode: 'ID_FAMA',
    type: 'Bank',
    bankName: 'Bank Fama International'
  },
  { channelCode: 'ID_GANESHA', type: 'Bank', bankName: 'Bank Ganesha' },
  { channelCode: 'ID_HANA', type: 'Bank', bankName: 'Bank Hana' },
  {
    channelCode: 'ID_HSBC',
    type: 'Bank',
    bankName: 'HSBC Indonesia (formerly called Bank Ekonomi Raharja)'
  },
  {
    channelCode: 'ID_HSBC_UUS',
    type: 'Bank',
    bankName: 'Hongkong and Shanghai Bank Corporation (HSBC) UUS'
  },
  {
    channelCode: 'ID_IBK',
    type: 'Bank',
    bankName: 'Bank IBK Indonesia (formerly Bank Agris)'
  },
  {
    channelCode: 'ID_ICBC',
    type: 'Bank',
    bankName: 'Bank ICBC Indonesia'
  },
  {
    channelCode: 'ID_INA_PERDANA',
    type: 'Bank',
    bankName: 'Bank Ina Perdania'
  },
  {
    channelCode: 'ID_INDEX_SELINDO',
    type: 'Bank',
    bankName: 'Bank Index Selindo'
  },
  {
    channelCode: 'ID_INDIA',
    type: 'Bank',
    bankName: 'Bank of India Indonesia'
  },
  {
    channelCode: 'ID_JAGO',
    type: 'Bank',
    bankName: 'Bank Jago (formerly Bank Artos Indonesia)'
  },
  { channelCode: 'ID_JAMBI', type: 'Bank', bankName: 'BPD Jambi' },
  {
    channelCode: 'ID_JAMBI_UUS',
    type: 'Bank',
    bankName: 'BPD Jambi UUS'
  },
  {
    channelCode: 'ID_JASA_JAKARTA',
    type: 'Bank',
    bankName: 'Bank Jasa Jakarta'
  },
  {
    channelCode: 'ID_JAWA_TENGAH',
    type: 'Bank',
    bankName: 'BPD Jawa Tengah'
  },
  {
    channelCode: 'ID_JAWA_TENGAH_UUS',
    type: 'Bank',
    bankName: 'BPD Jawa Tengah UUS'
  },
  {
    channelCode: 'ID_JAWA_TIMUR',
    type: 'Bank',
    bankName: 'BPD Jawa Timur'
  },
  {
    channelCode: 'ID_JAWA_TIMUR_UUS',
    type: 'Bank',
    bankName: 'BPD Jawa Timur UUS'
  },
  {
    channelCode: 'ID_JPMORGAN',
    type: 'Bank',
    bankName: 'JP Morgan Chase Bank'
  },
  {
    channelCode: 'ID_JTRUST',
    type: 'Bank',
    bankName: 'Bank JTrust Indonesia (formerly called Bank Mutiara)'
  },
  {
    channelCode: 'ID_KALIMANTAN_BARAT',
    type: 'Bank',
    bankName: 'BPD Kalimantan Barat'
  },
  {
    channelCode: 'ID_KALIMANTAN_BARAT_UUS',
    type: 'Bank',
    bankName: 'BPD Kalimantan Barat UUS'
  },
  {
    channelCode: 'ID_KALIMANTAN_SELATAN',
    type: 'Bank',
    bankName: 'BPD Kalimantan Selatan'
  },
  {
    channelCode: 'ID_KALIMANTAN_SELATAN_UUS',
    type: 'Bank',
    bankName: 'BPD Kalimantan Selatan UUS'
  },
  {
    channelCode: 'ID_KALIMANTAN_TENGAH',
    type: 'Bank',
    bankName: 'BPD Kalimantan Tengah'
  },
  {
    channelCode: 'ID_KALIMANTAN_TIMUR',
    type: 'Bank',
    bankName: 'BPD Kalimantan Timur'
  },
  {
    channelCode: 'ID_KALIMANTAN_TIMUR_UUS',
    type: 'Bank',
    bankName: 'BPD Kalimantan Timur UUS'
  },
  { channelCode: 'ID_LAMPUNG', type: 'Bank', bankName: 'BPD Lampung' },
  { channelCode: 'ID_MALUKU', type: 'Bank', bankName: 'BPD Maluku' },
  { channelCode: 'ID_MANDIRI', type: 'Bank', bankName: 'Bank Mandiri' },
  {
    channelCode: 'ID_MANDIRI_TASPEN',
    type: 'Bank',
    bankName: 'Mandiri Taspen Pos (formerly called Bank Sinar Harapan Bali)'
  },
  {
    channelCode: 'ID_MASPION',
    type: 'Bank',
    bankName: 'Bank Maspion Indonesia'
  },
  {
    channelCode: 'ID_MAYAPADA',
    type: 'Bank',
    bankName: 'Bank Mayapada International'
  },
  { channelCode: 'ID_MAYBANK', type: 'Bank', bankName: 'Bank Maybank' },
  {
    channelCode: 'ID_MAYBANK_SYR',
    type: 'Bank',
    bankName: 'Bank Maybank Syariah Indonesia'
  },
  { channelCode: 'ID_MAYORA', type: 'Bank', bankName: 'Bank Mayora' },
  { channelCode: 'ID_MEGA', type: 'Bank', bankName: 'Bank Mega' },
  {
    channelCode: 'ID_MEGA_SYR',
    type: 'Bank',
    bankName: 'Bank Syariah Mega'
  },
  {
    channelCode: 'ID_MESTIKA_DHARMA',
    type: 'Bank',
    bankName: 'Bank Mestika Dharma'
  },
  {
    channelCode: 'ID_MIZUHO',
    type: 'Bank',
    bankName: 'Bank Mizuho Indonesia'
  },
  {
    channelCode: 'ID_MNC_INTERNASIONAL',
    type: 'Bank',
    bankName: 'Bank MNC Internasional'
  },
  {
    channelCode: 'ID_MUAMALAT',
    type: 'Bank',
    bankName: 'Bank Muamalat Indonesia'
  },
  {
    channelCode: 'ID_MULTI_ARTA_SENTOSA',
    type: 'Bank',
    bankName: 'Bank Multi Arta Sentosa'
  },
  {
    channelCode: 'ID_NATIONALNOBU',
    type: 'Bank',
    bankName: 'Bank Nationalnobu'
  },
  {
    channelCode: 'ID_NUSA_TENGGARA_BARAT',
    type: 'Bank',
    bankName: 'BPD Nusa Tenggara Barat'
  },
  {
    channelCode: 'ID_NUSA_TENGGARA_TIMUR',
    type: 'Bank',
    bankName: 'BPD Nusa Tenggara Timur'
  },
  { channelCode: 'ID_OCBC', type: 'Bank', bankName: 'Bank OCBC NISP' },
  {
    channelCode: 'ID_OCBC_UUS',
    type: 'Bank',
    bankName: 'Bank OCBC NISP UUS'
  },
  {
    channelCode: 'ID_OKE',
    type: 'Bank',
    bankName: 'Bank Oke Indonesia (formerly called Bank Andara)'
  },
  { channelCode: 'ID_PANIN', type: 'Bank', bankName: 'Bank Panin' },
  {
    channelCode: 'ID_PANIN_SYR',
    type: 'Bank',
    bankName: 'Bank Panin Syariah'
  },
  { channelCode: 'ID_PAPUA', type: 'Bank', bankName: 'BPD Papua' },
  { channelCode: 'ID_PERMATA', type: 'Bank', bankName: 'Bank Permata' },
  {
    channelCode: 'ID_PERMATA_UUS',
    type: 'Bank',
    bankName: 'Bank Permata UUS'
  },
  {
    channelCode: 'ID_PRIMA_MASTER',
    type: 'Bank',
    bankName: 'Prima Master Bank'
  },
  {
    channelCode: 'ID_QNB_INDONESIA',
    type: 'Bank',
    bankName: 'Bank QNB Indonesia (formerly called Bank QNB Kesawan)'
  },
  {
    channelCode: 'ID_RABOBANK',
    type: 'Bank',
    bankName: 'Bank Rabobank International Indonesia'
  },
  {
    channelCode: 'ID_RESONA',
    type: 'Bank',
    bankName: 'Bank Resona Perdania'
  },
  {
    channelCode: 'ID_RIAU_DAN_KEPRI',
    type: 'Bank',
    bankName: 'BPD Riau Dan Kepri'
  },
  {
    channelCode: 'ID_SAHABAT_SAMPOERNA',
    type: 'Bank',
    bankName: 'Bank Sahabat Sampoerna'
  },
  {
    channelCode: 'ID_SBI_INDONESIA',
    type: 'Bank',
    bankName: 'Bank SBI Indonesia'
  },
  {
    channelCode: 'ID_SEABANK',
    type: 'Bank',
    bankName: 'Bank Seabank Indonesia (formerly Bank Kesejahteraan Ekonomi)'
  },
  {
    channelCode: 'ID_SHINHAN',
    type: 'Bank',
    bankName: 'Bank Shinhan Indonesia (formerly called Bank Metro Express)'
  },
  {
    channelCode: 'ID_SINARMAS',
    type: 'Bank',
    bankName: 'Bank Sinarmas'
  },
  {
    channelCode: 'ID_SINARMAS_UUS',
    type: 'Bank',
    bankName: 'Bank Sinarmas UUS'
  },
  {
    channelCode: 'ID_STANDARD_CHARTERED',
    type: 'Bank',
    bankName: 'Standard Charted Bank'
  },
  {
    channelCode: 'ID_SULAWESI',
    type: 'Bank',
    bankName: 'BPD Sulawesi Tengah'
  },
  {
    channelCode: 'ID_SULAWESI_TENGGARA',
    type: 'Bank',
    bankName: 'BPD Sulawesi Tenggara'
  },
  {
    channelCode: 'ID_SULSELBAR',
    type: 'Bank',
    bankName: 'BPD Sulselbar'
  },
  {
    channelCode: 'ID_SULSELBAR_UUS',
    type: 'Bank',
    bankName: 'BPD Sulselbar UUS'
  },
  {
    channelCode: 'ID_SULUT',
    type: 'Bank',
    bankName: 'BPD Sulut'
  },
  {
    channelCode: 'ID_SUMATERA_BARAT',
    type: 'Bank',
    bankName: 'BPD Sumatera Barat'
  },
  {
    channelCode: 'ID_SUMATERA_BARAT_UUS',
    type: 'Bank',
    bankName: 'BPD Sumatera Barat UUS'
  },
  {
    channelCode: 'ID_SUMSEL_DAN_BABEL',
    type: 'Bank',
    bankName: 'BPD Sumsel Dan Babel'
  },
  {
    channelCode: 'ID_SUMSEL_DAN_BABEL_UUS',
    type: 'Bank',
    bankName: 'BPD Sumsel Dan Babel UUS'
  },
  { channelCode: 'ID_SUMUT', type: 'Bank', bankName: 'BPD Sumut' },
  {
    channelCode: 'ID_SUMUT_UUS',
    type: 'Bank',
    bankName: 'BPD Sumut UUS'
  },
  {
    channelCode: 'ID_TABUNGAN_PENSIUNAN_NASIONAL',
    type: 'Bank',
    bankName: 'Bank Tabungan Pensiunan Nasional (BTPN)'
  },
  {
    channelCode: 'ID_TOKYO',
    type: 'Bank',
    bankName: 'Bank of Tokyo Mitsubishi UFJ (MUFJ)'
  },
  {
    channelCode: 'ID_UOB',
    type: 'Bank',
    bankName: 'Bank UOB Indonesia'
  },
  {
    channelCode: 'ID_VICTORIA_INTERNASIONAL',
    type: 'Bank',
    bankName: 'Bank Victoria Internasional'
  },
  {
    channelCode: 'ID_VICTORIA_SYR',
    type: 'Bank',
    bankName: 'Bank Victoria Syariah'
  },
  {
    channelCode: 'ID_WOORI',
    type: 'Bank',
    bankName: 'Bank Woori Indonesia'
  },
  
  // eWallet
  {
    channelCode: 'ID_DANA',
    type: 'E-Wallet',
    bankName: 'DANA (e-wallet)'
  },
  {
    channelCode: 'ID_GOPAY',
    type: 'E-Wallet',
    bankName: 'LinkAja (e-wallet)'
  },
  {
    channelCode: 'ID_OVO',
    type: 'E-Wallet',
    bankName: 'OVO (e-wallet)'
  },
  {
    channelCode: 'ID_SHOPEEPAY',
    type: 'E-Wallet',
    bankName: 'ShopeePay (e-wallet)'
  }
];

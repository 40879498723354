import React, {useEffect, useState} from "react";
import homeStyle from "../main/home.scss";
import style from "./scenarios.scss";
import {Menu} from "../menu/menu";
import {Spinner} from "../controls/spinner";
import {conversationApi} from "../api/conversationApi";
import {Button} from "../controls/button";
import {Link} from "react-router-dom";
import * as logger from "../errors/errorConsole";
import history from "../history/history";

export function Scenarios() {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creatingScenario, setCreatingScenario] = useState(false);
  
  useEffect(() => {
    loadScenarios();
  }, []);
  
  async function loadScenarios() {
    setLoading(true);
    setScenarios(await conversationApi.getAllScenarios());
    setLoading(false);
  }
  
  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={homeStyle.rightSidePanel}>
        <div className={homeStyle.list}>
          <Button onClick={createScenario} disabled={creatingScenario} noArrow>
            {creatingScenario ? <Spinner/> : "Add"}
          </Button>
          {loading && <Spinner/>}
          {!loading && scenarios.sort((s1, s2) => s1.name > s2.name ? 1 : -1).map(s => {
            return (<div className={style.scenario} key={s._id}>
              <div className={style.scenarioLabel}>
                {s.label}
              </div>
              <div>
                <Link to={`/scenarios/${s._id}`}>
                  {s.name}
                </Link>
              </div>
              <div className={style.conversationsLink}>
                <Link to={{pathname: `/conversations`, state: {scenarioId: s._id}}}>See conversations</Link>
              </div>
            </div>);
          })}
        </div>
      </div>
    </div>
  );
  
  async function createScenario() {
    setCreatingScenario(true);
    try {
      const newScenario = await conversationApi.createScenario({
        prompt: "", 
        name: "00 - New scenario", 
        label: "00 - New scenario",
        description: "",
      });
      history.push(`/scenarios/${newScenario._id}`);
    } catch (e) {
      await logger.logError("Could not create new scenario", e);      
    }
    setCreatingScenario(false);
  }
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UF5uf7is{display:flex;flex-direction:column;width:100%;padding:20px}.UF5uf7is .jeWSILwV{display:flex;flex-direction:column;width:100%}.UF5uf7is .jeWSILwV .w9_QBRUw{display:flex;padding:16px;border-radius:10px;width:100%;max-width:500;margin-top:10px;margin-bottom:10px;background-color:white;cursor:pointer;box-shadow:0px 5px 5px 0px rgba(0,0,0,0.2)}\n", "",{"version":3,"sources":["webpack://./src/drills/drills.scss"],"names":[],"mappings":"AAAA,UACE,YAAa,CACb,qBAAsB,CACtB,UAAW,CACX,YAAa,CAJf,oBAOI,YAAa,CACb,qBAAsB,CACtB,UAAW,CATf,8BAYM,YAAa,CACb,YAAa,CACb,kBAAmB,CACnB,UAAW,CACX,aAAc,CACd,eAAgB,CAChB,kBAAmB,CACnB,sBAAuB,CACvB,cAAe,CACf,0CAA8C","sourcesContent":[".drills {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  padding: 20px;\n\n  .drillsList {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    \n    .item {\n      display: flex;\n      padding: 16px;\n      border-radius: 10px;\n      width: 100%;\n      max-width: 500;\n      margin-top: 10px;\n      margin-bottom: 10px;\n      background-color: white;\n      cursor: pointer;\n      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2)\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drills": "UF5uf7is",
	"drillsList": "jeWSILwV",
	"item": "w9_QBRUw"
};
export default ___CSS_LOADER_EXPORT___;

import React, {useState} from "react";
import {userApi} from "../api/userApi";
import style from "./login.scss";
import history from '../history/history';
import {Button} from "../controls/button";
import {Formik, Form, Field} from "formik";
import {Spinner} from "../controls/spinner";
import {coursesStore} from "../store/coursesStore";


export function EmailLogin(props) {
  const [error, setError] = useState("");

  return (
    <div className={style.login}>
      {error && <div className={style.error}>{error}</div>}
      <div>Log into you ninja account</div>
      <Formik
        onSubmit={async (v, actions) => {
          try {
            await submit(v);
          } finally {
            actions.setSubmitting(false);
          }
        }}
        initialValues={{email: "", password: ""}}
      >
        {({submitForm, isSubmitting}) =>
          <Form>
            <Field type="text" name="email" placeholder="E-mail"/>
            <Field type="password" name="password" placeholder="password"
                   onKeyPress={tgt => tgt.charCode === 13 && submitForm()}/>
            <Button noArrow={isSubmitting} onClick={submitForm} text={!isSubmitting && 'Submit'}>
              {isSubmitting && <Spinner/>}
            </Button>
          </Form>
        }
      </Formik>
    </div>
  );

  async function submit({email, password}) {
    email = email.trim();
    try {
      await userApi.login({email, password});
      coursesStore.retrieveCourses();
      history.push('/');
    } catch (e) {
      switch (e.status) {
        case 422:
          setError("Wrong credentials");
          break;
        case 400:
          setError("Bad format");
          break;
        default:
          setError("An error ocurred with status " + e.status);
      }
    }
  }
}
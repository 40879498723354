// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xQSGUTTI{background-color:#e6effc;border-radius:10px;font-size:12px;margin:10px;padding:10px;color:#2d3037;line-height:2em}.xQSGUTTI .LVyjZV88{font-size:14px;color:#455d89;margin-bottom:10px}.xQSGUTTI .w_rYqWFC{font-size:16px;font-weight:700;color:#455d89}.xQSGUTTI .BkQ0HtY8{font-size:18px;font-weight:800;color:#2d3037}.xQSGUTTI .ANuXAdlm{color:#84a5d1;font-size:14px}\n", "",{"version":3,"sources":["webpack://./src/purchases/purchaseHistory.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAEA,UACE,wBCAqB,CDCrB,kBAAmB,CACnB,cAAe,CACf,WAAY,CACZ,YAAa,CACb,aCAsB,CDCtB,eAAgB,CAPlB,oBAUI,cAAe,CACf,aCNoB,CDOpB,kBAAmB,CAZvB,oBAgBI,cAAe,CACf,eAAgB,CAChB,aCboB,CDLxB,oBAsBI,cAAe,CACf,eAAgB,CAChB,aClBoB,CDNxB,oBA4BI,aCxBoB,CDyBpB,cAAe","sourcesContent":["@import \"../theme\";\n\n.purchase {\n  background-color: $accent1-tint2;\n  border-radius: 10px;\n  font-size: 12px;\n  margin: 10px;\n  padding: 10px;\n  color: $accent1-shade3;\n  line-height: 2em;\n\n  .date {\n    font-size: 14px;\n    color: $accent1-shade2;\n    margin-bottom: 10px;\n  }\n\n  .price {\n    font-size: 16px;\n    font-weight: 700;\n    color: $accent1-shade2\n  }\n\n  .numHours {\n    font-size: 18px;\n    font-weight: 800;\n    color: $accent1-shade3\n  }\n\n  .person {\n    color: $accent1-shade1;\n    font-size: 14px;\n  }\n\n}","$main-color1: #fee773;\n$main-color2: #ffa23e;\n\n$accent1-tint2: #e6effc;\n$accent1-tint1: #bedbff;\n$accent1: #a5c0e5;\n$accent1-shade1: #84a5d1;\n$accent1-shade2: #455d89;\n$accent1-shade3: #2d3037;\n\n$accent2-tint2: #c1d7f4;\n$accent2-tint1: #77a5e1;\n$accent2: #4783d0;\n$accent2-shade1: #1b65c3;\n$accent2-shade2: #09448f;\n\n$accent3-tint2: #f9fbff;\n$accent3-tint1: #9dc1f0;\n$accent3: #5c95de;\n$accent3-shade1: #286fcb;\n$accent3-shade2: #09448f;\n\n$accent4-tint2: #e0e0e0;\n$accent4-tint1: #d6d6d6;\n$accent4: #bfc8e1;\n$accent4-shade1: #7281ae;\n$accent4-shade2: #47568a;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"purchase": "xQSGUTTI",
	"date": "LVyjZV88",
	"price": "w_rYqWFC",
	"numHours": "BkQ0HtY8",
	"person": "ANuXAdlm"
};
export default ___CSS_LOADER_EXPORT___;

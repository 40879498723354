import {Field} from 'formik';
import React from 'react';

export function CheckboxField(props) {
  return (
    <Field name={props.name} type="text">
      {({
        field: {value},
        form
      }) => (
        <label>
          <input
            type="checkbox"
            {...props}
            checked={value && value.includes(props.value)}
            onChange={() => {
              if (!value) value = [];
              if (value.includes(props.value)) {
                form.setFieldValue(props.name, value.filter(v => v !== props.value));
              } else {
                form.setFieldValue(props.name, value.concat(props.value));
              }
            }}
          />
          {props.label}
        </label>
      )}
    </Field>
  );
}
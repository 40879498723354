import {api} from "./api";
import {userSession} from "./userSession";

export const notificationApi = {
  sendNotificationToTeacher: async (teacherId, notif) => {
    await api.notificationsSvcRequest('POST', '/pushNotifications', [{
      uid: Math.round(Math.random()*1000000) + " manual " + userSession.getUserId(),
      teacherId,
      title: notif.title,
      subtitle: notif.subtitle
    }]);
  },

  sendNotificationToStudent: async (studentId, notif) => {
    await api.notificationsSvcRequest('POST', '/studentNotifications', {
      uid: Math.round(Math.random()*1000000) + " manual " + userSession.getUserId(),
      studentId,
      templateId: "supportChat.message",
      data: {
        text: notif.text,
        __notificationType: "supportChat"
      },
    });
  },

  sendTemplatedNotificationToStudent: async (studentId, uid, templateId, data) => {
    await api.notificationsSvcRequest('POST', '/studentNotifications', {
      uid,
      studentId,
      templateId,
      data
    });
  },

  getStudentNotificationsTokens: async (userId) => {
    return api.notificationsSvcRequest('GET', `/student/notificationsTokens?userId=${userId}`);
  },
};

// import CastiqImg from '../../img/teachers/CASTIQ.png.webp';
// import WendyImg from '../../img/teachers/WENDY.png.webp';
// import SatyaImg from '../../img/teachers/SATYA KEVINO.png.webp';
// import VerineImg from '../../img/teachers/VERINE.png.webp';
//
// import PutriImg from '../../img/teachers/PUTRI.png.webp';
// import JosephineImg from '../../img/teachers/JOSEPHINE.png.webp';
// import WilbertImg from '../../img/teachers/WILBERT OSMOND.png.webp';
//
// import CastiqImgPng from '../../img/teachers/CASTIQ.png';
// import WendyImgPng from '../../img/teachers/WENDY.png';
// import SatyaImgPng from '../../img/teachers/SATYA KEVINO.png';
// import VerineImgPng from '../../img/teachers/VERINE.png';
//
// import PutriImgPng from '../../img/teachers/PUTRI.png';
// import JosephineImgPng from '../../img/teachers/JOSEPHINE.png';
// import WilbertImgPng from '../../img/teachers/WILBERT OSMOND.png';

class CourseTypes {}
CourseTypes.conversation = "conversation";
CourseTypes.general = "general";
CourseTypes.ielts = "ielts";
CourseTypes.toefl = "toefl";
CourseTypes.business = "business";

export const teachersData = [{
  id: 1,
  tier: 2,
  name: "Castiq",
  experience: "8 years",
  specialisations: [CourseTypes.toefl, CourseTypes.general, CourseTypes.conversation],
  // imageUrl: CastiqImg,
  // imageUrlPng: CastiqImgPng,
  ages: [0, 99],
  location: "Medan",
  preferredLocations: [{
    lat: 3.5644218,
    lon: 98.6673187
  }],
  schedule: {
    mon: [[9, 12]],
    tue: [[8.5, 11]],
    wed: [[9, 12]],
    thu: [[8.5, 11]],
    fri: [[9, 12]]
  },
  qualifications: [
    {
      degree: `Runner Up Lomba Pidato Nasional JCI (Junior Chamber International) April 2017. Graduatee of the Faculty of English Language and Literature at the Methodist University of Indonesia`,
      end: ""
    }
  ],
  achievements: [
    `President of Titanium Toastmasters Club 2016-present`,
    `Debate & Speech Trainer at Methodist University of Indonesia 2012-2015`,
    `Head of English Department at Scholars’ Hub Tuition Centre 2014-2015`
  ],
  phone: "+628126025829"
}, {
  id: 2,
  tier: 2,
  name: "Wendy",
  experience: `6 years`,
  specialisations: [CourseTypes.ielts, CourseTypes.toefl, CourseTypes.general, CourseTypes.conversation],
  // imageUrl: WendyImg,
  // imageUrlPng: WendyImgPng,
  ages: [12, 99],
  location: "Medan",
  preferredLocations: [{
    lat: 3.5900107,
    lon: 98.6818481
  }],
  schedule: {
    tue: [[15, 18]],
    thu: [[15, 18]],
  },
  qualifications: [
    {
      degree: `English teacher at Sekolah Mentari Bangsa (2013-2014) and Edumate International (2012-2015)`,
      end: ""
    }
  ],
  achievements: [
    ``
  ],
  phone: "+62819639681"
}, {
  id: 3,
  tier: 1,
  name: "Putri",
  experience: "3 years",
  specialisations: [CourseTypes.general],
  // imageUrl: PutriImg,
  // imageUrlPng: PutriImgPng,
  ages: [12, 23],
  location: "Medan",
  preferredLocations: [{
    lat: 3.5557198,
    lon: 98.654623
  }],
  schedule: {
    mon: [[15, 19]],
    tue: [[15, 19]],
    wed: [[15, 19]],
    fri: [[15, 19]]
  },
  qualifications: [
    {
      degree: `Perwakilan Indonesia untuk World Assembly of Youth and University Students 
                di Cheongpyeong, Korea Selatan (Aug 2015). Mahasiswa Semester Akhir Jurusan Sastra Inggris USU stambuk (2013)`,
      end: ""
    }
  ],
  achievements: [
    `Perwakilan Indonesia untuk Phillippines – 
    Indonesia Leadership & Cultural Exchange Program di Manila, Filipina (Jan-Feb 2015)`,
    `Perwakilan Indonesia untuk World Assembly of Youth and University Students 
    di Cheongpyeong, Korea Selatan (in Aug 2015)`,
    `Penerjemah untuk W-CARP 
    (World Collegiate Association for the Research of Principles)`
  ],
  phone: "+6281806484581"
},
  {
    "name": "Aditya",
    "preferredLocations": [{
      "lat": 3.5943504,
      "lon": 98.6272307
    }],
    phone: "+6285760689638"
  },
  {
    "name": "Arief",
    "preferredLocations": [{
      "lat": 3.5880005,
      "lon": 98.6269848
    }],
    phone: "+628112010994"
  },
  {
    "name": "Aguswando",
    "preferredLocations": [{
      "lat": 3.6056471,
      "lon": 98.6594153
    }],
    phone: "+628116551812"
  },
  {
    "name": "Arfandi",
    "preferredLocations": [{
      "lat": 3.5602612999999996,
      "lon": 98.6623927
    }],
    phone: "+6285762618911"
  },
  {
    "name":"Arianda",
    "preferredLocations": [{
      "lat": 3.5119968,
      "lon": 98.6331158
    }],
    phone: "+62819633614"
  },
  {
    "name":"Cun Cun",
    "preferredLocations": [{
      "lat": 3.5725269,
      "lon": 98.6841719
    }],
    phone: "+62819634080"
  },
  {
    "name":"Ilham",
    "preferredLocations": [{
      "lat": 3.5852134,
      "lon": 98.7451026
    }],
    phone: "+62819634080"
  },
  {
    "name":"Johnlong",
    "preferredLocations": [{
      "lat": 3.5932287,
      "lon": 98.7048934
    }],
    phone: "+6287868310408"
  },
  {
    "name":"Richard",
    "preferredLocations": [{
      "lat": 3.5926711,
      "lon": 98.6877837
    }],
    phone: "+6282277387094"
  },
  {
    "name":"Satya",
    "preferredLocations": [{
      "lat": 3.5869141,
      "lon": 98.7040372
    }],
    phone: "+6287818897902"
  },
  {
    "name":"Melly",
    "preferredLocations": [{
      "lat": 3.6129708,
      "lon": 98.7433567
    }],
    phone: "+6282277151612"
  },
  {
    "name":"Indri",
    "preferredLocations": [{
      "lat": 3.543899,
      "lon": 98.6248948
    }],
    phone: "+628116141659"
  },
  {
    "name":"Jetti",
    "preferredLocations": [{
      "lat": 3.5513628,
      "lon": 98.6605248
    }],
    phone: "+6285320480051"
  },
  {
    "name":"Lasna",
    "preferredLocations": [{
      "lat": 3.6020898,
      "lon": 98.7600861
    }],
    phone: "+6285370177993"
  },
  {
    "name":"Lisa Octavia",
    "preferredLocations": [{
      "lat": 3.6172121,
      "lon": 98.7155519
    }],
    phone: "+6281395604135"
  },
  {
    "name": "Sumiko",
    preferredLocations: [{
      "lat": 3.5543074,
      "lon": 98.6818284
    }, {
      "lat": 3.5766358,
      "lon": 98.6799448
    }],
    phone: "+6281262269477"
  },
  {
    "name": "Suci",
    preferredLocations: [{
      "lat": 3.5695892,
      "lon": 98.7215327
    }]
  },
  {
    "name": "Marina",
    preferredLocations: {}
  },
  {
    "name": "Lisa Chan",
    preferredLocations: {}
  },
  {
    "name": "Frederick",
    preferredLocations: {}
  }
];

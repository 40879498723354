import React, {useEffect, useState} from 'react';
import homeStyle from "../main/home.scss";
import {Menu} from "../menu/menu";
import {conversationApi} from "../api/conversationApi";
import {useLocation} from "react-router";
import * as moment from "moment-timezone";
import {Spinner} from "../controls/spinner";
import style from "./conversations.scss";
import {usersStore} from "../store/usersStore";
import {observer} from "mobx-react";

function Convos() {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedById, setExpandedById] = useState({});

  const {state} = useLocation();
  
  console.log(expandedById);
  
  useEffect(() => {
    loadConvos()
  }, []);
  
  async function loadConvos() {
    setLoading(true);
    setConversations(await conversationApi.getConversationsForScenario(state?.scenarioId));
    setLoading(false);
  }
  
  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={homeStyle.rightSidePanel}>
        <div className={homeStyle.list}>
          {loading && <Spinner/>}
          {!loading && conversations.map(c => <>
            <div key={c._id} className={style.conversation}>
              <div className={style.time}>{moment(c.creationTime).format("DD-MMM-YY HH:mm")}</div>
              <div>{usersStore.getUserById(c.userId)?.name}</div>
              <div onClick={() => expand(c._id)} className={style.expandBtn}>
                {expandedById[c._id] ? "hide" : "show"}
              </div>
              <div className={style.convoId}>{c._id}</div>
            </div>
            {expandedById[c._id] && c.transcript.split("\n").map(para => <p>{para}</p>)}
          </>)}
        </div>
      </div>
    </div>
  ); 
  
  function expand(convoId) {
    expandedById[convoId] = !expandedById[convoId];
    setExpandedById({...expandedById});
  }
}

export const Conversations = observer(Convos);
import React, {useEffect, useState} from "react";
import {Button} from "../controls/button";
import {conversationApi} from "../api/conversationApi";
import {logError} from "../errors/errorConsole";
import {Spinner} from "../controls/spinner";
import homeStyle from "../main/home.scss";
import {Menu} from "../menu/menu";
import {NeatFieldValue} from "../controls/neatFieldValue";
import {useForm} from "react-hook-form";
import {FieldArray} from "../controls/hookForm/fieldArray";

let characters = [];

export function ScenarioEditor(props) {
  const {scenarioId} = props.match.params;
  const [scenario, setScenario] = useState({});
  const [allCharacters, setAllCharacters] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const {register, handleSubmit, control, formState: {errors, isDirty}} = useForm({values: scenario});
  
  
  useEffect(() => {
    loadRemoteData();
  }, []);
  
  async function loadRemoteData() {
    characters = await conversationApi.getAllCharacters();
    setAllCharacters(characters);
    setScenario(await conversationApi.getScenario(scenarioId));
  }
  
  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={homeStyle.rightSidePanel}>
        <div style={{flexDirection: "row", display: "flex", alignItems: "flex-start"}}>
          <div style={{maxWidth: "900px", flex: 1}}>
            <form onSubmit={handleSubmit(save)}>
              <NeatFieldValue label={"Name"}>
                <input {...register('name', {required: true})}/>
              </NeatFieldValue>
              {errors.name && <p>Name is required.</p>}
              
              <NeatFieldValue label={"Label"}>
                <input {...register('label', {required: true})}/>
              </NeatFieldValue>
              {errors.label && <p>Label is required.</p>}
              
              <NeatFieldValue label={"Prologue to scenario"}>
                <FieldArray register={register} control={control} label="Cutscene" name="prologueCutscenes" innerComp={Cutscene}/>
              </NeatFieldValue>
              
              <NeatFieldValue label={"Main image"}>
                <input {...register('mainImage')}/>
              </NeatFieldValue>
              
              <NeatFieldValue label={"Prompt"}>
                <textarea
                    {...register('prompt', {required: true})}
                    style={{width: "100%", height: "600px"}}
                />
              </NeatFieldValue>
              
              <NeatFieldValue label={"Action goals"}>
                <textarea
                  {...register('goals', {required: true})}
                  style={{width: "100%", height: "200px"}}
                />
              </NeatFieldValue>
              
              <div style={{display: "flex", justifyContent: "flex-end"}}>
                {!isDirty && <div style={{color: "green"}}>&#x2713; Saved!</div>}
                {isDirty &&
                  <Button
                    disabled={isSubmitting}
                  >
                    {!isSubmitting && <input type="submit" value="Save"/>}
                    {isSubmitting && <Spinner/>}
                  </Button>
                }
              </div>
            </form>
          </div>
          {scenario.mainImage && <img src={scenario.mainImage} style={{maxHeight: "100vh", maxWidth: "40vw", "flex-shrink": 1}}/>}
        </div>
      </div>
    </div>
  );
  
  async function save(scenario) {
    setSubmitting(true);
    try {
      await conversationApi.updateScenario(scenarioId, scenario);
      setScenario(scenario);
    } catch (e) {
      logError("Failed to save scenario", e);
    }
    setSubmitting(false);
  }
  

}

function Cutscene({name, register, control}) {
  return (
    <div style={{width: "100%"}}>
      <NeatFieldValue label="Cutscene">
        <NeatFieldValue label="Image description">
          <textarea
            style={{width: "100%", height: "200px"}}
            {...register(`${name}.imageDescription`)}
            placeholder="Image description"
          />
        </NeatFieldValue>
        <FieldArray register={register} control={control} innerComp={Paragraph} label="Paragraph"
                    name={`${name}.paragraphs`}/>
      </NeatFieldValue>
    </div>
  );
}

function Paragraph({name, register}) {
  return (
  <div style={{width: "100%"}}>
    <NeatFieldValue label="Paragraph">
      <CharacterSelector name={`${name}.characterId`} register={register}/>
        <textarea
          style={{width: "100%", height: "200px"}}
          {...register(`${name}.text`, {required: true})}
          placeholder="Text"
        />
      </NeatFieldValue>
    </div>
  );
}

function CharacterSelector({name, register}) {
  return (
    <select
      {...register(`${name}`, {required: true})}
    >
      {characters.map(ch => <option value={ch._id}>{ch.name}</option>)}
    </select>
  );
}
import {observable} from "mobx";
import {userApi} from "../api/userApi";
import {debounce} from "lodash-decorators/debounce";
import {logError} from "../errors/errorConsole";

const maxBatchSize = 250;

class UsersStore {
  @observable usersById = {};
  @observable usersLoading = true;

  usersToRetrieve = [];

  @debounce(500, {trailing: true})
  async retrieveUsers() {
    this.usersLoading = true;
    this.usersToRetrieve = Array.from(new Set(this.usersToRetrieve));
    this.usersToRetrieve.filter(uId => !this.usersById[uId]);
    if (this.usersToRetrieve.length > 0) {
      const truncatedUsersToReceive = this.usersToRetrieve.slice(0, maxBatchSize);
      this.usersToRetrieve = this.usersToRetrieve.slice(maxBatchSize);
      const users = await userApi.searchUsersByIds(truncatedUsersToReceive);
      users.forEach(u => {
        this.usersById[u._id] = u;
      });
      this.usersToRetrieve = this.usersToRetrieve.filter(uId => !this.usersById[uId]);
      if (this.usersToRetrieve.length > 0)
        this.retrieveUsers();
    }
    this.usersLoading = false;
  }

  getUserById(userId) {
    if (!this.usersById[userId]) {
      this.usersToRetrieve.push(userId);
      this.retrieveUsers();
    }
    return this.usersById[userId];
  }
}

export const usersStore = new UsersStore();


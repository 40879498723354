import {api} from "./api";
import hello from "hellojs/dist/hello.all";
import {userSession} from "./userSession";

hello.init({
  facebook: "721350118210589"
}, {});

export const userApi = {
  login: async (creds) => {
    const cerahAuth = await api.userSvcRequest('POST', '/login', creds);
    userSession.userChanged(cerahAuth);
  },

  createUser: async (user) => {
    await api.userSvcRequest('POST', '/users', user);
  },

  createRefreshTokenForUser: async (invitation) => {
    return api.userSvcRequest('POST', '/studentAppInvites', invitation);
  },

  updateUser: async (user) => {
    await api.userSvcRequest('PUT', `/users/${user._id}`, user);
  },

  updatePermissions: async (userId, permissions) => {
    await api.userSvcRequest('PUT', `/permissions/${userId}`, permissions);
  },

  updateStudentPermissions: async (userId, permissions) => {
    await api.userSvcRequest('PUT', `/studentPermissions/${userId}`, permissions);
  },

  resetPwd: async (userId) => {
    await api.userSvcRequest('POST', `/pwdReset`, {userId});
  },

  facebookLogin: async () => {
    const {network, authResponse} = await hello('facebook').login({scope: "email"});
    const cerahAuth = await api.userSvcRequest('POST', `/login/facebook`, null, {
      Authorization: `Bearer ${authResponse.access_token}`
    });
    userSession.userChanged(cerahAuth);
  },

  searchUsersByNameSubstr: async (substr) => {
    return api.userSvcRequest('GET', `/users?contains=${encodeURIComponent(substr)}`);
  },

  searchUsersByIds: async (ids) => {
    if (ids.length === 0) {
      return [];
    }
    const commaSepIds = ids.splice(1).reduce((a, b) => a + ',' + b, ids[0]);
    return api.userSvcRequest('GET', `/users?ids=${commaSepIds}`);
  },

  getUser: async (id) => {
    return api.userSvcRequest('GET', `/users/${id}`);
  },

  getUserContactPerson: async (studentId) =>  {
    return (await api.userSvcRequest('GET', `/userContactPersons?studentId=${studentId}`))[0];
  },

  updateGoogleContactsApiToken: async (authCode) => {
    return api.userSvcRequest('POST', `/googleContactsApiAuthCode`, {authCode});
  }
};

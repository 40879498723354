// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JeHSoQso{height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:20px}.JeHSoQso .fG4fLuyW{background-color:#ffcccc;border:1px solid #ff2222;color:#ff2222;text-align:center;border-radius:5px;padding:20px;margin:20px;max-width:600px}.JeHSoQso .qBrqYI9j{width:100%;max-width:400px;margin:0 auto}.JeHSoQso form{display:flex;flex-direction:column;align-items:center}.JeHSoQso form input{border:none;border-bottom:1px solid;margin:10px;font-size:20px}\n", "",{"version":3,"sources":["webpack://./src/login/login.scss"],"names":[],"mappings":"AACA,UACE,YAAa,CACb,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CACvB,cAAe,CANjB,oBASI,wBAAyB,CACzB,wBAAyB,CACzB,aAAc,CACd,iBAAkB,CAClB,iBAAkB,CAClB,YAAa,CACb,WAAY,CACZ,eAAgB,CAhBpB,oBAoBI,UAAW,CACX,eAAgB,CAChB,aAAc,CAtBlB,eA0BI,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CA5BvB,qBA+BM,WAAY,CACZ,uBAAwB,CACxB,WAAY,CACZ,cAAe","sourcesContent":["\n.login {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n\n  .error {\n    background-color: #ffcccc;\n    border: 1px solid #ff2222;\n    color: #ff2222;\n    text-align: center;\n    border-radius: 5px;\n    padding: 20px;\n    margin: 20px;\n    max-width: 600px;\n  }\n\n  .loginBtn {\n    width: 100%;\n    max-width: 400px;\n    margin: 0 auto;\n  }\n\n  form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    input {\n      border: none;\n      border-bottom: 1px solid;\n      margin: 10px;\n      font-size: 20px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "JeHSoQso",
	"error": "fG4fLuyW",
	"loginBtn": "qBrqYI9j"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import {teacherApi} from "../api/teacherApi";
import history from "../history/history";
import {Formik, Form, Field} from "formik";
import {NeatFormField} from "../controls/neatFormField";
import style from './teacherEditor.scss';
import {notificationApi} from "../api/notificationApi";
import {Button} from "../controls/button";
import {logError} from "../errors/errorConsole";

export class TeacherNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.match.params.teacherId) {
      (async() => {
        this.setState({
          teacher: await teacherApi.getTeacher(props.match.params.teacherId)
        });
      })();
    } else {
      if (!props.location.state) {
        history.push('/teachers');
        return;
      }
      this.state = {
        teacher: props.location.state.teacher
      };
    }
    this.submit = this.submit.bind(this);
  }

  render() {
    const teacher = this.state.teacher;
    return (
      <div className={style.teacherEditor}>
        <div>Sending notification to teacher {teacher && teacher.name}</div>

        <img src={teacher && teacher.avatar} className={style.teacherImg}/>

        <Formik
          initialValues={{title: "", subtitle: ""}}
          onSubmit={this.submit}
        >
          {({submitForm}) => (
            <Form className={style.form}>
              <Field type="text" name="title">
                {({field}) =>
                  <NeatFormField {...field} label="Title"/>}
              </Field>
              <Field type="text" name="subtitle">
                {({field}) =>
                  <NeatFormField {...field} label="Content"/>}
              </Field>
              <Button type="submit" text="Send" onClick={submitForm}/>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  async submit(notif) {
    const teacherId = this.state.teacher._id;
    try {
      await notificationApi.sendNotificationToTeacher(teacherId, notif);
      history.push('/teachers');
    } catch (e) {
      logError(`failed to send notification to teacher ${teacherId}`, e);
    }
  }
}
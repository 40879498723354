import {api} from './api';

export const whatsappApi = {
  refreshQrCode: async () => {
    return api.whatsappSvcRequest('POST', `/qrCodes`);
  },

  getCurrentQrCode: async () => {
    return api.whatsappSvcRequest('GET', `/qrCodes/current`);
  },

  getScreenshot: async () =>  {
    return api.whatsappSvcRequest('GET', `/screenshots/current`);
  },
  
  getWaLinkClick: async (code) => {
    const waLinkClicks = await api.whatsappSvcRequest('GET', `/waLinkClicks?code=${code}`);
    return waLinkClicks[waLinkClicks.length - 1]; //return the latest click, hopefully
  }
};

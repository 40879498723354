import React, {useState} from 'react';
import style from './confirmationDialog.scss';
import {Button} from "./button";
import {Spinner} from "./spinner";

export function ConfirmationDialog({message, onReject, rejectText, onConfirm, confirmText, requireReason = false}) {
  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState(null);
  return (
    <div className={style.confirmationDialog}>
      <div className={style.message}>
        <div>{message}</div>
        {requireReason && <textarea type="text" placeholder="Explain why..." value={reason} onChange={(ev) => setReason(ev.target.value)}/>}
        <div className={style.buttonPanel}>
          <Button className={style.btnNo}
                  onClick={() => {
                    if (!submitting) {
                      setSubmitting(true);
                      onReject();
                    }
                  }}>
            {!submitting ? rejectText || "CANCEL" : <Spinner/>}
          </Button>
          <Button className={style.btnYes}
                  onClick={() => {
                    if (!submitting) {
                      setSubmitting(true);
                      onConfirm(reason);
                    }
                  }}>
            {!submitting ? confirmText || "CONFIRM" : <Spinner/>}
          </Button>
        </div>
      </div>
    </div>
  );
}

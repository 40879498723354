import React from 'react';
import {teacherApi} from "../api/teacherApi";
import history from "../history/history";
import {Formik, Form, Field} from "formik";
import {NeatFormField} from "../controls/neatFormField";
import style from './teacherEditor.scss';
import {ArrayField} from "../controls/arrayField";
import {CheckboxField} from "../controls/checkboxField";
import {UserField} from "../users/userField";
import {logError} from "../errors/errorConsole";
import {Button} from "../controls/button";
import {userSession} from "../api/userSession";
import {currencyLabels, supportedPreferredCurrencies} from "../config/currency";
import {payoutAccountTypeLabels} from "../config/accountTypes";
import { idChannelCodes } from '../config/xenditChannelCodes';

export const validSpecialisations = {
  'english.conversation': "Conversation",
  'english.general': "General English",
  'english.ielts': "IELTS",
  'english.toefl': "TOEFL",
  'english.business': "Business Eng"
};

export class TeacherEditor extends React.Component {
  constructor(props) {
    super(props);
    if (props.match.params.teacherId === 'new') {
      this.state = {
        teacher: {},
        paymentAccounts: []
      }
    } else {
      if (props.location.state) {
        this.state = {
          teacher: props.location.state.teacher,
          paymentAccounts: []
        };
      } else {
        this.state = {
          teacher: {},
          paymentAccounts: []
        };
        this.loadTeacherProfile();
      }
      this.loadPaymentDetails();
    }
    this.submit = this.submit.bind(this);
    this.submitPaymentAccounts = this.submitPaymentAccounts.bind(this);
    this.paymentAccountsValidation = this.paymentAccountsValidation.bind(this);
  }

  async loadTeacherProfile() {
    this.setState({
      teacher: await teacherApi.getTeacher(this.props.match.params.teacherId)
    })
  }

  async loadPaymentDetails() {
    if (userSession.getUserGroups().includes("ADMINS")) {
      this.setState({
        paymentAccounts: await teacherApi.getTeacherPaymentDetails(this.props.match.params.teacherId) || {}
      })
    }
  }

  render() {
    const teacher = this.state.teacher;
    const paymentAccounts = this.state.paymentAccounts;
  
    const ninjaIsAdmin = userSession.getUserGroups().includes("ADMINS");
  
    return (
      <div className={style.teacherEditor}>
        <div>{teacher._id ? "Edit teacher" : "Create new teacher"}</div>
        
        <div className={style.teacherInfo}>
          <img src={teacher.avatar} className={style.teacherImg}/>

          <Formik
            initialValues={teacher}
            onSubmit={this.submit}
            enableReinitialize={true}
          >
            <Form className={style.form}>
              <Field type="text" name="name">
                {({field}) =>
                <NeatFormField {...field} label="Display name"/>}
              </Field>
              <Field type="text" name="fullName">
                {({field}) =>
                <NeatFormField {...field} label="Full name"/>}
              </Field>
              <Field type="email" name="email">
                {({field}) =>
                  <NeatFormField {...field} label="Email"/>}
              </Field>
              <Field type="phone" name="phone">
                {({field}) =>
                  <NeatFormField {...field} label="Phone"/>}
              </Field>
              {ninjaIsAdmin && <Field type="text" name="taxId">
                {({field}) =>
                  <NeatFormField {...field} label="Tax ID"/>}
              </Field>}
              {ninjaIsAdmin && <ArrayField type="text" label="Address" name="address"/>}
              <Field type="text" name="skypeId">
                {({field}) =>
                  <NeatFormField {...field} label="Skype ID"/>}
              </Field>
              <Field type="text" name="avatar">
                {({field: {onChange, ...rest}}) =>
                  <NeatFormField
                    onChange={(e) => {this.setState({teacher: {...teacher, avatar: e.target.value}}); onChange && onChange(e)}}
                    {...rest}
                    label="Avatar"
                  />}
              </Field>
              <NeatFormField  name="preferredCurrency"
                              label="Preferred currency"
                              component="select">
                <option value={undefined} selected>--unset--</option>
                {renderCurrencyOptionsWise()}
              </NeatFormField>
              <ArrayField type="text" label="Education" name="education"/>
              <ArrayField type="text" label="Qualifications" name="qualifications"/>
              <ArrayField innerComp={TeacherExperience} label="Experience" name="experience"/>
              <div>
                <div>Specialisations</div>
                {Object.keys(validSpecialisations).map(k => (
                  <div className={style.specialisation}>
                    <CheckboxField key={k} name="specialisations" label={validSpecialisations[k]} value={k} />
                  </div>
                ))}
              </div>
              <button type="submit">Submit</button>
            </Form>
          </Formik>
        </div>
        {userSession.getUserGroups().includes("ADMINS") &&
          <Formik
            initialValues={paymentAccounts}
            onSubmit={this.submitPaymentAccounts}
            enableReinitialize={true}
            validate={this.paymentAccountsValidation}
          >
            {({errors, submitForm}) => (
              <Form>
                <ArrayField innerComp={PaymentDetails} label="Payment details" name="accounts"/>
                {Object.keys(errors).map(key => <div className={style.errorText}>{errors[key]}</div>)}
                <Button onClick={submitForm} text={"Update payment details"}/>
              </Form>
            )}
          </Formik>
        }
        {this.state.paymentAccountsError && <span style={{color: "red"}}>{this.state.paymentAccountsError}</span>}
      </div>
    );
  }

  async paymentAccountsValidation(paymentAccounts, props) {
    const errors = {};

    for (const account of paymentAccounts.accounts) {
      if (account.type === "xendit") {
        const validChannelCodes = idChannelCodes.map((cc) => cc.channelCode);
        if (!account.channelCode) {
          errors.channelCode = "Recipient bank is required"
        } else if (!validChannelCodes.includes(account.channelCode)) {
          errors.channelCode = "Invalid channel code for xendit";
        }
        if (!account.accountHolderName) {
          errors.accountHolderName = "Account holder name is required";
        }
        if (!account.accountNumber) {
          errors.accountNumber = "Bank account number is required";
        }
      }
      if (!account.currency) {
        errors.currency = "Currency is required";
      }
    }

    return errors;
  }

  async submit(teacher) {
    teacher.email && teacher.email.trim && teacher.email.trim();
    teacher.phone && teacher.phone.trim && teacher.phone.trim();
    teacher.name && teacher.name.trim && teacher.name.trim();
    teacher.skypeId && teacher.skypeId.trim && teacher.skypeId.trim();
    try {
      if (!teacher._id) {
        await teacherApi.createTeacher(teacher);
      } else {
        await teacherApi.updateTeacher(teacher);
      }
      history.push('/teachers');
    } catch (e) {
      logError("Failed to submit teacher information", e);
    }
  }

  async submitPaymentAccounts(paymentAccounts) {
    if (!this.state.teacher._id) return;

    paymentAccounts.accounts.forEach(pa => {
      if (!pa.type) pa.type = "wise";
    });


    try {
      if (paymentAccounts._id) {
        await teacherApi.updateTeacherPaymentDetails(paymentAccounts);
      } else {
        const createdPaymentAccounts = await teacherApi.createTeacherPaymentDetails({...paymentAccounts, teacherId: this.state.teacher._id});
        this.setState({
          paymentAccounts: createdPaymentAccounts
        });
      }
    } catch (e) {
      logError(`Failed to update payment account details for teacher ${this.state.teacher.name}`, e);
    }
  }
}

const TeacherExperience = ({
  field: {name, onChange}
}) => {
  return (
    <Form onChange={onChange} className={style.teacherExperience}>
      <Field type="text" name={`${name}.dates`} placeholder="dates"/>
      <Field type="text" name={`${name}.role`} placeholder="role"/>
      <Field type="text" name={`${name}.institution`} placeholder="institution"/>
    </Form>
  );
};

const PaymentDetails = ({
  field: {name, onChange, value}
}) => {
  return (
    <Form onChange={onChange} className={style.paymentDetails}>
      <Field component="select" name={`${name}.type`}>
        <option value="">Choose Type</option>
        {renderAccountTypeOptions()}
      </Field>
      <Field type="text" name={`${name}.alias`} placeholder="alias (optional)"/>
      {value.type === "wise" && <>
        <Field type="text" name={`${name}.wiseAccountId`} placeholder="Wise Account ID"/>
      </>}
      {value.type === "xendit" && <> 
        <Field type="text" name={`${name}.accountHolderName`} placeholder="Account holder's name"/>
        <Field type="text" name={`${name}.accountNumber`} placeholder="Bank account number"/>
        <Field component="select" name={`${name}.channelCode`}>
          <option value="">Choose recipient bank</option>
          {renderXenditRecipientBankOptions()}
        </Field>
      </>}
      <Field component="select" name={`${name}.currency`}>
        <option value="">Choose currency</option>,
        {value.type === "xendit" ? renderCurrencyOptionsXendit() : renderCurrencyOptionsWise()}
      </Field>
    </Form>
  );
};

function renderCurrencyOptionsXendit() {
  return <option value="IDR">Indonesian Rupiah</option>;
}

function renderCurrencyOptionsWise() {
  return supportedPreferredCurrencies.map(ccy =>
    <option value={ccy}>{currencyLabels[ccy]}</option>
  );
}

function renderXenditRecipientBankOptions() { 
  return idChannelCodes.map(({channelCode, bankName}) =>
    <option key={channelCode} value={channelCode}>{bankName.substring(0, 30)}</option>
  );
}

function renderAccountTypeOptions() {
  return Object.entries(payoutAccountTypeLabels).map(([code, label]) =>
    <option value={code}>{label}</option>
  );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KxMtgTz7{text-align:center}.RMTebNvj{position:relative;margin-top:10px;font-size:10px;border-collapse:collapse}.RMTebNvj td,.RMTebNvj th{border:1px solid black}.RMTebNvj .adPvxsc9{font-size:12px;background-color:#e6effc}.RMTebNvj .bjRKuag2{font-family:sans-serif;text-align:right}\n", "",{"version":3,"sources":["webpack://./src/payslips/unearnedRevenues.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAEA,UACE,iBAAkB,CACnB,UAGC,iBAAkB,CAClB,eAAgB,CAChB,cAAe,CAEf,wBAAyB,CAL3B,0BAOI,sBAAuB,CAP3B,oBAWI,cAAe,CACf,wBCfmB,CDGvB,oBAgBI,sBAAuB,CACvB,gBAAiB","sourcesContent":["@import \"../theme.scss\";\n\n.mainTitle {\n  text-align: center;\n}\n\n.unearnedRevTable {\n  position: relative;\n  margin-top: 10px;\n  font-size: 10px;\n\n  border-collapse: collapse;\n  td, th {\n    border: 1px solid black;\n  }\n  \n  .payer {\n    font-size: 12px;\n    background-color: $accent1-tint2;\n  }\n  \n  .numeric {\n    font-family: sans-serif;\n    text-align: right;\n  }\n}","$main-color1: #fee773;\n$main-color2: #ffa23e;\n\n$accent1-tint2: #e6effc;\n$accent1-tint1: #bedbff;\n$accent1: #a5c0e5;\n$accent1-shade1: #84a5d1;\n$accent1-shade2: #455d89;\n$accent1-shade3: #2d3037;\n\n$accent2-tint2: #c1d7f4;\n$accent2-tint1: #77a5e1;\n$accent2: #4783d0;\n$accent2-shade1: #1b65c3;\n$accent2-shade2: #09448f;\n\n$accent3-tint2: #f9fbff;\n$accent3-tint1: #9dc1f0;\n$accent3: #5c95de;\n$accent3-shade1: #286fcb;\n$accent3-shade2: #09448f;\n\n$accent4-tint2: #e0e0e0;\n$accent4-tint1: #d6d6d6;\n$accent4: #bfc8e1;\n$accent4-shade1: #7281ae;\n$accent4-shade2: #47568a;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainTitle": "KxMtgTz7",
	"unearnedRevTable": "RMTebNvj",
	"payer": "adPvxsc9",
	"numeric": "bjRKuag2"
};
export default ___CSS_LOADER_EXPORT___;

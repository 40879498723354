import React from 'react';
import {ArrayField} from "../controls/arrayField";
import {courseApi} from "../api/courseApi";
import {Formik, Form} from "formik";
import * as moment from "moment-timezone";
import {usersStore} from "../store/usersStore";
import {coursesStore} from "../store/coursesStore";
import {observer} from "mobx-react";
import {computed, observable} from 'mobx';
import formStyle from '../controls/neatFormField.scss';
import {Button} from "../controls/button";
import {logError} from "../errors/errorConsole";
import {NeatFormField} from "../controls/neatFormField";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import {DateTimeField} from "../controls/dateTimeField";

@observer
export class EnrolmentsEditor extends React.Component {
  constructor(props) {
    super(props);
    this.courseId = props.match.params.courseId;
    this.userId = props.match.params.userId;
    if (!this.userId || !this.courseId) {
      history.back();
      return;
    }
    this.state = {enrolments: {dates: []}};
    this.submit = this.submit.bind(this);
    this.loadEnrolments();
  }

  async loadEnrolments() {
    let enrolments;
    try {
       enrolments = await courseApi.getEnrolmentsByCourseIdUserId(this.courseId, this.userId);
    } catch (e) {
      if (e.statusCode === 404) {
      } else {
        logError(`Could not retrieve enrolments for user ${this.userId} and course ${this.courseId}`, e);
        return;
      }
    }
    if (!enrolments) {
      enrolments = {
        courseId: this.courseId,
        userId: this.userId,
        dates: ["2010-01-01T00:00:00.000Z"]
      };
    }
    this.setState({enrolments});
  }

  render() {
    const user = usersStore.getUserById(this.userId);
    const course = coursesStore.coursesById[this.courseId];

    const enrolments = this.state.enrolments;

    return (
      <Formik
        initialValues={enrolments}
        enableReinitialize
        onSubmit={this.submit}
      >
        {({submitForm}) => (
          <Form>
            <div className={formStyle.label}>
              Enrolment / drop out dates for user {user && user.name} in course {course && course.name}
            </div>
            <div className={formStyle.label}>
              Dates and times are in YOUR LOCAL TIMEZONE
            </div>
            <ArrayField innerComp={DateTimeField} label="Dates must be in ascending order, alternating (enrolment, drop out, enrolment, etc.)" name="dates"/>
            <NeatFormField name="wontContinue"
                           label="Customer has notified it won't continue"
                           component="select">
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </NeatFormField>
            <Button text="Submit" onClick={submitForm}/>
          </Form>
        )}
      </Formik>
    );
  }

  async submit(value) {
    try {
      await courseApi.updateEnrolments(value);
      coursesStore.reloadEnrolmentsOntoCourseById(this.courseId);
      history.back();
    } catch (e) {
      logError(`Failed to update enrolments to ${value}`, e);
    }
  }
}

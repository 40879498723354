import React, {useState, useEffect} from 'react';
import {courseApi} from "../api/courseApi";
import {Button} from "../controls/button";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import style from './coursePackages.scss';
import history from "../history/history";


export function CoursePackages({course, onPackagesChanged}) {
  const [pkgs, setPkgs] = useState([]);
  const [pkgIdToDelete, setPkgIdToDelete] = useState(null);

  async function loadPackages() {
    const pkgs = await courseApi.searchPackagesByCourseId(course._id);
    setPkgs(pkgs);
  }

  useEffect(() => {
    loadPackages();
  }, [course]);

  return (
    <div className={style.coursePackages}>
      <div>
        {pkgs.map(pkg =>
          <Package pkg={pkg}
                   onDelete={() => submitPkgDeletion(pkg._id)}
                   onEdit={() => editPackage(pkg)}/>
        )}
      </div>
      {course._id && <Button className={style.addPkgBtn} onClick={addPackage} text="add package" noArrow/>}
      {pkgIdToDelete &&
        <ConfirmationDialog
          message="You're about to delete a package that has already been purchased by some students. Are you sure??"
          onConfirm={() => deletePackage(pkgIdToDelete)}
          onReject={() => setPkgIdToDelete(null)}
        />}
    </div>
  );

  async function submitPkgDeletion(pkgId) {
    const purchases = await courseApi.searchPurchasesByPackageId(pkgId);
    if (purchases.length > 0) {
      setPkgIdToDelete(pkgId);
    } else {
      deletePackage(pkgId);
    }
  }

  async function deletePackage(pkgId) {
    await courseApi.deletePackage(pkgId);
    setPkgIdToDelete(null);
    loadPackages();
    onPackagesChanged && onPackagesChanged();
  }


  function addPackage() {
    history.push('/packages/new', {course})
  }

  function editPackage(pkg) {
    history.push(`/packages/${pkg._id}`, {course, pkg})
  }
}

function Package({pkg, onEdit, onDelete}) {
  return (<>
    <div className={style.package} onClick={onEdit}>
      {pkg.numHours} h at {pkg.currency} {pkg.price}
    </div>
    <span className={style.deletePackageButton}
          onClick={onDelete}>-</span>
  </>);
}

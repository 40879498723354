// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K7_OOWW7{display:inline}.K7_OOWW7 .qx0bF57n{display:inline;border:1px solid #888;width:auto;margin:5px;font-family:monospace;padding:4px;border-radius:4px}\n", "",{"version":3,"sources":["webpack://./src/courses/schedule.scss"],"names":[],"mappings":"AAAA,UACE,cAAe,CADjB,oBAII,cAAe,CACf,qBAAsB,CACtB,UAAW,CACX,UAAW,CACX,qBAAsB,CACtB,WAAY,CACZ,iBAAkB","sourcesContent":[".scheduleEntry {\n  display: inline;\n\n  .scheduleEntryPart {\n    display: inline;\n    border: 1px solid #888;\n    width: auto;\n    margin: 5px;\n    font-family: monospace;\n    padding: 4px;\n    border-radius: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scheduleEntry": "K7_OOWW7",
	"scheduleEntryPart": "qx0bF57n"
};
export default ___CSS_LOADER_EXPORT___;

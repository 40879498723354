import React, {useEffect, useState} from 'react';
import {ArrayField} from "../controls/arrayField";
import {configApi} from "../api/configApi";
import {Formik, Form, Field} from "formik";
import {logError} from "../errors/errorConsole";
import {Spinner} from "../controls/spinner";
import {Button} from "../controls/button";
import history from "../history/history";

export function ConfigEditor() {
  const [config, setConfig] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <div style={{padding: 10}}>
      {!config && <Spinner/>}
      {config && <Formik
        initialValues={config}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({submitForm}) => (
          <Form>
            <ArrayField name="teacherExcludedCountries"
                        label="Countries with teacher form disabled"
                        type="text"/>
            <div>
              <Button noArrow={isSubmitting}
                      onClick={submitForm}
                      disabled={isSubmitting}
                      text={!isSubmitting && "Submit"}>
                {isSubmitting && <Spinner/>}
              </Button>
            </div>
          </Form>
        )}
      </Formik>}
    </div>
  );

  async function loadConfig() {
    setConfig(await configApi.getWebappConfig());
  }

  async function submit(config) {
    setSubmitting(true);
    try {
      await configApi.updateWebappConfig(config);
      history.push('/courses');
    } catch (e) {
      logError("Failed to update config", e);
    }
    setSubmitting(false);
  }
}


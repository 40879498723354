import React from 'react';
import {Formik, Form, Field, FieldArray} from "formik";
import style from './relationField.scss'
import {UserField} from './userField';

export const RelationshipField = ({
  field: {name, value, onChange, onBlur}, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <Form onChange={onChange} className={style.relationFieldEntry}>
      <div className={style.relationContainer}>
        <Field component="select" name={`${name}.type`}>
          <option value="parentOf">Parent of</option>
          <option value="childOf">Child of</option>
        </Field>
          <Field component={UserField} name={`${name}.userId`}/>
      </div>
    </Form>
  )
};

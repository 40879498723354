import React, {useState} from 'react';
import style from './errorConsole.scss';

let addError;

function setHook(f) {
  addError = f;
}

export function ErrorConsole() {
  const [errors, setErrors] = useState([]);
  setHook(addError);
  return (
    <div className={style.errorConsole}>
      {errors.map((e, idx) =>
        <div key={idx} className={style.error}>
          {e}
          <div className={style.close} onClick={() => removeError(idx)}>&times;</div>
        </div>
      )}
    </div>
  );

  function addError(e) {
    setErrors([...errors, e]);
  }

  function removeError(idx) {
    const newErrors = [...errors];
    newErrors.splice(idx, 1);
    setErrors(newErrors);
  }
}

export async function logError(msg, e) {
  let errorMsg = "";
  try {
    const respPayload = await e.json();
    if (respPayload.message) {
      errorMsg += `${JSON.stringify(respPayload.message)} `;
    }
    if (respPayload.data) {
      errorMsg += JSON.stringify(respPayload.data);
    }
    if (errorMsg === "") {
      errorMsg = JSON.stringify(respPayload);
    }
  } catch (o) {
    console.error(o);
    errorMsg = e;
  }
  addError(`${msg} ${errorMsg}`);
  console.error(...arguments);
}

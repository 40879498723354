import React from 'react';
import {Menu} from "../menu/menu";
import * as moment from 'moment-timezone';
import style from './dashboard.scss';
import homeStyle from '../main/home.scss';
import {observer} from "mobx-react";
import {coursesStore} from "../store/coursesStore";
import {Spinner} from "../controls/spinner";
import {usersStore} from "../store/usersStore";
import Chart from "chart.js";
import {Button} from "../controls/button";
import {teachersStore} from "../store/teachersStore";
import {NpsRecent} from "../nps/npsRecent";
import {RecentLevelAttempts} from "../levelAttempts/recentLevelAttempts";
import {notificationApi} from "../api/notificationApi";

@observer
export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const st = coursesStore;
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.list}>
          <div>
            <div className={style.dashboard}>
              <Section title="Paid courses by week">
                {this.renderPaidCoursesByWeek()}
              </Section>
              <Section title="Trial courses by week">
                {this.renderTrialCoursesByWeek()}
              </Section>
              <Section title="Monthly revenues (M)">
                {this.renderRevenuesByWeek()}
              </Section>
              <Section title="CerahOnline revenues (M)">
                {this.renderCerahOnlineRevenuesByWeek()}
              </Section>
              <Section title="Revenues by attribution (k)">
                {this.renderRevenuesByTeacher()}
              </Section>
              <Section title="Total sales last year">
                {this.renderOnlineRevenues()}
              </Section>
              <Section title="Paid courses">
                <Counter count={st.paidCoursesByWeek[st.paidCoursesByWeek.length - 1]?.size}/>
              </Section>
              <Section title="Newly active / inactive">
                {this.renderNewlyActiveInactive()}
              </Section>
              <Section title="Newly started / ended">
                {this.renderNewAndEndedCourses()}
              </Section>
              <Section title="Active teachers">
                <Counter count={st.numActiveTeachersLastWeek}/>
              </Section>
              <Section title="Active students">
                <Counter count={st.numActivePayingStudentsLastWeek}/>
              </Section>
              <Section title="Freeloaders">
                {this.renderFreeloaderCourses()}
              </Section>
              <Section title="Recent sales">
                {this.renderRecentSales()}
              </Section>
              <Section title="NPS recent">
                <NpsRecent/>
              </Section>
              <Section title="Missing reports">
                {this.renderMissingReportCourses()}
              </Section>
              <Section title="Underscheduled / Underpaid">
                {this.renderMisscheduledCourses()}
              </Section>
              <Section title="Stalled courses">
                {this.renderStalledCourses()}
              </Section>
              <Section title="Recent placement tests">
                <RecentLevelAttempts/>
              </Section>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStalledCourses() {
    return coursesStore.stalledCourses.map(sc => (
      <div className={style.courseRow}>
        <div className={style.date}>{moment(sc.lastTaughtMeeting.startTime).format("DD-MMM-YY")}</div>
        <div className={style.userId}>{sc.name}</div>
        <div className={style.score}>{teachersStore.getTeacherById(sc.teacherId)?.name}</div>
      </div>
    ));
  }

  renderNewlyActiveInactive() {
    const oneWeekAgo = moment().subtract(7, 'day').subtract(6, 'hours').toISOString();
    const byName = (c1, c2) => c1.name > c2.name ? 1 : -1;
    const byNoMeetingsLeft = (c1, c2) => c1.plannedMeetings.length === 0  ? -1 : c2.plannedMeetings.length === 0 ? 1 : byName(c1, c2);
    const byWontContinue = (c1, c2) => c1.wontContinue ? -1 : c2.wontContinue ? 1 : byNoMeetingsLeft(c1, c2);
    const byNewlyStarted = (c1, c2) => c1.earliestScheduledMeeting.startTime > oneWeekAgo ? -1 : c2.earliestScheduledMeeting.startTime > oneWeekAgo ? 1 : byName(c1, c2);
    if (!coursesStore.paidCoursesByWeek[51]) return null;
    const last = [...coursesStore.paidCoursesByWeek[51]];
    const present = [...coursesStore.paidCoursesByWeek[50]];
    return (<table>
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th/>
        <th/>
      </tr>
      </thead>
      <tr style={{backgroundColor: "#ccc"}}>
        <td colspan="3"><strong>Newly inactive this week</strong></td>
      </tr>
      {_.difference(present, last)
        .map(cId => coursesStore.coursesById[cId])
        .sort(byWontContinue)
        .map(c => (<tr>
          <td style={{fontSize: 12}}>{c.name}</td>
          <td style={{fontSize: 10}}>{c.teacher.name}</td>
          <td>{c.wontContinue? <strong>ended</strong> : c.plannedMeetings.length === 0 ? <strong>NO_MTNGS</strong> : ""}</td>
        </tr>))
      }
      <tr style={{backgroundColor: "#ccc"}}>
        <td colspan="3"><strong>Newly active this week</strong></td>
      </tr>
      {_.difference(last, present)
        .map(cId => coursesStore.coursesById[cId])
        .sort(byName)
        .sort(byNewlyStarted)
        .map(c => (<tr>
          <td style={{fontSize: 12}}>{c.name}</td>
          <td style={{fontSize: 10}}>{c.teacher.name}</td>
          <td>{c.earliestScheduledMeeting.startTime > oneWeekAgo ? <strong>new</strong> : ""}</td>
        </tr>))
      }
    </table>);
  }

  renderNewAndEndedCourses() {
    const newCourses = coursesStore.newCoursesLastWeek;
    const endedCourses = coursesStore.endedCoursesLastWeek;
    return (<table>
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th/>
        </tr>
      </thead>
      <tr style={{backgroundColor: "#ccc"}}>
        <td><strong>Ended this week</strong></td>
        <td>{endedCourses.length}</td>
      </tr>
      {endedCourses.map(c => (<tr>
        <td style={{fontSize: 12}}>{c.name}</td>
        <td style={{fontSize: 10}}>{c.teacher.name}</td>
      </tr>))}
      <tr style={{backgroundColor: "#ccc"}}>
        <td><strong>Started this week</strong></td>
        <td>{newCourses.length}</td>
      </tr>
      {newCourses.map(c => (<tr>
        <td style={{fontSize: 12}}>{c.name}</td>
        <td style={{fontSize: 10}}>{c.teacher.name}</td>
      </tr>))}
    </table>);
  }

  renderMissingReportCourses() {
    return (
      <table className={style.missingReportCourses}>
        <tbody>
          {coursesStore.coursesWithBelatedReports.map(lc => ({
            teacherProfile: teachersStore.getTeacherById(lc.teacherId),
            course: lc
          })).sort((tc1, tc2) =>
            tc1.teacherProfile?.name > tc2.teacherProfile?.name ? 1 : -1
          ).map(tc => {
            const teacher = tc.teacherProfile;
            return (
              <tr>
                <td className={style.belatedReportDate}>{moment(tc.course.earliestPlannedMeeting.startTime).format("DD-MMM")}</td>
                <td className={style.belatedReportCourse}>{tc.course.name}</td>
                <td className={style.belatedReportTeacher}>{(teacher?.name) || <Spinner/>}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  }

  renderFreeloaderCourses() {
    const onlyFreeloaders = (c) => c.studentsOverdueRenewal.some(st => st.paidHours - st.attendedHours < 0 && st.attendedHours > 1);
    
    function urgentsFirst(c1, c2) {
      function mostUrgentStudentsFirst(st1, st2) {
        return st1.paidHours - st1.attendedHours - st2.paidHours + st2.attendedHours;
      }
      const st1 = c1.studentsOverdueRenewal.sort(mostUrgentStudentsFirst)[0];
      const st2 = c2.studentsOverdueRenewal.sort(mostUrgentStudentsFirst)[0];
      return (st1.paidHours - st1.attendedHours === 0 && st2.paidHours - st2.attendedHours === 0) ?
        st2.paidHours - st1.paidHours :
        (st1.paidHours - st1.attendedHours) - (st2.paidHours - st2.attendedHours);
    }

    return (<table>
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">attended</th>
          <th scope="col">paid</th>
        </tr>
      </thead>
      {coursesStore.overdueRenewalCourses.filter(onlyFreeloaders).sort(urgentsFirst).map(c =>
        [
          <tr style={{backgroundColor: "#ccc"}}>
            <td><strong>{c.name}</strong></td><td/><td/>
          </tr>,
          ...c.studentsOverdueRenewal.map(udr => {
            const user = usersStore.getUserById(udr.studentId);
            return <tr style={udr.attendedHours >= udr.paidHours ? {color: 'red'} : {}}>
              <td>{(user && user.name) || <Spinner/>}</td>
              <td>{Math.round(udr.attendedHours * 100) / 100}</td>
              <td>{Math.round(udr.paidHours * 100) / 100}</td>
            </tr>
          })
        ]
      )}
    </table>);
  }


  renderRecentSales() {
    return (<table>
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th/>
        <th scope="col">Paid</th>
      </tr>
      </thead>
      {coursesStore.activeCourses
        .filter(c => c.usersWhoPaidRecently.length > 0)
        .sort((c1, c2) => c2.latestPaidPurchase?.creationTime - c1.latestPaidPurchase?.creationTime)
        .map(c => {
          return (<>
            <tr style={{backgroundColor: "#ccc"}}>
              <td><strong>{c.name}</strong></td>
              <td/>
              <td/>
            </tr>
            {c.usersWhoPaidRecently.map(uId => {
              const user = usersStore.getUserById(uId);
              const lastSale = c.latestPaidPurchasePerUserId[uId];
              return (
                <tr>
                  <td>{(user && user.name) || <Spinner/>}</td>
                  <td style={{fontSize: "smaller"}}>{moment(lastSale.creationTime).format("DD/MMM")}</td>
                  <td>{Math.round(lastSale.numHours * 100) / 100}</td>
                </tr>
              )
            })}
          </>);
        })
      }
    </table>);
  }

  renderMisscheduledCourses() {
    window.store = coursesStore;
    return (<table>
      <thead>
        <tr>
          <th scope="col">Course</th>
          <th scope="col">scheduled</th>
          <th scope="col">paid</th>
        </tr>
      </thead>
      {coursesStore.misscheduledCourses.map(c =>
        [
          <tr style={{backgroundColor: "#ccc"}}>
            <td><strong>{c.name}</strong></td><td></td><td></td>
          </tr>,
          ...c.misscheduledStudents.map(stId => {
            const student = usersStore.getUserById(stId);
            const schedHrs = c.scheduledHoursPerStudent[stId];
            const paidHrs = c.paidHoursPerStudent[stId];
            const underpaid = schedHrs > paidHrs;
            return (
              <tr>
                <td>{(student && student.name) || <Spinner/>}</td>
                <td style={underpaid ? null : {color: "green"}}>{Math.round(schedHrs *100) / 100}h</td>
                <td style={underpaid ? {color: "red"} : null}>{Math.round(paidHrs * 100) / 100}h</td>
              </tr>
            );
          })
        ]
      )}
    </table>);
  }

  renderPaidCoursesByWeek() {
    const numCourses = coursesStore.paidCoursesByWeek.map(set => set.size);
    return (<WeeklyGraph data={numCourses}/>);
  }

  renderTrialCoursesByWeek() {
    const numCourses = coursesStore.activeCoursesByWeek.map(set => set.size).map((act, wk) => act - coursesStore.paidCoursesByWeek[wk].size);
    return (<WeeklyGraph data={numCourses}/>);
  }

  renderRevenuesByWeek() {
    const monthlyRevenuesByWeek = coursesStore.monthlyRevenuesByWeek;
    return (<WeeklyGraph data={monthlyRevenuesByWeek}/>);
  }

  renderCerahOnlineRevenuesByWeek() {
    const onlineRevenues = coursesStore.cerahOnlineRevenuesByWeek;
    return (<WeeklyGraph data={onlineRevenues}/>);
  }

  renderOnlineRevenues() {
    const newCourses = coursesStore.allCourses;
    const revenues = [
      {
        name: "Online private",
        filter: c => c.type === "onlinePrivate"
      },
      {
        name: "Online group",
        filter: c => c.type === "onlineGroup"
      },
      {
        name: "Location 'online'",
        filter: c => c.isOnline
      },
      {
        name: "CerahPrivat",
        filter: c => c.type === "privat"
      },
      {
        name: "CerahBareng",
        filter: c => c.type === "bareng"
      },
      {
        name: "all sales",
        filter: c => true
      },
    ];
    revenues.forEach(concept => {
      concept.revenue = Math.round(newCourses.filter(concept.filter).map(c => c.lastYearRevenueIDR).reduce((acc, v) => acc + v, 0));
    });
    return (<table>
      <thead>
      <tr>
        <th scope="col">Product</th>
        <th scope="col">amount</th>
      </tr>
      </thead>
      {revenues.map(concept => <tr>
        <td>{concept.name}</td><td className={style.number}>{concept.revenue.toLocaleString()}</td>
      </tr>)}
    </table>)
  }

  renderRevenuesByTeacher() {
    const {monthlyRevenuesByTeacherAndWeek: rev, hoursByTeacherAndWeek, coursesByTeacherAndWeek} =
      coursesStore.attributionByTeacherAndWeek;
    return (<table>
      <thead>
      <tr>
        <th scope="col">teacher</th>
        <th scope="col">hours</th>
        <th scope="col">crs</th>
        <th scope="col">revenues</th>
      </tr>
      </thead>
      {Object.keys(rev).map(tId => [
        tId,
        Math.round(500 * (rev[tId][51] + rev[tId][50])),
        Math.round((hoursByTeacherAndWeek[tId][51] + hoursByTeacherAndWeek[tId][50]) / 2 * 10) / 10,
        (new Set([...coursesByTeacherAndWeek[tId][51], ...coursesByTeacherAndWeek[tId][50]]).size)
      ])
        .filter(([tId, r]) => r > 0)
        .sort(([tId1, r1], [tId2, r2]) => r2 - r1)
        .map(([tId, r, h, c]) => {
          const teacher = teachersStore.getTeacherById(tId);
          return (<tr>
            <td>{teacher && teacher.name || tId}</td>
            <td className={style.number}>{h}</td>
            <td className={style.number}>{c}</td>
            <td className={style.number}>{r}</td>
          </tr>);
        })
      }
    </table>)
  }
}

@observer
class Section extends React.Component {
  render() {
    return (
      <div className={style.section}>
        <div className={style.sectionTitle}>{this.props.title}</div>
        {coursesStore.coursesLoading ? <Spinner/> :
          this.props.children
        }
      </div>
    );
  }
}

function Counter(props) {
  return (
    <div className={style.counter}>
      <div className={style.figure}>{props.count}</div>
    </div>
  );
}

class WeeklyGraph extends React.Component {
  constructor(props) {
    super(props);
    this.labels = new Array(52);
    for (let i = 0; i < 52; i++) {
      this.labels[i] = moment().subtract(51 - i, 'weeks').format("DD-MMM-YY");
    }
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.chart = new Chart(this.canvasRef.current, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [{
          data: this.props.data,
          backgroundColor: "#e6effc",
          borderColor: "#455d89"
        }]
      },
      options: {
        legend: {display: false},
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  componentDidUpdate() {
    this.chart.update();
  }

  render() {
    return (
      <div>
        <div style={{height: "30px"}}/>
        <canvas ref={this.canvasRef} width="300" height="250"/>
      </div>
    );
  }
}

import {courseApi} from "../api/courseApi";
import {observable, computed} from "mobx";
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import {usersStore} from "./usersStore";

const nowDelayHrs = 6;

class CoursesStore {
  @observable allCourses = [];
  @observable coursesLoading = true;
  @observable coursesById = {};
  @observable.shallow allMeetings = [];

  constructor() {
    this.retrieveCourses();
  }

  async retrieveCourses() {
    this.coursesLoading = true;
    const courses = await courseApi.getAllCourses();
    const coursesById = {};
    courses.forEach(c => {
      c.enrolmentDates = {}; c.meetings = []; c.purchases = {}; c.purchasesByStudentId = {};
      coursesById[c._id] = c;
    });
    await Promise.all([
      this.loadEnrolmentsOntoCourses(coursesById),
      // this.loadMeetingsOntoCourses(coursesById),
      this.loadPurchasesOntoCourses(coursesById)
    ]);
    setTimeout(() => {
      const enrichedCourses = courses.map(c => enrich(c));
      this.allCourses = enrichedCourses;
      enrichedCourses.forEach(c => this.coursesById[c._id] = c);
      this.coursesLoading = false;
    });
  }

  async reloadCourse(courseId) {
    this.coursesLoading = true;
    const c = await courseApi.getCourse(courseId);
    c.enrolmentDates = {}; c.meetings = []; c.purchases = {}; c.purchasesByStudentId = {};
    await Promise.all([
      this.reloadEnrolmentsOntoCourse(c),
      this.reloadMeetingsOntoCourse(c),
      this.reloadPurchasesOntoCourse(c)
    ]);
    setTimeout(() => {
      const enrichedCourse = enrich(c);
      for (let i = 0; i < this.allCourses.length; i++) {
        if (this.allCourses[i]._id === c._id) {
          this.allCourses[i] = enrichedCourse;
          break;
        }
      }
      this.coursesById[c._id] = enrichedCourse;
      this.coursesLoading = false;
    });
  }

  async loadMeetingsOntoCourses(coursesById) {
    this.allMeetings = await courseApi.getAllMeetings();
    this.allMeetings.forEach(m => {
      const c = coursesById[m.courseId];
      if (c) c.meetings.push(m);
    });
  }

  async reloadMeetingsOntoCourse(course) {
    course.meetings = await courseApi.searchMeetingsByCourseId(course._id);
  }

  async loadEnrolmentsOntoCourses(coursesById) {
    const enrolments = await courseApi.getAllEnrolments();
    enrolments.forEach(e => {
      const c = coursesById[e.courseId];
      if (c) {
        c.enrolmentDates[e.userId] = e.dates.map(d => moment.tz(d, c.timezone));
      }
    });
  }

  async reloadEnrolmentsOntoCourseById(courseId) {
    const c = this.coursesById[courseId];
    if (c) {
      await this.reloadEnrolmentsOntoCourse(c)
    }
  }

  async reloadEnrolmentsOntoCourse(c) {
    const enrolments = await courseApi.getEnrolmentsByCourseId(c._id);
    enrolments.forEach(e => {
      c.enrolmentDates[e.userId] = e.dates.map(d => moment.tz(d, c.timezone));
    });
  }

  async loadPurchasesOntoCourses(coursesById) {
    // const purchases = await courseApi.searchPurchasesByCourseIds(Object.keys(coursesById));
    const purchases = await courseApi.getAllPurchases();

    purchases.forEach(prch => {
      const c = coursesById[prch.courseId];
      c.purchases[prch.userId] = c.purchases[prch.userId] || [];
      c.purchases[prch.userId].push(prch);
      prch.studentIds.forEach(st => {
        c.purchasesByStudentId[st] = c.purchasesByStudentId[st] || [];
        c.purchasesByStudentId[st].push(prch);
      });
    });
  }

  async reloadPurchasesOntoCourseById(courseId) {
    const c = this.coursesById[courseId];
    if (!c) return;
  }

  async reloadPurchasesOntoCourse(c) {
    const purchases = await courseApi.getPurchasesForCourse(c._id);

    purchases.forEach(prch => {
      c.purchases[prch.userId] = c.purchases[prch.userId] || [];
      c.purchases[prch.userId].push(prch);
      prch.studentIds.forEach(st => {
        c.purchasesByStudentId[st] = c.purchasesByStudentId[st] || [];
        c.purchasesByStudentId[st].push(prch);
      });
    });
  }

  @computed get activeCourses() {
    return this.allCourses.filter(c =>
      !c.wontContinue || c.plannedMeetings.length > 0
    );
  }

  @computed get stalledCourses() {
    const threeWksAgo = moment().subtract(3, 'weeks');
    return this.activeCourses.filter(c => {
      return moment(c.lastTaughtMeeting.startTime).isBefore(threeWksAgo);
    }).sort((sc1, sc2) => moment(sc2.lastTaughtMeeting.startTime).isBefore(moment(sc1.lastTaughtMeeting.startTime)));
  }

  @computed get misscheduledCourses() {
    return this.activeCourses.filter(c => c.isOverscheduled || c.isUnderscheduled);
  }

  @computed get dueRenewalCourses() {
    return this.activeCourses.filter(c => {
      return c.studentsDueRenewal.length > 0;
    });
  }

  @computed get overdueRenewalCourses() {
    return this.activeCourses.filter(c => {
      return c.studentsOverdueRenewal.length > 0;
    });
  }

  @computed get coursesWithBelatedReports() {
    const twentyFourHrsAgo = moment().subtract(24, 'hours');
    return this.activeCourses.filter(c => {
      const epm = c.earliestPlannedMeeting;
      return epm && moment(epm.startTime).isBefore(twentyFourHrsAgo);
    }).sort((c1, c2) =>
      moment(c1.earliestPlannedMeeting.startTime).isAfter(
        moment(c2.earliestPlannedMeeting.startTime)) ? 1 : -1
    );
  }

  @computed get activeCoursesByTeacherId() {
    const coursesByTeacherId = {};
    this.activeCourses.forEach(c => {
      if (!coursesByTeacherId[c.teacherId]) {
        coursesByTeacherId[c.teacherId] = [];
      }
      coursesByTeacherId[c.teacherId].push(c);
    });
    return coursesByTeacherId;
  }

  @computed get activeCoursesByWeek() {
    const now = moment().subtract(nowDelayHrs, "hours");
    const activeCoursesByWeek = new Array(52);
    for (let i = 0; i < 52; i++) {activeCoursesByWeek[i] = (new Set());}
    this.allMeetings.forEach(m => {
      if (m.state === "TAUGHT" && moment(m.startTime).isBefore(now)) { //the difference is 0 if moments they lie on the next week or the previous one. We have to leave out future meetings explicitly
        const diffWks = now.diff(moment(m.startTime), 'weeks');
        if (diffWks >= 0 && diffWks <= 51 && !m.report.attended.includes("5cab079e61d19a59173f7b3f")) {
          activeCoursesByWeek[(51 - diffWks)].add(m.courseId);
        }
      }
    });
    return activeCoursesByWeek;
  }

  @computed get paidCoursesByWeek() {
    const paidCoursesByWeek = new Array(52).fill(0);
    if (this.coursesLoading)
      return paidCoursesByWeek;

    this.activeCoursesByWeek.forEach(
      (courseIds, wk) => paidCoursesByWeek[wk] = new Set([...courseIds].filter(cId => this.coursesById[cId].totalRevenueIDR > 10))
    );
    return paidCoursesByWeek;
  }

  @computed get newCoursesLastWeek() {
    if (!this.paidCoursesByWeek[51]) return [];
    const oneWeekAgo = moment().subtract(7, 'day').subtract(nowDelayHrs, 'hours').toISOString();
    return [...this.paidCoursesByWeek[51]].map(cId => this.coursesById[cId]).filter(c => c.earliestScheduledMeeting.startTime > oneWeekAgo);
  }

  @computed get endedCoursesLastWeek() {
    const oneWeekAgo = moment().subtract(7, 'day').subtract(nowDelayHrs, 'hours').toISOString();
    return this.allCourses.filter(c =>
      c.wontContinue && (
        c.lastTaughtMeeting.startTime > oneWeekAgo ||
        c.terminationNotificationDate > oneWeekAgo
      ) &&
      c.totalRevenueIDR > 0
    );
  }

  @computed get numActiveTeachersLastWeek() {
    const {coursesByTeacherAndWeek} = coursesStore.attributionByTeacherAndWeek;
    return Object.values(coursesByTeacherAndWeek).reduce((acc, courses) => acc + (courses[courses.length - 1].size > 0 ? 1 : 0), 0)
  }

  @computed get numActivePayingStudentsLastWeek() {
    const nowIso = moment().subtract(nowDelayHrs, 'hours').toISOString();
    const aWeekAgoIso = moment().subtract(1, "week").subtract(nowDelayHrs, 'hours').toISOString();
    return new Set(coursesStore.activeCourses.map(c =>
      Object.keys(c.purchasesByStudentId).filter(stId =>
        c.scheduledMeetingsPerStudent[stId].filter(m => m.state === "TAUGHT" && m.startTime > aWeekAgoIso && m.startTime < nowIso).length > 0
      )
    ).flat()).size;
  }

  @computed get monthlyRevenuesByWeek() {
    if (this.coursesLoading)
      return new Array(52).fill(0);
    const {monthlyRevenuesByTeacherAndWeek} = this.attributionByTeacherAndWeek;
    return new Array(52).fill(0).map((dum, wk) =>
      Object.keys(monthlyRevenuesByTeacherAndWeek).map(
        tId => monthlyRevenuesByTeacherAndWeek[tId][wk]
      ).reduce((acc, v) => acc + v, 0)
    );
  }

  @computed get numMeetingsPerQuarterHourLast20Wks() {
    const now = moment().subtract(nowDelayHrs, "hours");
    const nowISO = now.toISOString();
    const aYearAgoISO = moment(now).subtract(20, 'weeks').toISOString();
    const numMs = new Array(96).fill(0).map(x => new Array(7).fill(0));
    this.allMeetings.filter(m => m.startTime > aYearAgoISO && m.startTime < nowISO && m.state === "TAUGHT").forEach(m => {
      let {i, j} = idxsForTime(m.startTime);
      let quarters = Math.round(m.durationMins / 15);
      while (quarters-- > 0) {
        numMs[i][j] += 1;
        i++;
        if (i > 95) {
          i = i % 96;
          j = (j + 1) % 7;
        }
      }
    });
    return numMs;

    function idxsForTime(time) {
      const mmt = moment(time).tz("Asia/Jakarta");
      return {i: Math.round(mmt.hours() * 4 + mmt.minutes() / 15) % 96, j: mmt.isoWeekday() - 1};
    }
  }

  @computed get attributionByTeacherAndWeek() {
    const hourlyToMinutelyMonthlifiedInMln = 1 / 60 / 7 * 30 / 1000000;
    if (this.coursesLoading) return {
      monthlyRevenuesByTeacherAndWeek: {},
      hoursByTeacherAndWeek: {},
      coursesByTeacherAndWeek: {}
    };
    const now = moment().subtract(nowDelayHrs, "hours");
    const nowISO = now.toISOString();
    const aYearAgoISO = moment(now).subtract(52, 'weeks').toISOString();
    const monthlyRevenuesByTeacherAndWeek = {};
    const hoursByTeacherAndWeek = {};
    const coursesByTeacherAndWeek = {};
    this.allMeetings.filter(m => m.startTime > aYearAgoISO && m.startTime < nowISO && m.state === "TAUGHT").forEach(m => {
      const c = this.coursesById[m.courseId];
      if (c.averageRevenuePerHourIDR > 0) {
        const diffWks = now.diff(moment(m.startTime), 'weeks');
        monthlyRevenuesByTeacherAndWeek[m.teacherId] = monthlyRevenuesByTeacherAndWeek[m.teacherId] || new Array(52).fill(0);
        monthlyRevenuesByTeacherAndWeek[m.teacherId][(51 - diffWks)] += c.averageRevenuePerHourIDR * m.durationMins * hourlyToMinutelyMonthlifiedInMln;
        hoursByTeacherAndWeek[m.teacherId] = hoursByTeacherAndWeek[m.teacherId] || new Array(52).fill(0);
        hoursByTeacherAndWeek[m.teacherId][(51 - diffWks)] += m.durationMins / 60;
        coursesByTeacherAndWeek[m.teacherId] = coursesByTeacherAndWeek[m.teacherId] || new Array(52).fill(0).map(e => new Set());
        coursesByTeacherAndWeek[m.teacherId][(51 - diffWks)].add(c);
      }
    });
    return {monthlyRevenuesByTeacherAndWeek, hoursByTeacherAndWeek, coursesByTeacherAndWeek};
  }

  @computed get cerahOnlineRevenuesByWeek() {
    if (this.coursesLoading) return [];
    const nov1st = moment("2019-11-01");
    const now = moment().subtract(nowDelayHrs, "hours");
    const nowISO = now.toISOString();
    const aYearAgoISO = moment(now).subtract(52, 'weeks').toISOString();
    const revenuesByWeek = new Array(52).fill(0);
    this.allMeetings.filter(m => m.startTime > aYearAgoISO && m.startTime < nowISO && m.state === "TAUGHT").forEach(m => {
      const c = this.coursesById[m.courseId];
      if (c.isOnlineType && moment(c.startDate).isAfter(nov1st)) {
        const diffWks = now.diff(moment(m.startTime), 'weeks');
        revenuesByWeek[(51 - diffWks)] += c.averageRevenuePerHourIDR * m.durationMins / 60;
      }
    });
    return revenuesByWeek.map(r => r / 7 * 30 / 1000000);
  }

  @computed get allCoursesBySearchString() {
    return this.getCoursesIndexedBySearchString(this.allCourses);
  }

  @computed get activeCoursesBySearchString() {
    return this.getCoursesIndexedBySearchString(this.activeCourses);
  }

  getCoursesIndexedBySearchString(courses) {
    let coursesBySearchStringKeys = [];
    let coursesBySearchStringCourseIds = [];
    courses.forEach(c => {
      const studentIds = Object.keys(c.purchasesByStudentId);
      const students = studentIds.map(stId => usersStore.getUserById(stId));
      const teacher = c.teacher;
      const keys = [teacher && teacher.name.toLowerCase(), c.name.toLowerCase(), ...students.map(s => s && s.name?.toLowerCase() || ""), ...students.map(s => s && s.phone || "")];
      coursesBySearchStringKeys = [...coursesBySearchStringKeys, ...keys];
      coursesBySearchStringCourseIds = [...coursesBySearchStringCourseIds, ...new Array(keys.length).fill(c._id)]
    });
    return [coursesBySearchStringKeys, coursesBySearchStringCourseIds];
  }
}

function enrich(course) {
  const eCourse = {
    @observable.shallow meetings: [],
    @observable purchases: {},
    @observable purchasesByStudentId: {},
    @observable enrolmentDates: {},

    @computed get isOnlineType() {
      return ["onlinePrivate", "onlineGroup"].includes(eCourse.type);
    },

    @computed get latestPaidPurchasePerUserId() {
      const ps = eCourse.purchases;
      const latestPaidPurchasePerUserId = {};
      Object.keys(ps).forEach(uId => {
        let latestSaleTime = 0;
        ps[uId].forEach(p => {
          if (p.price > 0 && p.creationTime > latestSaleTime && !p.notes) {
            latestSaleTime = p.creationTime;
            latestPaidPurchasePerUserId[uId] = p;
          }
        });
      });
      return latestPaidPurchasePerUserId;
    },

    @computed get latestPaidPurchase() {
      const latestSales = eCourse.latestPaidPurchasePerUserId;
      let latestCoursePaidPrch = null;
      Object.keys(latestSales).forEach(uId => {
        const latestUserPaidPrch = latestSales[uId];
        latestCoursePaidPrch = (latestCoursePaidPrch || latestUserPaidPrch);
        if (latestUserPaidPrch.creationTime > latestCoursePaidPrch.creationTime) {
          latestCoursePaidPrch = latestUserPaidPrch;
        }
      });
      return latestCoursePaidPrch;
    },

    @computed get taughtMeetings() {
      return eCourse.meetings.filter(m => m.state === 'TAUGHT');
    },

    @computed get lastTaughtMeeting() {
      const tms = eCourse.taughtMeetings;
      return tms.length && tms[tms.length - 1];
    },

    @computed get plannedMeetings() {
      return eCourse.meetings.filter(m => m.state === "PLANNED");
    },

    @computed get earliestPlannedMeeting() {
      const pms = eCourse.plannedMeetings.sort(
        (m1, m2) => moment(m1.startTime).isAfter(moment(m2.startTime)) ? 1 : -1
      );
      return pms[0];
    },

    @computed get scheduledMeetings() {
      return [...eCourse.plannedMeetings, ...eCourse.taughtMeetings];
    },

    @computed get earliestScheduledMeeting() {
      const sortedScheduledMeetings = eCourse.scheduledMeetings.sort(
        (m1, m2) => moment(m1.startTime).isAfter(moment(m2.startTime)) ? 1 : -1
      );
      return sortedScheduledMeetings[0];
    },

    @computed get scheduledHours() {
      return eCourse.scheduledMeetings.reduce((acc, m) => m.durationMins + acc, 0) / 60;
    },

    @computed get paidHoursPerStudent() {
      const paidHoursPerStudent = {};
      const studentIds = Object.keys(eCourse.purchasesByStudentId);
      studentIds.forEach(stId => {
        const purchases = eCourse.purchasesByStudentId[stId];
        paidHoursPerStudent[stId] = purchases.reduce((acc, p) => p.numHours + acc, 0);
      });
      return paidHoursPerStudent;
    },

    @computed get amountPaidPerStudent() {
      const paidHoursPerStudent = {};
      const studentIds = Object.keys(eCourse.purchasesByStudentId);
      studentIds.forEach(stId => {
        const purchases = eCourse.purchasesByStudentId[stId];
        paidHoursPerStudent[stId] = purchases.reduce((acc, p) => p.price + acc, 0);
      });
      return paidHoursPerStudent;
    },

    @computed get totalRevenueIDR() {
      let totalRevenue = 0;
      const userIds = Object.keys(eCourse.purchases);
      userIds.forEach(userId => {
        const purchases = eCourse.purchases[userId];
        totalRevenue += purchases.reduce(toIdrTotal, 0);
      });
      return totalRevenue;
    },

    @computed get lastYearRevenueIDR() {
      let lastYearRevenue = 0;
      const now = moment().subtract(nowDelayHrs, "hours");
      const nowMillis = now.valueOf();
      const oneYearAgoMillis = now.subtract(1, "year").valueOf();
      const userIds = Object.keys(eCourse.purchases);
      userIds.forEach(userId => {
        const purchases = eCourse.purchases[userId].filter(p => (p.creationTime < nowMillis && p.creationTime > oneYearAgoMillis));
        lastYearRevenue += purchases.reduce(toIdrTotal, 0);
      });
      return lastYearRevenue;
    },

    @computed get averageRevenuePerHourIDR() {
      return this.totalRevenueIDR / this.scheduledHours;
    },

    @computed get paidEnrolleeIdsByMeetingId() {
      const paidEnrolleeIdsByMeetingId = {};
      Object.entries(eCourse.scheduledMeetingsPerStudent).forEach(([studentId, mArray]) => {
        mArray.forEach(m => {
          paidEnrolleeIdsByMeetingId[m._id] = (paidEnrolleeIdsByMeetingId[m._id] || []);
          if (eCourse.amountPaidPerStudent[studentId] > 0) {
            paidEnrolleeIdsByMeetingId[m._id].push(studentId);
          }
        });
      });
      return paidEnrolleeIdsByMeetingId;
    },

    @computed get plannedMeetingsAttendeeIds() {
      const studentIds = eCourse.activeStudentIds;
      const remainingHoursPerStudent = {};
      studentIds.forEach(stId => {
        remainingHoursPerStudent[stId] = eCourse.paidHoursPerStudent[stId] - eCourse.attendedHoursPerStudent[stId];
      });

      const plannedMeetingsAttendeeIds = {};
      eCourse.plannedMeetings.forEach(m => {
        plannedMeetingsAttendeeIds[m.startTime] = [];
        studentIds.forEach(stId => {
          if (isEnrolled(stId, m.startTime) && remainingHoursPerStudent[stId] > 0) {
            remainingHoursPerStudent[stId] -= m.durationMins / 60;
            if (remainingHoursPerStudent[stId] >= 0)
              plannedMeetingsAttendeeIds[m.startTime].push(stId);
          }
        });
      });
      return plannedMeetingsAttendeeIds;
    },

    @computed get plannedMeetingsRoster() {
      const plannedMeetingsHeadcount = {}
      Object.keys(eCourse.plannedMeetingsAttendeeIds).forEach(mtngTime => plannedMeetingsHeadcount[mtngTime] = eCourse.plannedMeetingsAttendeeIds[mtngTime].length);
      return plannedMeetingsHeadcount;
    },

    @computed get scheduledMeetingsPerStudent() {
      const studentIds = Object.keys(eCourse.paidHoursPerStudent);
      const scheduledMeetingsPerStudent = {};
      studentIds.forEach(stId => {
        scheduledMeetingsPerStudent[stId] = [];
      });
      eCourse.scheduledMeetings.forEach(m => {
        studentIds.forEach(stId => {
          if (isEnrolled(stId, m.startTime))
            scheduledMeetingsPerStudent[stId].push(m);
        });
      });
      return scheduledMeetingsPerStudent;
    },

    @computed get scheduledHoursPerStudent() {
      const userIds = Object.keys(eCourse.scheduledMeetingsPerStudent);
      const scheduledHoursPerStudent = {};
      userIds.forEach(userId => {
        scheduledHoursPerStudent[userId] = 0;
        eCourse.scheduledMeetingsPerStudent[userId].forEach(m => {
          scheduledHoursPerStudent[userId] += m.durationMins;
        });
        scheduledHoursPerStudent[userId] /= 60;
      });
      return scheduledHoursPerStudent;
    },

    @computed get misscheduledStudents() {
      const studentIds = Object.keys(eCourse.scheduledHoursPerStudent);
      return studentIds.filter(uId => eCourse.studentIsActive[uId]).filter(uId => eCourse.scheduledHoursPerStudent[uId] !== eCourse.paidHoursPerStudent[uId]);
    },

    @computed get isUnderscheduled() {
      if (eCourse.activeStudentIds.length === 0) return false;
      const hourDiffs = eCourse.activeStudentIds.map(uId => eCourse.scheduledHoursPerStudent[uId] - eCourse.paidHoursPerStudent[uId]);
      const numNegDiffs = hourDiffs.reduce((acc, val) => acc + ( val < 0 ? 1 : 0 ), 0);
      if (eCourse.type === "onlineGroup") {
        return (numNegDiffs >= 2);
      }
      return (numNegDiffs >= eCourse.activeStudentIds.length);
    },

    @computed get isOverscheduled() {
      if (eCourse.type === "onlineGroup") {
        return Object.values(eCourse.plannedMeetingsRoster).filter(rosterLength => rosterLength < 2).length > 0;
      }
      const numStudents = Object.keys(eCourse.activeStudentIds).length;
      return Object.values(eCourse.plannedMeetingsRoster).filter(rosterLength => rosterLength < numStudents).length > 0;
    },

  
    @computed get attendedHoursPerStudent() {
      const attendedHoursPerStudent = {};
      const studentIds = Object.keys(eCourse.attendedHoursPerStudentInMonth);
      studentIds.forEach(userId => {
        attendedHoursPerStudent[userId] = Object.values(eCourse.attendedHoursPerStudentInMonth[userId]).reduce((a, b) => a + b, 0);
      });
      return attendedHoursPerStudent;
    },
    
    @computed get attendedHoursPerStudentInMonth() {
      const studentIds = Object.keys(eCourse.paidHoursPerStudent);
      const attendedHoursPerStudentAndMonth = {};
      studentIds.forEach(userId => {
        attendedHoursPerStudentAndMonth[userId] = {};
        eCourse.scheduledMeetingsPerStudent[userId].filter(m => m.state === 'TAUGHT').forEach(m => {
          const classMmnt = moment(m.startTime).tz("Asia/Singapore");
          const month = monthIdx(classMmnt);
          attendedHoursPerStudentAndMonth[userId][month] = attendedHoursPerStudentAndMonth[userId][month] || 0;
          attendedHoursPerStudentAndMonth[userId][month] += m.durationMins / 60;
        });
      });
      return attendedHoursPerStudentAndMonth;
    },
  
    @computed get accountedHoursPerStudent() {
      const accountedHoursPerStudent = {};
      const studentIds = Object.keys(eCourse.accountedHoursPerStudentInMonth);
      studentIds.forEach(userId => {
        accountedHoursPerStudent[userId] = Object.values(eCourse.accountedHoursPerStudentInMonth[userId]).reduce((a, b) => a + b, 0);
      });
      return accountedHoursPerStudent;
    },
    
    @computed get accountedHoursPerStudentInMonth() {
      const studentIds = Object.keys(eCourse.paidHoursPerStudent);
      const accountedHoursPerStudentInMonth = {};
      studentIds.forEach(userId => {
        accountedHoursPerStudentInMonth[userId] = {};
        eCourse.scheduledMeetingsPerStudent[userId].filter(m => m.state === 'TAUGHT' && m.accountedFor).forEach(m => {
          let payslipMmnt = moment(m.payslip.split(".")[1]).subtract(48, "hours"); //very old payslips have a different identifier format
          if (!payslipMmnt.isValid()) payslipMmnt = moment(m.startTime);
          const month = monthIdx(payslipMmnt);
          accountedHoursPerStudentInMonth[userId][month] = accountedHoursPerStudentInMonth[userId][month] || 0;
          accountedHoursPerStudentInMonth[userId][month] += (m.durationMins / 60);
        });
      });
      return accountedHoursPerStudentInMonth;
    },
  
    @computed get unearnedRevenueByStudent() {
      const {remainingCreditPerStudent} = eCourse.unearnedRevenues;
      return remainingCreditPerStudent;
    },
  
    @computed get unearnedRevenueByPayerAndMonth() {
      const {remainingCreditPerPayerAndMonth} = eCourse.unearnedRevenuesPerMonth;
      return remainingCreditPerPayerAndMonth;
    },
    
    @computed get unearnedRevenues() {
      const {remainingCreditPerStudentAndMonth, remainingCreditPerPayerAndMonth} = eCourse.unearnedRevenuesPerMonth;
      const currentMonth = monthIdx(moment());
      const remainingCreditPerStudent = {};
      const stIds = Object.keys(remainingCreditPerStudentAndMonth);
      for (let stId of stIds) {
        remainingCreditPerStudent[stId] = remainingCreditPerStudentAndMonth[stId][currentMonth];
      }
      const remainingCreditPerPayer = {};
      const payerIds = Object.keys(remainingCreditPerPayerAndMonth);
      for (let payerId of payerIds) {
        remainingCreditPerPayer[payerId] = remainingCreditPerPayerAndMonth[payerId][currentMonth];
      }
      return {remainingCreditPerStudent, remainingCreditPerPayer};
    },
    
    @computed get unearnedRevenuesPerMonth() {
      const remainingCreditPerStudentAndMonth = {};
      const remainingCreditPerPayerAndMonth = {};
      
      const currentMonth = monthIdx(moment());
  
      const {consumedCreditPerPayerAndMonth, consumedCreditPerStudentAndMonth} = eCourse.earnedRevenuesPerMonth;
      const {purchasesToSgByPayerAndMonth, purchasesToSgByStudentAndMonth} = eCourse.purchasesToSgByMonth;
  
      const stIds = Object.keys(consumedCreditPerStudentAndMonth);
      stIds.forEach(stId => {
        remainingCreditPerStudentAndMonth[stId] = {0 : 0};
        for (let month = 1; month <= currentMonth; month++) {
          remainingCreditPerStudentAndMonth[stId][month] = 
            remainingCreditPerStudentAndMonth[stId][month - 1] + 
            (purchasesToSgByStudentAndMonth[stId] && purchasesToSgByStudentAndMonth[stId][month] || 0) - 
            (consumedCreditPerStudentAndMonth[stId][month] || 0);
        }
      });

      const payerIds = Array.from(new Set([...Object.keys(consumedCreditPerPayerAndMonth), ...Object.keys(purchasesToSgByPayerAndMonth)]));
      payerIds.forEach(payerId => {
        remainingCreditPerPayerAndMonth[payerId] = {0 : 0};
        for (let month = 1; month <= currentMonth; month++) {
          remainingCreditPerPayerAndMonth[payerId][month] = 
            remainingCreditPerPayerAndMonth[payerId][month - 1] + 
            (purchasesToSgByPayerAndMonth[payerId] && purchasesToSgByPayerAndMonth[payerId][month] || 0) - 
            (consumedCreditPerPayerAndMonth[payerId] && consumedCreditPerPayerAndMonth[payerId][month] || 0);
        }
      });
  
      return { remainingCreditPerStudentAndMonth, remainingCreditPerPayerAndMonth };
    },
  
    @computed get earnedRevenueByPayerAndMonth() {
      const {consumedCreditPerPayerAndMonth} = eCourse.earnedRevenuesPerMonth;
      return consumedCreditPerPayerAndMonth;
    },
  
  
    @computed get earnedRevenuesPerMonth() {
      function calculateApplicablePriceForEachMeeting(purchases) {
        const prices = [];
        purchases.forEach(p => {
          if (p.numHours < 0) {
            let hoursYetToBeRemoved = -p.numHours;
            while (hoursYetToBeRemoved > 0) {
              const pkgToBeUpdated = prices[prices.length - 1];
              if (!pkgToBeUpdated) {
                hoursYetToBeRemoved = 0; 
                console.error(`more negative hours than positive ones in`, purchases);
                continue;
              }
              if (pkgToBeUpdated.numHours > hoursYetToBeRemoved) {
                pkgToBeUpdated.numHours -= hoursYetToBeRemoved;
                hoursYetToBeRemoved = 0;
              } else {
                prices.pop();
                hoursYetToBeRemoved -= pkgToBeUpdated.numHours;
              }
            }
          } else {
            let xrate;
            if (p.invoiceXRate) {
              xrate = p.invoiceXRate;
            } else {
              if (p.price === 0) {
                xrate = 1;
              } else {
                switch (p.currency) {
                  case "EUR": 
                    xrate = 0.6979; break;
                  case "IDR":
                    xrate = 11406; break;
                  case "GBP":
                    xrate = 0.6268; break;
                  case "TWD":
                    xrate = 22.65; break;
                  default:
                    throw `Cannot determine SGD price for purchase of ${p.currency} ${p.price} for payer ${p.userId} in course ${p.courseId} (purchase ID: ${p._id})`;                
                }
              }
            }
            prices.push({
              numHours: p.numHours,
              payerId: p.userId,
              paidToCompany: p.paidToCompany,
              hourlyPricePerStudentSgd: p.price / p.numHours / xrate / p.studentIds.length
            });
          }
        });
        return prices;
      }
      
      const consumedCreditPerStudentAndMonth = {};
      const consumedCreditPerPayerAndMonth = {};
      const stIds = Object.keys(eCourse.purchasesByStudentId);
  
      const currentMonth = monthIdx(moment());
  
      stIds.forEach(stId => {
        const purchases = eCourse.purchasesByStudentId[stId].sort((p1, p2) => p1.creationTime - p2.creationTime);
        const applicablePrices = calculateApplicablePriceForEachMeeting(purchases);
        
        for (let month = 0; month <= currentMonth; month++) {
          let remainingAccountedHoursInMonth = eCourse.accountedHoursPerStudentInMonth[stId][month] || 0;
  
          let previousApplicablePrice = null;
          let consumedCreditInMonthForStudent = 0;
          while (remainingAccountedHoursInMonth > 0 && (applicablePrices.length > 0 || previousApplicablePrice)) {
            const applicablePrice = applicablePrices[0] || previousApplicablePrice;
            previousApplicablePrice = applicablePrice;
            let consumedSgdCreditAtThisPrice;
            if (applicablePrice.numHours > remainingAccountedHoursInMonth) {
              consumedSgdCreditAtThisPrice = remainingAccountedHoursInMonth * applicablePrice.hourlyPricePerStudentSgd;
              applicablePrice.numHours -= remainingAccountedHoursInMonth;
              remainingAccountedHoursInMonth = 0
            } else {
              consumedSgdCreditAtThisPrice = applicablePrice.numHours * applicablePrice.hourlyPricePerStudentSgd;
              remainingAccountedHoursInMonth -= applicablePrice.numHours;
              applicablePrices.shift();
            }
            consumedCreditPerPayerAndMonth[applicablePrice.payerId] = consumedCreditPerPayerAndMonth[applicablePrice.payerId] || {};
            consumedCreditPerPayerAndMonth[applicablePrice.payerId][month] = consumedCreditPerPayerAndMonth[applicablePrice.payerId][month] || 0;
            if (applicablePrice.paidToCompany === "SG") {
              consumedCreditPerPayerAndMonth[applicablePrice.payerId][month] += consumedSgdCreditAtThisPrice;
              consumedCreditInMonthForStudent += consumedSgdCreditAtThisPrice;
            }
          }
          
          consumedCreditPerStudentAndMonth[stId] = consumedCreditPerStudentAndMonth[stId] || {};
          consumedCreditPerStudentAndMonth[stId][month] = consumedCreditInMonthForStudent;
        }
      });
      
      return {consumedCreditPerPayerAndMonth, consumedCreditPerStudentAndMonth};
    },
    
    
    @computed get purchasesToSgByMonth() {
      const purchasesToSgByPayerAndMonth = {}
      const purchasesToSgByStudentAndMonth = {}
      const nowMmnt = moment();
  
      Object.entries(eCourse.purchases).forEach(([payerId, ps]) => {
        ps.forEach((p,idx) => {
          if (p.paidToCompany === "SG" && moment(p.creationTime).isBefore(nowMmnt)) {
            const month = monthIdx(moment(p.creationTime));
            purchasesToSgByPayerAndMonth[payerId] = purchasesToSgByPayerAndMonth[payerId] || {};
            purchasesToSgByPayerAndMonth[payerId][month] = purchasesToSgByPayerAndMonth[payerId][month] || 0;
            purchasesToSgByPayerAndMonth[payerId][month] += p.price / p.invoiceXRate;
            p.studentIds.forEach(stId => {
              purchasesToSgByStudentAndMonth[stId] = purchasesToSgByStudentAndMonth[stId] || {};
              purchasesToSgByStudentAndMonth[stId][month] = purchasesToSgByStudentAndMonth[stId][month] || 0;
              purchasesToSgByStudentAndMonth[stId][month] += p.price / p.invoiceXRate / p.studentIds.length;
            });
          }
        });
      });
      return {purchasesToSgByPayerAndMonth, purchasesToSgByStudentAndMonth};
    },
    
    
    @computed get payersToSgForStudent() {
      const payersByStudentId = {};
      const stIds = Object.keys(eCourse.purchasesByStudentId);
      stIds.forEach(stId => {
        payersByStudentId[stId] = Array.from(new Set(eCourse.purchasesByStudentId[stId].map(p => p.userId)));
      });
      return payersByStudentId;
    },

    /**
     * A student is active if there's still some date in the future in which it'll be enrolled, either because
     * there's no final drop out date or because there is one but is in the future
     * @returns {{}}
     */
    @computed get studentIsActive() {
      const studentIsActive = {};
      Object.keys(eCourse.purchasesByStudentId).forEach(stId => {
        const dates = eCourse.enrolmentDates[stId];
        studentIsActive[stId] = !dates || !!(dates.length % 2) || moment().isBefore(moment(dates[dates.length -1]))
      });
      return studentIsActive;
    },

    @computed get activeStudentIds() {
      const studentIsActive = eCourse.studentIsActive;
      return Object.keys(studentIsActive).filter(uId => studentIsActive[uId]);
    },

    @computed get studentsDueRenewal() {
      const studentIds = Object.keys(eCourse.attendedHoursPerStudent);
      const studentsDueRenewal = [];
      studentIds.forEach(stId => {
        const studentAttendedHours = eCourse.attendedHoursPerStudent[stId];
        const studentPaidHours = eCourse.paidHoursPerStudent[stId];
        const studentIsActive = eCourse.studentIsActive[stId];
        if (studentIsActive
              && moment(eCourse.startDate).isBefore(moment.tz(eCourse.timezone))
              && studentAttendedHours > studentPaidHours - 3
              && (eCourse.scheduledHoursPerStudent[stId] > studentPaidHours || !eCourse.wontContinue)) {
          studentsDueRenewal.push({
            studentId: stId,
            paidHours: studentPaidHours,
            attendedHours: studentAttendedHours
          })
        }
      });
      return studentsDueRenewal;
    },

    @computed get studentsOverdueRenewal() {
      return eCourse.studentsDueRenewal.filter(u => u.paidHours <= u.attendedHours);
    },

    @computed get studentsDueNps() {
      return eCourse.activeStudentIds.filter(stId => {
        const attendedHrs = eCourse.attendedHoursPerStudent[stId];
        const lastMtngDuration = eCourse.lastTaughtMeeting.durationMins / 60;
        return (attendedHrs - lastMtngDuration < 2 && attendedHrs >= 2) ||
          (attendedHrs - lastMtngDuration < 10 && attendedHrs >= 10) ||
          (attendedHrs - lastMtngDuration < 40 && attendedHrs >= 40);
      })
    },

    @computed get usersWhoPaidRecently() {
      const twoWeeksAgo = moment().subtract(1, 'week');
      const userIds = Object.keys(eCourse.purchases);
      return userIds.filter(uId => {
        const lastPaidPurchase = eCourse.latestPaidPurchasePerUserId[uId];
        return lastPaidPurchase && moment(lastPaidPurchase.creationTime).isAfter(twoWeeksAgo);
      });
    },

    @computed get isNowEnding() {
      const oneWeekAgo = moment().subtract(1, 'week');
      return (
        eCourse.wontContinue && (
          (eCourse.lastTaughtMeeting && moment(eCourse.lastTaughtMeeting.startTime).isAfter(oneWeekAgo)) ||
          (eCourse.terminationNotificationDate && moment(eCourse.terminationNotificationDate).isAfter(oneWeekAgo))
        ) &&
        eCourse.plannedMeetings.length <= 2
      )
    },

    @computed get isOnline() {
      return eCourse.location.name.toLowerCase().includes("online");
    }
  };

  Object.assign(eCourse, course);
  return eCourse;

  function isEnrolled(userId, whenIsoOrMillis) {
    //if there's no info about enrolment, enrolment for the full course is assumed
    if (!eCourse.enrolmentDates[userId]) return true;

    //enrolment dates are listed as enrolment / drop-out / enrolment, etc. If this meeting is after an enrolment date
    // and before a drop-out one, the meeting has been scheduled for that user
    const idx = _.sortedIndex(eCourse.enrolmentDates[userId], moment(whenIsoOrMillis));
    return !!(idx % 2);
  }
}

export const coursesStore = new CoursesStore();

let ticStamp;

function tic() {
  ticStamp = Date.now().valueOf();
}

function toc(message) {
  console.log(message, (Date.now().valueOf() - ticStamp))
}

function toIdrTotal(acc, p) {
  if (p.currency === "IDR") {
    return p.price + acc;
  } else if (p.currency === "EUR") {
    return p.price * 15281 + acc;
  } else if (p.currency === "USD") {
    return p.price * 14570 + acc;
  } else if (p.currency === "GBP") {
    return p.price * 17891 + acc;
  } else if (p.currency === "MYR") {
    return p.price * 3318 + acc;
  } else if (p.currency === "COP") {
    return p.price * 3.53 + acc;
  } else if (p.currency === "INR") {
    return p.price * 189 + acc;
  } else if (p.currency === "MXN") {
    return p.price * 717.4 + acc;
  } else if (p.currency === "CLP") {
    return p.price * 17.1 + acc;
  } else if (p.currency === "SGD") {
    return p.price * 11115 + acc;
  } else if (p.currency === "VND") {
    return p.price * 0.6432 + acc;
  } else if (p.currency === "KRW") {
    return p.price * 11.80 + acc;
  } else {
    return p.price * 515.94 + acc; //NTD
  }
}

export function monthIdx(mmnt) {
  return (mmnt.get("year") - 2019) * 12 + mmnt.get("month") + 4;
}
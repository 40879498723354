import {observable} from "mobx";
import {userApi} from "../api/userApi";
import {debounce} from "lodash-decorators/debounce";
import {teacherApi} from "../api/teacherApi";

class TeachersStore {
  @observable teachersById = {};
  @observable teachersLoading = true;

  teachersToRetrieve = [];

  @debounce(500, {trailing: true})
  async retrieveTeachers() {
    this.teachersLoading = true;
    this.teachersToRetrieve = this.teachersToRetrieve.filter(tId => !this.teachersById[tId]);
    if (this.teachersToRetrieve.length > 0) {
      const teachers = await teacherApi.searchTeachers(Array.from(new Set(this.teachersToRetrieve)));
      teachers.forEach(t => {
        this.teachersById[t._id] = t;
      });
      this.teachersToRetrieve = this.teachersToRetrieve.filter(tId => !this.teachersById[tId]);
    }
    this.teachersLoading = false;
  }

  getTeacherById(teacherId) {
    if (!this.teachersById[teacherId]) {
      this.teachersToRetrieve.push(teacherId);
      this.retrieveTeachers();
    }
    return this.teachersById[teacherId];
  }
}

export const teachersStore = new TeachersStore();


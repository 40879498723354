import React, {useEffect, useState} from "react";
import homeStyle from "../../main/home.scss";
import style from "./characters.scss";
import {Menu} from "../../menu/menu";
import {Spinner} from "../../controls/spinner";
import {conversationApi} from "../../api/conversationApi";
import {Button} from "../../controls/button";
import {Link} from "react-router-dom";
import {logError} from "../../errors/errorConsole";

export function Characters() {
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creatingCharacter, setCreatingCharacter] = useState(false);
  
  useEffect(() => {
    loadCharacters();
  }, []);
  
  async function loadCharacters() {
    setLoading(true);
    setCharacters(await conversationApi.getAllCharacters());
    setLoading(false);
  }
  
  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={homeStyle.rightSidePanel}>
        <div className={homeStyle.list}>
          <Button onClick={createCharacter} disabled={creatingCharacter} noArrow>
            {creatingCharacter ? <Spinner/> : "Add"}
          </Button>
          {loading && <Spinner/>}
          {!loading && characters.sort((i1, i2) => i1.name > i2.name ? 1 : -1).map(i =>
            <div className={style.character} key={i._id}>
              <Link to={`/characters/${i._id}`}>
                {i.name}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
  async function createCharacter() {
    setCreatingCharacter(true);
    try {
      await conversationApi.createCharacter({voiceId: "tom", name: "New character"});
      loadCharacters();
    } catch (e) {
      logError("Failed to create character", e);
    }
    setCreatingCharacter(false);
  }  
}
import React from 'react';
import * as moment from "moment";
import styles from './meetingsSchedule.scss';

const days = [1, 2, 3, 4, 5, 6, 7];
const array24 = new Array(24).fill(0);

export function EventsSchedule({startDate, events}) {
  const eventsByDay = [...new Array(7)].map(() => []);
  events.forEach(ev => {
    const mmnt = moment(ev.startTime);
    eventsByDay[mmnt.isoWeekday() - 1].push({
      startDayPercent: (1 + mmnt.hours() + (mmnt.minutes() / 60)) / 0.25,
      durationDayPercent: ev.durationMins / 14.40,
      name: ev.name,
      displayComponent: ev.displayComponent
    });
  });
  return (
    <div className={styles.frame}>
      <div className={styles.axis}>
        <div className={styles.tick}/>
        {array24.map((h, idx) =>
          <div className={styles.tick}>{moment().hours(idx).minutes(0).format("HH:mm")}</div>
        )}
      </div>
      <div className={styles.week}>
        {days.map((d, idx) =>
          <div className={styles.day}>
            <div className={styles.dayLabel}>{moment(startDate).add(idx, 'days').format("ddd, DD MMM")}</div>
            {array24.map(h => <div className={styles.hour}/>)}
            {eventsByDay[idx].map(event =>
              <div className={styles.meeting}
                   style={{
                     top: `${event.startDayPercent}%`,
                     height: `${event.durationDayPercent}%`
                   }}>
                {event.name}
                {event.displayComponent}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
import React, {useEffect, useState} from 'react';
import {usersStore} from "../store/usersStore";
import {classRequestApi} from "../api/classRequestApi";
import {observer} from "mobx-react";
import style from '../classRequests/classRequests.scss';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import * as moment from "moment";
import {Button} from "../controls/button";
import {Link} from "react-router-dom";
import {Spinner} from "../controls/spinner";
import {classRequestStates} from "../api/classRequestApi";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {logError} from "../errors/errorConsole";
import {coursesStore} from "../store/coursesStore";

export const TeacherReplacementRequests = observer(
  function TeacherReplacementRequests() {
    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState({});

    useEffect(() => {
      loadState()
    }, []);

    async function loadState() {
      setLoading(true);
      setRequests({});
      const crArray = (await classRequestApi.findOpenTeacherReplacementRequests()).reverse() || [];
      const requests = {};
      crArray.forEach(cr => requests[cr._id] = cr);
      setRequests(requests);
      setLoading(false);
    }

    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.rightSidePanel}>
          <div className={style.topButtonPanel}>
            <Button className={style.classRequestButton}>
              <Link to="/teacherReplacementRequests/new">
                Add
              </Link>
            </Button>
          </div>
          <div className={homeStyle.list}>
            <div className={style.classRequests}>
              {loading && <Spinner/>}

              {!loading &&
                Object.values(requests).map(trr =>
                  <TeacherReplacementRequest
                    onRemoveFromProposal={() => loadState()}
                    onReactivate={() => loadState()}
                    onWithdraw={() => loadState()}
                    request={trr}/>
                )
              }
              {!loading && Object.keys(requests).length === 0 && <div>No open teacher replacement requests</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const TeacherReplacementRequest = observer(
  function TeacherReplacementRequest(props) {
    const [confirmingRemoval, setConfirmingRemoval] = useState(false);

    const request = props.request;
    const requester = request.userId && usersStore.getUserById(request.userId);
    const course = request.courseId && coursesStore.coursesById[request.courseId];
    return (
      <>
      <div className={`${style.classRequest} ${props.highlighted && style.highlighted}`}
           onClick={props.onClick}>

        <div className={style.name}>{course && course.name || '?'} <small>{requester ? `${requester.name} ${requester.phone}` : ''}</small></div>
        <div className={style.timestamp}>{moment(request.creationTime).format("DD-MMM HH:mm:ss")}</div>

        <div>
          <a href={request.ticketUrl} target="_blank" onClick={e => e.stopPropagation()}>
            <Button className={style.classRequestButton} text="Ticket"/>
          </a>
          {request.closed !== 'Y' ?
            <>
              {request.courseProposalId ?
                <>
                  <Button className={style.classRequestButton}>
                    <Link to={`/courseProposals/${request.courseProposalId}`}>
                      See proposal
                    </Link>
                  </Button>
                  <Button className={style.classRequestButton}
                          text="Remove from proposal"
                          onClick={showRemovalConfirmation}/>
                </> :
                <Button className={style.classRequestButton}>
                  <Link to={{
                    pathname: `/courseProposals/new`,
                    state: {
                      courseProposal: createCourseProposalFromTeacherReplacementRequest(request)
                    }
                  }}>
                    Create proposal
                  </Link>
                </Button>
              }
              <Button className={style.classRequestButton}>
                <Link to={{pathname: `/teacherReplacementRequests/${request._id}/withdrawal`, state: {request}}}>
                  Withdraw
                </Link>
              </Button>
            </> :
            request.state !== classRequestStates.PAID &&
              <Button className={style.classRequestButton}
                        text="Reactivate"
                        onClick={reactivate}
              />
          }
        </div>
      </div>
      {confirmingRemoval &&
        <ConfirmationDialog
          message={`You're removing ${requester.name} from its course proposal. This may close the course proposal for good if this is the lass class request linked to it. Are you sure this is what you want to do?`}
          onConfirm={removeFromProposal}
          onReject={() => setConfirmingRemoval(false)}/>
      }
      </>
    );

    function showRemovalConfirmation() {
      setConfirmingRemoval(true);
    }

    async function removeFromProposal() {
      request.courseProposalId = null;
      try {
        await classRequestApi.updateTeacherReplacementRequest(request);
        props.onRemoveFromProposal && props.onRemoveFromProposal();
      } catch (e) {
        logError(`Couldn't remove teacher replacement request ${request._id} from its course proposal`, e);
      }
      setConfirmingRemoval(false);
    }
  }
);


function createCourseProposalFromTeacherReplacementRequest(request) {
  const course = coursesStore.coursesById[request.courseId];
  return course ? {
    name: course.name + " - needs teacher replacement",
    teacherReplacementRequestId: request._id,
    schedule: (course.schedule && course.schedule.length && course.schedule) || [],
    visibleTo: [],
    invisibleTo: [course.teacherId],
    age: course.acceptedAges,
    location: course.location,
    type: course.type,
    timezone: course.timezone,
    subject: course.subject,
  } : {};
}
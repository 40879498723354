// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baEEI0bP{margin:5px 10px;max-width:300px;display:flex;align-items:center;flex-wrap:wrap}.baEEI0bP .B06YVmHf{font-size:13px;color:green;display:inline;padding:5px}.baEEI0bP .B06YVmHf.oqcZ_jk2{color:#8c8}.baEEI0bP .B06YVmHf.dFCV3EF_{border:1px solid;border-radius:6px}.baEEI0bP .B06YVmHf.ZuNP4tY5{color:red}.baEEI0bP .B06YVmHf.ZuNP4tY5.oqcZ_jk2{color:#faa}.baEEI0bP .B06YVmHf.psUaKmiW{color:#666}.baEEI0bP .B06YVmHf.psUaKmiW.oqcZ_jk2{color:#bbb}.Mq40IzTu{color:green}.aEZgQhqL{color:red}\n", "",{"version":3,"sources":["webpack://./src/courseProposals/courseProposals.scss"],"names":[],"mappings":"AAEA,UACE,eAAgB,CAChB,eAAgB,CAChB,YAAa,CACb,kBAAmB,CACnB,cAAe,CALjB,oBAQI,cAAe,CACf,WAAY,CACZ,cAAe,CACf,WAAY,CAXhB,6BAcM,UAAW,CAdjB,6BAkBM,gBAAiB,CACjB,iBAAkB,CAnBxB,6BAuBM,SAAU,CAvBhB,sCA0BQ,UAAW,CA1BnB,6BA+BM,UAAW,CA/BjB,sCAkCQ,UAAW,CACZ,UAML,WAAY,CACb,UAGC,SAAU","sourcesContent":["@import '../theme.scss';\n\n.teachers {\n  margin: 5px 10px;\n  max-width: 300px;\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n\n  .teacher {\n    font-size: 13px;\n    color: green;\n    display: inline;\n    padding: 5px;\n\n    &.notPicked {\n      color: #8c8;\n    }\n\n    &.picked {\n      border: 1px solid;\n      border-radius: 6px;\n    }\n\n    &.rejected {\n      color: red;\n\n      &.notPicked {\n        color: #faa;\n      }\n    }\n\n    &.responsePending {\n      color: #666;\n\n      &.notPicked {\n        color: #bbb;\n      }\n    }\n  }\n}\n\n.callSuccess {\n  color: green;\n}\n\n.callFailure {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teachers": "baEEI0bP",
	"teacher": "B06YVmHf",
	"notPicked": "oqcZ_jk2",
	"picked": "dFCV3EF_",
	"rejected": "ZuNP4tY5",
	"responsePending": "psUaKmiW",
	"callSuccess": "Mq40IzTu",
	"callFailure": "aEZgQhqL"
};
export default ___CSS_LOADER_EXPORT___;

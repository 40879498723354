import React, {useEffect, useState} from 'react';
import {Field} from "formik";
import {userApi} from "../api/userApi";
import style from './userField.scss';
import * as _ from "lodash";

export function UserFieldComponent({label, value, onUserSelected}) {
  const [searchString, setSearchString] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    loadSearchStringFromUserId();
  }, [value]);

  async function loadSearchStringFromUserId() {
    if (value)
      setSearchString((await userApi.searchUsersByIds([value]))[0]?.name || "");
    else
      setSearchString("");
  }

  async function updateOptions(ev) {
    const input = ev.target.value;
    if (input.length >= 3) {
      setSearchString(input);
      updateUsers(input);
    } else {
      setSearchString(input);
      setUsers([])
    }
  }

  const updateUsers = _.debounce(
    async function updateUsers(input) {
      setUsers(await userApi.searchUsersByNameSubstr(input));
  }, 500);

  async function optionSelected(user) {
    setSearchString(user.name);
    setUsers([]);
    onUserSelected(user._id);
  }

  async function formSubmitted(ev) {
    ev.preventDefault();
    if (users.length === 1)
      optionSelected(users[0]);
  }

  return (
    <div>
      <div>{label}</div>
      <form onSubmit={formSubmitted}>
        <input type="text" autoFocus onChange={updateOptions} value={searchString}/>
        {users && users.length > 0 &&
          <div className={style.suggestions}>
            {users.map(u =>
              <div className={style.suggestion} onClick={() => optionSelected(u)}>{u.name} {u.email} {u.phone}</div>
            )}
          </div>
        }
      </form>
    </div>
  );
}

export const UserField = ({
  field: {name, value},
  form: {setFieldValue},
  values,
  ...props
}) => {
  return <UserFieldComponent label={props.label} value={value} onUserSelected={userId => setFieldValue(name, userId)}/>
};

import React from 'react';
import {classRequestApi} from "../api/classRequestApi";
import {NeatFormField} from "../controls/neatFormField";
import {NeatFieldValue} from "../controls/neatFieldValue";
import history from "../history/history";
import homeStyle from '../main/home.scss';
import style from './classRequestEditor.scss';
import {Menu} from "../menu/menu";
import {Formik, Form, Field} from "formik";
import {usersStore} from "../store/usersStore";
import {observer} from "mobx-react";
import {UserField, UserFieldComponent} from "../users/userField";
import {ArrayField} from "../controls/arrayField";
import {logError} from "../errors/errorConsole";

@observer
export class ClassRequestEditor extends React.Component {
  constructor(props) {
    super(props);
    if (props.match.params.classRequestId === "new") {
      this.state = {
        classRequest: {},
      }
    } else {
      if (props.location.state && props.location.state.classRequest) {
        this.state = {
          classRequest: props.location.state.classRequest,
        };
      } else {
        this.state = {
          classRequest: {}
        };
        (async () => {
          try {
            await this.loadState();
          } catch (e) {
            logError("couldn't load class request ", e);
            history.push('/classRequests');
          }
        })();
      }
    }
    this.submit = this.submit.bind(this);
  }

  async loadState() {
    this.setState({
      classRequest: await classRequestApi.getClassRequest(this.props.match.params.classRequestId)
    });
  }

  async submit(cr) {
    try {
      if (cr._id)
        await classRequestApi.updateClassRequest(cr);
      else
        await classRequestApi.createClassRequest(cr);
      history.push('/classRequests');
    } catch (e) {
      logError("Failed to create class request", e);
    }
  }

  render() {
    const cr = this.state.classRequest;
    const crId = cr && cr._id
    const userId = cr && cr.userId
    const payer = userId && usersStore.getUserById(userId)
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={style.classRequestEditor}>
          Class request
          <Formik
            initialValues={cr}
            onSubmit={this.submit}
            enableReinitialize={true}
          >
            <Form>
              {
                crId ?
                  <NeatFieldValue label="Customer (payer)"
                                  value={payer && payer.name}/>
                  :
                  <NeatFormField name="userId"
                                 label="Customer (payer)"
                                 component={({field, form}) =>
                                   <UserFieldComponent label="" value={field.value} onUserSelected={userId => {
                                     form.setFieldValue(field.name, userId);
                                     this.setState({payerId: userId});
                                   }}/>
                                 }
                  />

              }
              <ArrayField name="studentIds"
                          label="Students"
                          innerComp={UserField}/>
  
              <NeatFormField name="referralOf"
                             label="Referral of"
                             component={UserField}
              />

              <button type="submit">SAVE</button>

            </Form>
          </Formik>
        </div>
      </div>
    );
  }
}
import React from 'react';
import Plotly from 'plotly.js-cartesian-dist'
import {observer} from "mobx-react";
import {coursesStore} from "../store/coursesStore";
import * as moment from 'moment-timezone';

const xValues = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const yValues = new Array(96).fill(0).map((v, idx) => moment().hours(idx / 4).minutes((idx % 4) * 15).format("HH:mm"));

export const Calendar = observer(
  function Calendar(props) {
    const data = [
      {
        z: coursesStore.numMeetingsPerQuarterHourLast20Wks,
        x: xValues,
        y: yValues,
        type: 'heatmap',
        colorscale: 'Hot',
        hoverongaps: false,
        reversescale: true,
        xgap: 1,
      }
    ];

    const layout = {
      title: 'Last 20 weeks (WIB)',
      yaxis: {
        autorange: "reversed",
        type: "category",
        tickson: "boundaries",
      },
      xaxis: {
        type: "category",
        tickson: "boundaries"
      }
    };

    setTimeout(() => {
      const canvas = document.getElementById("canvas");
      if (canvas) {
        Plotly.newPlot(canvas, data, layout);
      }
    });

    return (
      <div id="canvas" style={{minHeight:600}}>
      </div>
    );
  }
);
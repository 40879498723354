import Datetime from "react-datetime";
import * as moment from "moment-timezone";
import React from "react";

export const DateTimeField = ({
  field: {name, value},
  form: {setFieldValue},
  values,
  ...props
}) => {
  return <Datetime value={new Date(value)} timeFormat="HH:mm" onChange={v => setFieldValue(name, moment(v).toISOString())}/>;
};
import React, {useEffect, useState} from 'react';
import {classRequestApi} from "../api/classRequestApi";
import {NeatFormField} from "../controls/neatFormField";
import history from "../history/history";
import homeStyle from '../main/home.scss';
import style from './teacherReplacementRequestEditor.scss';
import {Menu} from "../menu/menu";
import {Formik, Form, Field} from "formik";
import {observer} from "mobx-react";
import {UserFieldComponent} from "../users/userField";
import {logError} from "../errors/errorConsole";
import {CoursePicker} from "../courses/coursePicker";
import {useLocation} from "react-router";
import {coursesStore} from "../store/coursesStore";

export const TeacherReplacementRequestEditor = observer(
  function TeacherReplacementRequestEditor() {

    const {state} = useLocation();
    const initialValues = state ? {courseId: state.courseId} : {};

    async function submit(request) {
      try {
        await classRequestApi.createTeacherReplacementRequest(request);
        history.push('/teacherReplacementRequests');
      } catch (e) {
        logError("Failed to create teacher replacement request", e);
      }
    }

    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={style.classRequestEditor}>
          Teacher replacement request
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            enableReinitialize={true}
          >
            <Form>
              <NeatFormField name="courseId"
                             label="Course"
                             component={({field, form}) =>
                               <CoursePicker
                                  initialSearchString={coursesStore.coursesById[field.value] && coursesStore.coursesById[field.value].name}
                                  onCourseSelected={course => {
                                     form.setFieldValue(field.name, course._id);
                                  }}
                               />
                             }
              />
              <NeatFormField name="userId"
                             label="Requester (student or ninja)"
                             component={({field, form}) =>
                               <UserFieldComponent label="" value={field.value} onUserSelected={userId => {
                                 form.setFieldValue(field.name, userId);
                               }}/>
                             }
              />
              <button type="submit">SAVE</button>

            </Form>
          </Formik>
        </div>
      </div>
    );
  }
);
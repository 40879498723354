import React, {useState} from 'react';
import {usersStore} from "../store/usersStore";
import {classRequestApi} from "../api/classRequestApi";
import {observer} from "mobx-react";
import style from './classRequests.scss';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import * as moment from "moment";
import {Button} from "../controls/button";
import {Link} from "react-router-dom";
import {deskApi} from "../api/deskApi";
import {Spinner} from "../controls/spinner";
import {onlineLocation} from "../controls/locationSelector";
import {courseProposalTypes} from "../courseProposals/courseProposalEditor";
import {classRequestStates} from "../api/classRequestApi";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {logError} from "../errors/errorConsole";
import {userSession} from "../api/userSession";
import {countryCodes} from "../countries/countryCodes";

@observer
export class ClassRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      merging: false,
      classRequests: {},
      selected: {}
    };
    this.loadState();
    this.mergeSelected = this.mergeSelected.bind(this);
  }

  async loadState() {
    this.setState({
      loading: true,
      classRequests: {},
      selected: {}
    });
    const crArray = (this.props.closed ?
        await classRequestApi.findClosedClassRequests() :
        await classRequestApi.findOpenClassRequests()
      ).reverse() || [];
    const classRequests = {};
    crArray.forEach(cr => classRequests[cr._id] = cr);
    this.setState({
      classRequests
    });
    this.setState({loading: false});
  }

  render() {
    const st = this.state;
    const selectedCrs = this.getSelectedClassRequests();
    const noOfCrSelected = selectedCrs.length;
    const noOfProposedCrSelected = selectedCrs.filter(cr => !!cr.courseProposalId).length;
    const potentialDuplicatesById = this.getPotentialDuplicatesById(Object.values(st.classRequests))
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.rightSidePanel}>
          {!this.props.closed &&
            <div className={style.topButtonPanel}>
              <Button className={style.classRequestButton}>
                <Link to="/classRequests/new">
                  Add
                </Link>
              </Button>
              {noOfCrSelected > 1 && noOfProposedCrSelected <= 1 && noOfCrSelected <= 3 &&
                <div>
                  <Button className={style.classRequestButton}
                          text={!st.merging && "Merge"}
                          noArrow={st.merging}
                          onClick={!st.merging && this.mergeSelected}>
                    {st.merging && <Spinner/>}
                  </Button>
                </div>
              }
              {noOfCrSelected > 1 && noOfProposedCrSelected === 0 &&
              <Button className={style.classRequestButton}>
                <Link to={{
                  pathname : `/courseProposals/new`,
                  state: {
                    courseProposal: createCourseProposalFromClassRequests(selectedCrs)
                  }
                }}>
                  Create public proposal
                </Link>
              </Button>
              }
            </div>
          }
          <div className={homeStyle.list}>
            <div className={style.classRequests}>
              {st.loading && <Spinner/>}

              {!st.loading &&
                this.props.closed ?
                  <>
                  <div className={style.subsectionHeader}>Paid</div>
                  {Object.values(st.classRequests).filter(cr => cr.state === "PAID").map(cr =>
                    <ClassRequest highlighted={st.selected[cr._id]}
                                  onClick={() => this.classRequestChosen(cr)}
                                  onRemoveFromProposal={() => this.loadState()}
                                  onReactivate={() => this.loadState()}
                                  onMarkedAsPromising={() => this.loadState()}
                                  classRequest={cr}/>
                  )}
                  <div className={style.subsectionHeader}>Withdrawn</div>
                  {Object.values(st.classRequests).filter(cr => cr.state === "WITHDRAWN").map(cr =>
                    <ClassRequest highlighted={st.selected[cr._id]}
                                  onClick={() => this.classRequestChosen(cr)}
                                  onAddToProposal={() => this.loadState()}
                                  onReactivate={() => this.loadState()}
                                  classRequest={cr}/>
                  )}
                  </> :
                  <>
                  <div className={style.subsectionHeader}>Proposed to teachers</div>
                  {Object.values(st.classRequests).filter(cr => !!cr.courseProposalId).map(cr =>
                    <ClassRequest highlighted={st.selected[cr._id]}
                                  onClick={() => this.classRequestChosen(cr)}
                                  onRemoveFromProposal={() => this.loadState()}
                                  onReactivate={() => this.loadState()}
                                  onMarkedAsFake={() => this.loadState()}
                                  onMarkedAsPromising={() => this.loadState()}
                                  classRequest={cr}
                                  isPotentialDuplicate={potentialDuplicatesById[cr._id]}/>
                  )}
                  <div className={style.subsectionHeader}>Not yet proposed</div>
                  {Object.values(st.classRequests).filter(cr => !cr.courseProposalId).map(cr =>
                    <ClassRequest highlighted={st.selected[cr._id]}
                                  onClick={() => this.classRequestChosen(cr)}
                                  onAddToProposal={() => this.loadState()}
                                  onReactivate={() => this.loadState()}
                                  onMarkedAsFake={() => this.loadState()}
                                  onMarkedAsPromising={() => this.loadState()}
                                  classRequest={cr}
                                  isPotentialDuplicate={potentialDuplicatesById[cr._id]}/>
                  )}
                  </>
              }
              {!st.loading && Object.keys(st.classRequests).length === 0 && <div>No {this.props.closed ? "closed" : "open"} class requests</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getPotentialDuplicatesById(openClassRequests) {
    const classRequestsByPayerAndStudents = {};
    const potentialDupesById = {};
    openClassRequests.forEach(cr => {
      const userId = cr.userId;
      const studentIds = (cr.studentIds || []).sort().join('#')
      const id = userId + "$" + studentIds;
      if (classRequestsByPayerAndStudents[id]) {
        potentialDupesById[cr._id] = true;
        potentialDupesById[classRequestsByPayerAndStudents[id]._id] = true;
      }
      classRequestsByPayerAndStudents[id] = cr;
    });
    return potentialDupesById;
  }

  classRequestChosen(cr) {
    this.setState(curSt => {
      return {
        selected: {...curSt.selected, [cr._id]: !curSt.selected[cr._id]}
      }
    });
  }

  async mergeSelected() {
    function completenessScore(cr) {
      return (cr.schedule ? 1 : 0) +
        (cr.medium ? 1 : 0) +
        (cr.groupSize ? 1 : 0) +
        (cr.courseProposalId ? 100 : 0);
    }

    this.setState({merging: true});
    const st = this.state;
    const selectedCrs = this.getSelectedClassRequests();
    const scores = selectedCrs.map(cr => completenessScore(cr));
    const idx = scores.indexOf(Math.max(...scores));

    const masterCr = selectedCrs[idx];
    selectedCrs.splice(idx, 1);         //slaveCrs
    try {
      await Promise.all(
        selectedCrs.map(cr => classRequestApi.updateClassRequest({...cr, mergedInto: masterCr._id}))
      );
      await deskApi.mergeTickets(masterCr.ticketId, selectedCrs.map(cr => cr.ticketId)); //FIXME: this would make more sense to do it in the back-end
      this.loadState();
    } catch (e) {
      logError("Failed to merge tickets", e);
    }
    this.setState({merging: false});
  }

  getSelectedClassRequests() {
    const st = this.state;
    return Object.entries(st.selected)
      .filter(([crId, selected]) => selected)
      .map(([crId, v]) => st.classRequests[crId]);
  }
}

@observer
class ClassRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingToProposal: false,
    };
    this.showProposalPicker = this.showProposalPicker.bind(this);
    this.showRemovalConfirmation = this.showRemovalConfirmation.bind(this);
    this.removeFromProposal = this.removeFromProposal.bind(this);
    this.markAsFake = this.markAsFake.bind(this);
    this.markAsPromising = this.markAsPromising.bind(this);
    this.addToProposal = this.addToProposal.bind(this);
    this.reactivate = this.reactivate.bind(this);
  }

  render() {
    const ninjaIsAdmin = userSession.getUserGroups().includes("ADMINS");
    const currentYear = moment().get("years");
    const cr = this.props.classRequest;
    const payer = cr.userId && usersStore.getUserById(cr.userId);
    const students = cr.studentIds && cr.studentIds.map(stId => usersStore.getUserById(stId)) || [];
    const isPotentialDuplicate = this.props.isPotentialDuplicate;
    return (
      <>
      <div className={`${style.classRequest} ${this.props.highlighted && style.highlighted}`}
           onClick={this.props.onClick}>

        {isPotentialDuplicate && <div className={style.potentialDuplicate}>POTENTIAL DUPLICATE</div>}
        <div className={style.name}>{payer && payer.name || '?'} - {cr.medium} <small>{payer && payer.phone || ''}</small></div>
        <div className={style.timestamp}>{moment(cr.creationTime).format("DD-MMM HH:mm:ss")}</div>
        <div className={style.userSince}>user since {payer && moment(payer.creationTime).format("DD-MMM-YY")}</div>
        <div className={style.name}><small style={{color: "red", fontWeight: "800"}}> {cr.voucher}</small></div>
        <div className={style.name}><small style={{color: cr.promising ? "#00c000" : "#c0c0c0", fontWeight: "800"}}>
          &nbsp;{payer && payer.campaign || (cr.referralOf === "5cab079e61d19a59173f7b3f" ? "referral" : cr.referralOf && usersStore.getUserById(cr.referralOf)?.name) || "unknown source"}
        </small></div>
        <div className={style.country}>{payer && countryCodes[payer.country]}</div>
 
        {students.length > 0 && <table className={style.students}>
          {students.map(st => st && <tr className={style.student}><td>{st.name} {st.yearOfBirth && `aged ${currentYear - st.yearOfBirth - 1}-${currentYear - st.yearOfBirth}`}</td></tr>)}
        </table>}

        {this.state.addingToProposal &&
            <GroupProposalPicker onProposalPicked={this.addToProposal}
                                 onCancel={() => this.setState({addingToProposal: false})}/>}

        <div>
          <a href={cr.ticketUrl} target="_blank" onClick={e => e.stopPropagation()}>
            <Button className={style.classRequestButton} text="Ticket"/>
          </a>
          {cr.state === "PAID" && ninjaIsAdmin &&         
            <Button className={style.classRequestButton}
                    text="Promising"
                    onClick={() => this.setState({confirmingMarkingAsPromising: true})}>
            </Button>
          }
          {cr.closed !== 'Y' ?
            <>
              <Button className={style.classRequestButton}>
                <Link to={{pathname: `/classRequests/${cr._id}`, state: {classRequest: cr}}}>
                  Edit
                </Link>
              </Button>
              {cr.courseProposalId ?
                <>
                  <Button className={style.classRequestButton}>
                    <Link to={`/courseProposals/${cr.courseProposalId}`}>
                      See proposal
                    </Link>
                  </Button>
                  <Button className={style.classRequestButton}
                          text="Remove from proposal"
                          onClick={this.showRemovalConfirmation}/>
                </> :
                <>
                  <Button className={style.classRequestButton}>
                    <Link to={{
                      pathname: `/courseProposals/new`,
                      state: {
                        courseProposal: createCourseProposalFromClassRequests([cr])
                      }
                    }}>
                      Create proposal
                    </Link>
                  </Button>
                  <Button className={style.classRequestButton}
                          text="Add to existing proposal"
                          onClick={this.showProposalPicker}/>
                </>
              }
              <Button className={style.classRequestButton}>
                <Link to={{pathname: `/classRequests/${cr._id}/withdrawal`, state: {classRequest: cr}}}>
                  Withdraw
                </Link>
              </Button>
              <Button className={style.classRequestButton}
                      text="Not a real request"
                      onClick={() => this.setState({confirmingMarkingAsFake: true})}>
              </Button>
              <Button className={style.classRequestButton}
                      text="Promising"
                      onClick={() => this.setState({confirmingMarkingAsPromising: true})}>
              </Button>
            </> :
            cr.state !== classRequestStates.PAID &&
              <Button className={style.classRequestButton}
                        text="Reactivate"
                        onClick={this.reactivate}
              />
          }
        </div>
      </div>
      {this.state.confirmingRemoval &&
        <ConfirmationDialog
          message={`You're removing ${payer.name} from its course proposal. This may close the course proposal for good if this is the class request linked to it. Are you sure this is what you want to do?`}
          onConfirm={this.removeFromProposal}
          onReject={() => this.setState({confirmingRemoval: false})}/>
      }
      {this.state.confirmingMarkingAsFake &&
        <ConfirmationDialog
          message={`You're saying the class request of ${payer.name} IS NOT A REAL REQUEST. This will remove the test calls linked to it, ${cr.proposalId ? "remove it from it course proposal," : ""} and close delete the request for good. Are you sure that's what you want to do? `}
          onConfirm={this.markAsFake}
          requireReason={true}
          onReject={() => this.setState({confirmingMarkingAsFake: false})}/>
      }
      {this.state.confirmingMarkingAsPromising &&
        <ConfirmationDialog
        message={`You're saying ${payer.name} is a PROMISING / SERIOUS CUSTOMER. This will encourage Google / Facebook to send us customers like this one. Are you sure that's what you want to do? `}
        onConfirm={this.markAsPromising}
        onReject={() => this.setState({confirmingMarkingAsPromising: false})}/>
      }
      </>
    );
  }

  async showProposalPicker(ev) {
    ev.preventDefault();
    this.setState({addingToProposal: true});
  }

  async addToProposal(pickedProposalId) {
    const cr = this.props.classRequest;
    cr.courseProposalId = pickedProposalId;
    await classRequestApi.updateClassRequest(cr);
    this.setState({addingToProposal: false});
    this.props.onAddToProposal && this.props.onAddToProposal();
  }

  showRemovalConfirmation() {
    this.setState({confirmingRemoval: true});
  }

  async markAsFake(reason) {
    const cr = this.props.classRequest;
    try {
      await classRequestApi.markClassRequestAsFake(cr, reason);
      this.props.onRemoveFromProposal && this.props.onRemoveFromProposal();
    } catch (e) {
      logError(`Couldn't mark class request ${cr._id} as fake`, e);
    }
    this.setState({confirmingMarkingAsFake: false});
    this.props.onMarkedAsFake();
  }
  
  async markAsPromising() {
    const cr = this.props.classRequest;
    try {
      await classRequestApi.markClassRequestAsPromising(cr);
    } catch (e) {
      logError(`Couldn't mark class request ${cr._id} as promising`, e);
    }
    this.setState({confirmingMarkingAsPromising: false});
    this.props.onMarkedAsPromising();
  }
  
  async removeFromProposal() {
    const cr = this.props.classRequest;
    cr.courseProposalId = null;
    try {
      await classRequestApi.updateClassRequest(cr);
      this.props.onRemoveFromProposal && this.props.onRemoveFromProposal();
    } catch (e) {
      logError(`Couldn't remove class request ${cr._id} from its course proposal`, e);
    }
    this.setState({confirmingRemoval: false});
  }

  async reactivate(e) {
    e.stopPropagation();
    const cr = this.props.classRequest;
    await classRequestApi.updateClassRequest({...cr, state: classRequestStates.ACTIVE});
    this.props.onReactivate && this.props.onReactivate();
  }
}

function GroupProposalPicker(props) {
  const [proposals, setProposals] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [pickedProposalId, setPickedProposalId] = useState();

  async function setSearch(searchStr) {
    setPickedProposalId(null);
    setSearchString(searchStr);
    updateProposals(searchStr);
  }

  const updateProposals = _.debounce(async function (searchStr) {
    setProposals(await classRequestApi.findOpenGroupCourseProposals(searchStr));
  }, 500, {trailing: true});

  function setPickedProposal(p) {
    setPickedProposalId(p._id);
    setProposals([]);
    setSearchString(p.name);
  }

  return (
    <div>
      <input type="text" onChange={ev => setSearch(ev.target.value)} value={searchString} onClick={ev => ev.stopPropagation()}/>
      <div style={{position: "relative"}}>
        <div className={style.suggestions}>
          {proposals.map(p =>
            <div className={style.suggestion} onClick={ev => {ev.stopPropagation(); setPickedProposal(p)}}>{p.name}</div>
          )}
        </div>
      </div>
      <Button className={style.classRequestButton} text="Cancel" onClick={props.onCancel}/>
      {pickedProposalId && <Button className={style.classRequestButton} text="Add to proposal" onClick={() => props.onProposalPicked(pickedProposalId)}/>}
    </div>
  );
}


function createCourseProposalFromClassRequests(crs) {
  const cr = crs.length === 1 ? crs[0] : {};
  const customerNames = crs.map(cr => {
    if (cr.studentIds && cr.studentIds.length)
      return cr.studentIds.map(studentId => usersStore.getUserById(studentId) && usersStore.getUserById(studentId).name).join(' & ')
    return usersStore.getUserById(cr.userId) && usersStore.getUserById(cr.userId).name
  });
  const courseProposal = {
    name: customerNames.join(" & "),
    classRequestIds: crs.map(cr => cr._id),
    schedule: (cr.schedule && cr.schedule.length && cr.schedule) || [],
    visibleTo: (cr.chosenTeacherId && [cr.chosenTeacherId]) || [],
    age: JSON.stringify(cr.age || null),
    startDate: cr.trialDate,
    level: cr.level
  };
  if (crs.length === 1) {
    if (cr.medium === 'cerahOnline' || cr.medium === 'online') {
      courseProposal.location = onlineLocation;
        if (cr.groupSize === 1) {
          courseProposal.type = courseProposalTypes.onlinePrivate;
        } else {
          courseProposal.type = courseProposalTypes.onlineGroup;
        }
    } else if (cr.medium === 'home' || cr.medium === 'tuitionCenter') {
      courseProposal.type = courseProposalTypes.privat;
    } else if (cr.medium === 'cerahBareng') {
      courseProposal.type = courseProposalTypes.bareng;
    }
  } else {
    if (crs[0].medium === 'cerahOnline' || cr.medium === 'online') {
      courseProposal.type = courseProposalTypes.onlineGroup;
    } else {
      courseProposal.type = courseProposalTypes.bareng;
    }
  }
  if (cr.timezone) {
    if (cr.timezone === "WITA" || cr.timezone === "+08") {
      courseProposal.timezone = "Asia/Makassar"
    } else if (cr.timezone === "WIT" || cr.timezone === "+09") {
      courseProposal.timezone = "Asia/Jayapura"
    } else if (cr.timezone === "CET" || cr.timezone === "CEST") {
      courseProposal.timezone = "Europe/Madrid"
    } else if (cr.timezone === "BST" || cr.timezone === "GMT") {
      courseProposal.timezone = "Europe/London"
    } else { //if (scheduleText.includes("WIB") || scheduleText.includes("+07")) {
      courseProposal.timezone = "Asia/Jakarta"
    }
  } else {
    courseProposal.timezone = "Asia/Jakarta";
  }
  return courseProposal;
}

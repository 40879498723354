import React from 'react';
import {Menu} from "../menu/menu";
import homeStyle from '../main/home.scss';
import style from './courses.scss';
import {Link} from "react-router-dom";
import history from "../history/history";
import {Course} from "./course";
import {Spinner} from "../controls/spinner";
import {coursesStore} from "../store/coursesStore";
import {observer} from "mobx-react";

@observer
export class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      search: true,
    };
    this.updateCourses = _.debounce(this.updateCourses.bind(this), 500);
    this.updateOptions = this.updateOptions.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
  }

  keyDown(evt) {
    evt = evt || window.event;
    if (evt.ctrlKey && evt.keyCode === 70) {
      this.inputRef.current.focus();
      this.inputRef.current.select();
      evt.preventDefault();
    }
    if (evt.code === "F3") {
      if (evt.shiftKey) {
        window.find(this.state.searchString, false, true);
      } else {
        window.find(this.state.searchString);
      }
      evt.preventDefault();
    }
  }

  render() {
    const courses = this.state.courses;
    courses.sort((c1, c2) => c1.startDate < c2.startDate ? 1 : -1);
    return (
      <div className={homeStyle.home}>
        <Menu/>
        <div className={homeStyle.list}>
          {this.state.search && <div className={style.topButtonPanel}>
            <input ref={this.inputRef} type="text" autoFocus onChange={this.updateOptions}
                   value={this.state.searchString}/>
            <button onClick={() => window.find(this.state.searchString)}>∨</button>
            <button onClick={() => window.find(this.state.searchString, false, true)}>∧</button>
          </div>}
          <div className={style.courses}>
            {coursesStore.coursesLoading && <Spinner/>}
            {courses.map(c =>
              <Course key={c._id}
                      course={c}
                      onEdit={() => this.editCourse(c)}
                      onPurchase={() => this.purchaseCourse(c)}
                      onReimbursement={() => this.inversePurchase(c)}
                      onPaymentRequest={() => this.createPaymentRequests(c)}
                      onCreateTrial={() => this.createTrial(c)}
                      onEditMeetings={() => this.editMeetings(c)}
                      onEditEnrolments={(userId) => this.editEnrolments(c, userId)}
                      onShowPurchases={(stId) => this.showPurchaseHistory(c, stId)}
                      onTransferStudent={(stId) => this.transferStudent(c, stId)}
                      onShowNps={() => this.showNps(c)}
                      onTeacherReplacement={()=> this.createTeacherReplacementRequest(c)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  async updateOptions(ev) {
    const input = ev.target.value;
    this.setState({
      searchString: input
    });
    if (input.length >= 2) {
      this.updateCourses(input);
    } else {
      this.setState({
        courses: []
      });
    }
  }

  async updateCourses(input) {
    const showAll = this.props.location.pathname && "/allCourses" === this.props.location.pathname;
    const [keys, courseIds] = showAll ? coursesStore.allCoursesBySearchString : coursesStore.activeCoursesBySearchString;
    const courses = new Set();
    keys.forEach((k, idx) => {
      if (k.includes(input.toLowerCase())) {
        courses.add(coursesStore.coursesById[courseIds[idx]]);
      }
    });
    this.setState({
      courses: Array.from(courses)
    });
  }

  createTeacherReplacementRequest(course) {
    history.push(`/teacherReplacementRequests/new`, {courseId: course._id });
  }

  editCourse(course) {
    history.push(`/courses/${course._id}`);
  }

  purchaseCourse(course) {
    history.push('/purchases/new', {course: {_id: course._id, name: course.name, schedule: course.schedule, timezone: course.timezone}});
  }

  inversePurchase(course) {
    history.push('/reimbursements/new', {course: {_id: course._id, name: course.name}});
  }

  createPaymentRequests(course) {
    history.push(`/paymentRequests/new`, {course: {_id: course._id, name: course.name, courseProposalId: course.courseProposalId, schedule: course.schedule, timezone: course.timezone}});
  }

  createTrial(course) {
    history.push('/createTrial', {course: {_id: course._id, name: course.name, courseProposalId: course.courseProposalId}});
  }

  editMeetings(course) {
    history.push(`/courses/${course._id}/meetings`);
  }

  editEnrolments(course, userId) {
    history.push(`/courses/${course._id}/enrolments/${userId}`);
  }

  transferStudent(course, stId) {
    history.push(`/creditTransfers/new`, {srcCourseId: course._id, studentId: stId});
  }

  showPurchaseHistory(course, stId) {
    history.push(`/courses/${course._id}/students/${stId}/purchaseHistory`)
  }

  showNps(course) {
    history.push(`/courses/${course._id}/nps`);
  }
}

import React from 'react';
import {usersStore} from "../store/usersStore";
import {Button} from "../controls/button";
import history from "../history/history";
import {Spinner} from "../controls/spinner";
import style from "./courseRenewalWithdrawer.scss";
import {observer} from "mobx-react";
import {courseRenewalFinalStates} from "../api/courseApi";
import {dropoutReasons, courseApi} from "../api/courseApi";
import {logError} from "../errors/errorConsole";

@observer
export class CourseRenewalWithdrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  render() {
    const renewal = this.props.location.state.courseRenewal;
    const studentName = renewal.userId && usersStore.getUserById(renewal.userId) && usersStore.getUserById(renewal.userId).name;
    return (
      <div>
        You're marking {studentName}'s course renewal for course {} as <strong>WON'T CONTINUE</strong>.
        <br/>
        Select the suspected reason:
        <div className={style.reasonsList}>
          {
            this.state.submitting ?
              <Spinner/> :
              Object.keys(dropoutReasons).map(reason =>
                <Button className={style.reasonButton}
                        text={reason}
                        onClick={() => this.withdrawCourseRenewal(reason)}/>
              )
          }
        </div>
      </div>
    );
  }

  async withdrawCourseRenewal(reason) {
    const renewal = this.props.location.state.courseRenewal;
    this.setState({submitting: true});
    try {
      await courseApi.updateCourseRenewal({...renewal, finalState: courseRenewalFinalStates.WONT_CONTINUE, dropoutReason: reason});
    } catch (e) {
      logError("Unable to drop out student", e);
      this.setState({submitting: false});
      return;
    }
    history.push("/renewals");
  }
}


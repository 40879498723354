// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vwFuipVW{display:flex;flex-direction:column;align-items:flex-start}.vwFuipVW .QDU6v0RF{font-size:12px;margin:10px;width:200px}\n", "",{"version":3,"sources":["webpack://./src/renewals/courseRenewalWithdrawer.scss"],"names":[],"mappings":"AAAA,UACE,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CAHzB,oBAMI,cAAe,CACf,WAAY,CACZ,WAAY","sourcesContent":[".reasonsList {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  .reasonButton {\n    font-size: 12px;\n    margin: 10px;\n    width: 200px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reasonsList": "vwFuipVW",
	"reasonButton": "QDU6v0RF"
};
export default ___CSS_LOADER_EXPORT___;

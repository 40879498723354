import React from 'react';
import {quizApi} from "../api/quizApi";
import * as moment from "moment";
import {observer} from "mobx-react";
import style from '../dashboard/dashboard.scss';
import {coursesStore} from "../store/coursesStore";
import {Link} from "react-router-dom";

@observer
export class NpsRecent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      npsAttempts: [],
    };
    this.loadState();
  }


  async loadState() {
    const npsAttempts = await quizApi.getAttemptsAfter("nps", Date.now() - 86400000 * 7);
    npsAttempts.sort((a, b) => b.startTimeMillis - a.startTimeMillis);
    this.setState({
      npsAttempts,
    });
  }

  render() {
    return (
      this.state.npsAttempts.map(att => {
        const c = coursesStore.coursesById[att.params.courseId];
        return (
          <Link to={`/courses/${att.params.courseId}/nps`}>
            <div className= {style.courseRow}>
              <div className={style.date}>{moment(att.startTimeMillis).format("DD-MMM")}</div>
              <div>{c && c.name}</div>
            </div>
          </Link>
        );
      })
    );
  }
}
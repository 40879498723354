// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sq51wRVN{border-radius:10px;margin:10px;background-color:#e6effc;width:calc(100% - 20px);padding:20px;font-weight:700;font-size:16px;color:#2d3037}.Sq51wRVN a{text-decoration:none;color:inherit}.Sq51wRVN:hover{background-color:#455d89;color:white}\n", "",{"version":3,"sources":["webpack://./src/scenarios/characters/characters.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAEA,UACE,kBAAmB,CACnB,WAAY,CACZ,wBCFqB,CDGrB,uBAAwB,CACxB,YAAa,CACb,eAAgB,CAChB,cAAe,CACf,aCFsB,CDNxB,YAWI,oBAAqB,CACrB,aAAc,CAZlB,gBAgBI,wBCXoB,CDYpB,WAAY","sourcesContent":["@import \"../../theme.scss\";\n\n.character {\n  border-radius: 10px;\n  margin: 10px;\n  background-color: $accent1-tint2;\n  width: calc(100% - 20px);\n  padding: 20px;\n  font-weight: 700;\n  font-size: 16px;\n  color: $accent1-shade3;\n\n  a {\n    text-decoration: none;\n    color: inherit;\n  }\n\n  &:hover {\n    background-color: $accent1-shade2;\n    color: white;\n  }\n}\n","$main-color1: #fee773;\n$main-color2: #ffa23e;\n\n$accent1-tint2: #e6effc;\n$accent1-tint1: #bedbff;\n$accent1: #a5c0e5;\n$accent1-shade1: #84a5d1;\n$accent1-shade2: #455d89;\n$accent1-shade3: #2d3037;\n\n$accent2-tint2: #c1d7f4;\n$accent2-tint1: #77a5e1;\n$accent2: #4783d0;\n$accent2-shade1: #1b65c3;\n$accent2-shade2: #09448f;\n\n$accent3-tint2: #f9fbff;\n$accent3-tint1: #9dc1f0;\n$accent3: #5c95de;\n$accent3-shade1: #286fcb;\n$accent3-shade2: #09448f;\n\n$accent4-tint2: #e0e0e0;\n$accent4-tint1: #d6d6d6;\n$accent4: #bfc8e1;\n$accent4-shade1: #7281ae;\n$accent4-shade2: #47568a;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"character": "Sq51wRVN"
};
export default ___CSS_LOADER_EXPORT___;

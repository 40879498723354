import {PayslipInvoiceDisplay} from "./payslipInvoiceDisplay";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {teachersStore} from "../store/teachersStore";
import * as moment from "moment-timezone";
import ReactDOM from "react-dom";
import React from "react";
import {courseApi} from "../api/courseApi";

export async function savePayslipsAsPdfs(payslips) {
  const payslipEl = document.createElement("div");
  document.body.appendChild(payslipEl);
  for (let i = 0; i < payslips.length; i++) {
    const ps = await courseApi.getPayslip(payslips[i]._id); // the payslips returned from the backend as a collection do not contain meetings info (just the fee and the id, but not the number of hours) 
    const teacher = teachersStore.getTeacherById(ps.teacherId);
    await render(payslipEl, <PayslipInvoiceDisplay payslip={ps} teacher={teacher}/>);
    const canvas = await html2canvas(payslipEl.children.item(0), {scale: 2});
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    const width = pdf.internal.pageSize.getWidth();
    const height = canvas.height / canvas.width * width;
    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);
    await pdf.save(`${moment(ps.creationTime).format("YYYYMMDD")}.${teacher.fullName}.${ps.identifier}.pdf`, {returnPromise: true});
    console.log("finished saving", ps.identifier);
    ReactDOM.unmountComponentAtNode(payslipEl);
  }
  document.body.removeChild(payslipEl);
}

async function render(htmlContainer, component) {
  return new Promise((resolve, reject) => {
    try {
      ReactDOM.render(component, htmlContainer, () => {
        console.log("rendered");
        resolve();
      });
    } catch (e) {
      reject(e);
    }
  });
}

// function saveAs(uri, filename) {
//   var link = document.createElement('a');
//   if (typeof link.download === 'string') {
//     link.href = uri;
//     link.download = filename;
//    
//     //Firefox requires the link to be in the body
//     document.body.appendChild(link);
//    
//     //simulate click
//     link.click();
//    
//     //remove the link when done
//     document.body.removeChild(link);
//   } else {
//     window.open(uri);
//   }
// }
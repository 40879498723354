import React, {useEffect, useState} from 'react';
import * as moment from "moment-timezone";
import Datetime from "react-datetime";
import {courseApi} from "../api/courseApi";
import style from './payslips.scss';
import {teachersStore} from "../store/teachersStore";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {Button} from "../controls/button";
import {savePayslipsAsPdfs} from "./payslipPrinter";

export function Payslips() {
  const now = moment();

  const [fromMmnt, setFromMmnt] = useState(moment(now).subtract(30, 'days'));
  const [toMmnt, setToMmnt] = useState(now);
  const [payslips, setPayslips] = useState([]);

  useEffect(() => {
    loadPayslips();
  }, [fromMmnt, toMmnt]);

  async function loadPayslips() {
    const newPayslips = await courseApi.getPayslipsInDateRange(fromMmnt, toMmnt);
    setPayslips(newPayslips);
  }

  return (
    <div className={style.payslips}>
      From: <Datetime value={new Date(fromMmnt.valueOf())} timeFormat="HH:mm" onChange={v => setFromMmnt(moment(v))}/>
      To: <Datetime value={new Date(toMmnt.valueOf())} timeFormat="HH:mm" onChange={v => setToMmnt(moment(v))}/>
      <Button text="Print 'em all!" onClick={() => savePayslipsAsPdfs(payslips)}/>
      {payslips.map(ps => <PayslipEntry payslip={ps}/>)}
    </div>
  );
}

export const PayslipEntry = observer(
  function PayslipEntry({payslip}) {
    const amount = payslip.meetings.reduce((acc, v) => acc + v.fee, 0);
    const teacher = teachersStore.getTeacherById(payslip.teacherId);
    return (
      <Link style={{textDecoration: "none"}} to={`/payslips/${payslip._id}`}>
        <div className={style.payslipEntry}>
          <div>
            <div className={style.name}>{teacher && teacher.name || "??"}</div>
            <div className={style.date}>{moment(payslip.creationTime).format("DD-MMM-YYYY HH:mm")}</div>
          </div>
          <div className={style.amount}>{amount.toLocaleString("id-ID", {style: "currency", currency: "IDR", maximumFractionDigits: 0})}</div>
          {payslip.paid && <div className={style.stamp}>PAID</div>}
        </div>
      </Link>
    );
  }
);

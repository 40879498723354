import React, {useState, useEffect} from 'react';
import history from "../history/history";
import {coursesStore} from "../store/coursesStore";
import {logError} from "../errors/errorConsole";
import {paymentApi} from "../api/paymentApi";
import {usersStore} from "../store/usersStore";
import {observer} from "mobx-react";
import moment from "moment";
import style from "./purchaseEditor.scss";
import {Spinner} from "../controls/spinner";
import {Button} from "../controls/button";
import {Link} from "react-router-dom";
import {RemoveButton} from "../controls/removeButton";

export function PurchaseEditor({location}) {
  if (!location.state || !location.state.course) {
    history.push('/courses');
    return;
  }

  const course = location.state.course;
  const courseId = course._id;

  const [paymentRequests, setPaymentRequests] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedPymtReq, setSelectedPymtReq] = useState(null);

  useEffect(() => {
    loadPaymentRequests();
  }, []);

  async function loadPaymentRequests() {
    setPaymentRequests(await paymentApi.findPaymentRequestsForCourse(courseId));
  }

  return (
    <div style={{padding: 10}}>
      You're adding a purchase for course {course.name}
      {paymentRequests.map(pymtReq =>
        <PaymentRequest paymentReq={pymtReq}
                        onClick={() => setSelectedPymtReq(pymtReq)}
                        onDelete={() => cancelPaymentRequest(pymtReq._id)}
                        selected={pymtReq === selectedPymtReq}
        />
      )}

      <div style={{textAlign: "center"}}>
        Cannot find the payment link you're looking for? Create <Link to={{pathname: "/paymentRequests/new", state: {course}}}>one</Link>
      </div>

      <Button noArrow={isSubmitting}
              onClick={submitPurchase}
              disabled={isSubmitting || selectedPymtReq === null}
              text={!isSubmitting && "Make purchase"}>
        {isSubmitting && <Spinner/>}
      </Button>
    </div>
  );

  async function submitPurchase() {
    if (isSubmitting) return;
    setSubmitting(true);
    try {
      await paymentApi.markPaymentRequestAsPaid(selectedPymtReq._id);
      coursesStore.reloadPurchasesOntoCourseById(courseId);
      history.push('/courses');
    } catch (e) {
      logError(`error saving purchase`, e);
    }
    this.setState({isSubmitting: false});
  }

  async function cancelPaymentRequest(paymentRequestId) {
    try {
      await paymentApi.cancelPaymentRequest(paymentRequestId);
      loadPaymentRequests();
    } catch (e) {
      logError(`Error cancelling payment request ${paymentRequestId}`, e);
    }
  }
}

const PaymentRequest = observer(
  function PaymentRequest({paymentReq, onClick, selected, onDelete}) {
    const payer = usersStore.getUserById(paymentReq.userId);
    const students = paymentReq.studentIds.map(stId => usersStore.getUserById(stId));
    const item = paymentReq.item_details[0];

    return (
      <div className={`${style.paymentRequest} ${selected ? style.selected : ""}`} onClick={onClick}>
        <div className={style.payer}>{payer ? payer.name : "..."}{payer && payer.country ? ` (${payer.country})` : ''}</div>
        <div className={style.student}>for {students.map(st => st ? st.name : "...").join(", ")}</div>
        <div className={style.price}>{paymentReq.currency} {item.price}</div>
        <div className={style.item}>{item.name}</div>
        <div className={style.creationTime}>
          {moment(paymentReq.creationTime).format("DD-MM-YYYY HH:mm:ss")}
        </div>
        <RemoveButton onClick={onDelete}/>
      </div>
    );
  }
);
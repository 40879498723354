import React from 'react';
import {quizApi} from "../api/quizApi";
import * as moment from "moment";
import {observer} from "mobx-react";
import style from '../dashboard/dashboard.scss';

@observer
export class RecentLevelAttempts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levelAttempts: [],
    };
    this.loadState();
  }


  async loadState() {
    const levelAttempts = await quizApi.getAttemptsAfter("level", Date.now() - 604800000);
    levelAttempts.sort((a, b) => b.startTimeMillis - a.startTimeMillis);
    this.setState({
      levelAttempts,
    });
  }

  render() {
    return (
      this.state.levelAttempts.filter(att => att.currQuestion > 25).map(att => {
        return (
          <div className={style.courseRow}>
            <div className={style.date}>{moment(att.startTimeMillis).format("DD/MM HH:mm")}</div>
            <div className={style.userId}>{att.params && att.params.phone || att.userId}</div>
            <div className={style.score}>{att.correctMultChoice}/{att.totalMultChoice} ({getLevelFromTestScore(att)})</div>
          </div>
        )}
      )
    );
  }
}

function getLevelFromTestScore(score) {
  const correct = score.correctMultChoice;
  if (correct <= 8) return 'A1';
  if (correct <= 12) return 'A2';
  if (correct <= 17) return 'B1';
  if (correct <= 20) return 'B2';
  if (correct <= 22) return 'C1';
  return 'C2';
}

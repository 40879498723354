import React from 'react';
import {courseApi} from "../api/courseApi";
import history from "../history/history";
import * as moment from 'moment-timezone';
import {Button} from "../controls/button";
import {Spinner} from "../controls/spinner";
import style from './payslip.scss';
import {coursesStore} from "../store/coursesStore";
import {observer} from "mobx-react";
import {logError} from "../errors/errorConsole";
import {v1 as uuid} from "uuid";
import {availableSubjects} from "../courses/courseEditor";
import { pricingApi } from '../api/pricingApi';

const availableSubjectsMap = {};
availableSubjects.forEach(({value, label}) => availableSubjectsMap[value] = label);

@observer
export class PayslipEditor extends React.Component {
  constructor(props) {
    super(props);
    if (!props.match.params || !props.match.params.teacherId) {
      history.push('/teachers');
      return;
    }
    this.feesByCourseId = {}; // Initialize empty object for fee by course ID, we'll add fee based on course.
    this.state = {
      teacher: {},
      meetingsByCourseId: {},
      isSubmitting: false,
    };
    if (props.location.state && props.location.state.teacher) {
      this.state.teacher = props.location.state.teacher;
    }
    this.loadUnpaidMeetingsOntoState();
    this.submitPayslip = this.submitPayslip.bind(this);
  }

  async loadUnpaidMeetingsOntoState() {
    const meetingsByCourseId = {};
    const teacherId = this.props.match.params.teacherId;
    this.meetings = await courseApi.getUnpaidMeetingsByTeacherId(teacherId);
    const courseIds = Array.from(new Set(this.meetings.map(m => m.courseId)));
    courseIds.forEach(id => coursesStore.reloadCourse(id)); //Make sure the store [eventually] contains the latest data of all courses taught by this teacher
    this.meetings.forEach(m => {
      if (!meetingsByCourseId[m.courseId]) {
        meetingsByCourseId[m.courseId] = [];
      }
      meetingsByCourseId[m.courseId].push(m);
    });
    this.setState({
      meetingsByCourseId,
    });
  }

  render() {
    const mtngs = this.state.meetingsByCourseId;
    const teacherName = this.state.teacher.name;
    return (
      <div>
        {this.state.error && <div style={{color: "red"}}>{this.state.error}</div>}
        <p>Unpaid meetings for {teacherName}:</p>
        <table className={style.payslipTable}>
          <thead>
            <th>Date</th>
            <th>Duration</th>
            <th>Paid enrollees</th>
            <th>Attendance</th>
            <th>Fee</th>
          </thead>
          {Object.keys(mtngs).map(courseId => {
            const c = coursesStore.coursesById[courseId];
            if (!c) return null;
            this.ensureFeesAreRetrieved(c);
            return (<>
              <tr><td colspan="5" className={style.course}>{c.name.toLowerCase().includes("group of") || c.name.toLowerCase().includes("glico") ? <strong style={{fontSize: 16, textTransform: "uppercase"}}>{c.name}</strong> : c.name} --- <span style={{textTransform: "uppercase"}}>{availableSubjectsMap[c.subject]}</span></td></tr>
              {mtngs[courseId].map(m => {
                const numEnrollees = c.paidEnrolleeIdsByMeetingId[m._id]?.length;
                return (<tr key={m._id}>
                  <td className={style.numeric}>{moment(m.startTime).format("DD-MM-YYYY HH:mm")} WIB</td>
                  <td className={style.numeric}>{m.durationMins === 60 ? (m.durationMins + "m"): <strong style={{fontSize: 16}}>{m.durationMins + "m"}</strong>}</td>
                  <td>{numEnrollees >= 0 ? numEnrollees : "?"} enrollees</td>
                  <td>{m.report && m.report.attended && m.report.attended.length} attendees</td>
                  <td>
                    <input type="number" onChange={(ev) => {m.fee = ev.target.value; this.setState({})}} value={m.fee}/>
                    <Button className={style.fillDownBtn}
                            text="Fill down"
                            onClick={() => {
                              mtngs[courseId].forEach(
                                om => {if (om.startTime > m.startTime) om.fee = m.fee}
                              );
                              this.setState({});
                            }}/>
                  </td>
                </tr>);
              })}
            </>);
          })}
        </table>

        {this.state.isSubmitting ?
          <Spinner/> :
          <Button text="Generate payslip" onClick={this.submitPayslip}/>
        }
      </div>
    );
  }

  async submitPayslip() {
    this.setState({
      isSubmitting: true
    });
    const identifier = `${uuid()}.${moment().format("YYYYMMDD")}.${this.state.teacher.name}`;
    try {
      const submittedPayslip = await courseApi.createPayslip({
        identifier,
        teacherId: this.props.match.params.teacherId,
        meetings: this.meetings.map(m => {return {
          _id: m._id,
          fee: m.fee
        }})
      });
      history.push(`/payslips/${submittedPayslip._id}`);
    } catch (e) {
      this.setState({
        isSubmitting: false,
      });
      logError("Unable to create the payslip", e);
    }
  }

  async ensureFeesAreRetrieved(course) {
    if (this.feesByCourseId[course._id] || !this.state.meetingsByCourseId[course._id]) {
      return;
    }
    this.feesByCourseId[course._id] = true;
    
    const meetings = this.state.meetingsByCourseId[course._id]
    await Promise.all(meetings.map(async m => {
      const enrollees = course.paidEnrolleeIdsByMeetingId[m._id];
      let numEnrollees = enrollees?.length;
      const pricings = enrollees.map(stId =>
        (course.purchasesByStudentId[stId].filter(p => p.pricing === "jun24").length > 0 || course.name.toLowerCase().includes("glico")) ? "jun24" : "old" 
      );
      const capacity = (course.type === "onlineGroup" ? 4 : numEnrollees || 1);
      const {teacherFees} = await pricingApi.getPricing({courseType: course.type, capacity, subject: course.subject, pricings});
      m.fee = Math.round(this.calculateFee(m.durationMins, teacherFees));
    }));
    this.setState({});
  }

  calculateFee(durationMin, hourlyFee){
    return hourlyFee * (durationMin / 60);
  }
}
import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {EventsSchedule} from "./eventsSchedule";
import * as moment from "moment";
import {observer} from "mobx-react";
import {coursesStore} from "../store/coursesStore";
import {Button} from "../controls/button";
import {classRequestApi} from "../api/classRequestApi";

export const TrialsSchedule = observer(
  function TrialsSchedule() {
    const [weekOffset, setWeekOffset] = useState(0);
    const [events, setEvents] = useState([]);

    const lastMonday = moment().startOf('isoWeek').add(weekOffset, 'week');
    const nextMonday = moment(lastMonday).add(1, 'week');

    async function loadMeetings() {
      const openCourseProposals = await classRequestApi.findOpenCourseProposals();
      const meetings = openCourseProposals
        .map(cp => coursesStore.activeCourses.filter(c => c.courseProposalId === cp._id)[0]) //FIXME: hack, this should be in the database once the course is created.
        .map(c => c && c.scheduledMeetings[0])  ///FIXME: this will only work if the prospect joins the next class, not a later one
        .filter(m => m && moment(m.startTime).isAfter(lastMonday) && moment(m.startTime).isBefore(nextMonday));
      const events = meetings.map(m => ({
        startTime: m.startTime,
        durationMins: m.durationMins,
        name: coursesStore.coursesById[m.courseId].name
      }));
      setEvents(events);
    }

    useEffect(() => {loadMeetings()}, [weekOffset]);

    return (
      <>
      <div style={{textAlign: "center"}}>Trials from {lastMonday.format("DD-MMM")} to {moment(nextMonday).subtract(1, 'day').format("DD-MMM")} (IN YOUR LOCAL TIME)</div>
      <Button text="Previous" onClick={() => setWeekOffset(weekOffset - 1)}/>
      <Button text="Next" onClick={() => setWeekOffset(weekOffset + 1)}/>
      <div style={{height: "80vh"}}>
        <EventsSchedule events={events} startDate={lastMonday}/>
      </div>
      </>
    );
  }
);
import {api} from "./api";
import {userApi} from "./userApi";

export const paymentApi = {
  async createPaymentRequest({pkgId, userId, studentIds, paymentMethod}) {
    const user = await userApi.getUser(userId);
    const request = {
      pkgId,
      userId: user._id,
      studentIds: studentIds,
      customer: {
        firstName: user.name,
        lastName: '-',
        email: user.email || "info@cerah.co",
        phone: user.phone || "+6287749103645",
        analyticsDistinctId: user.analyticsDistinctId
      },
      paymentMethod
    };
    return api.paymentSvcRequest('POST', `/paymentRequests`, request);
  },

  async findPaymentRequestsForCourse(courseId) {
    return api.paymentSvcRequest("GET", `/paymentRequests?courseId=${courseId}&$sort[creationTime]=-1`);
  },
  
  async getPaymentRequest(pymtReqId) {
    return api.paymentSvcRequest("GET", `/paymentRequests/${pymtReqId}`);
  },

  async markPaymentRequestAsPaid(paymentRequestId) {
    return api.paymentSvcRequest("POST", `/manualPaymentNotifications`, {paymentRequestId});
  },

  async cancelPaymentRequest(paymentRequestId) {
    return api.paymentSvcRequest("POST", `/paymentRequestCancellations`, {paymentRequestId});
  },
  
  xrates: async() => {
    return api.courseSvcRequest("GET", `/exchangeRates?base=IDR&symbols=PHP,ZAR,GHS,USD,SGD,EUR,GBP`);
  },
  
  transferFunds: async (payment) => {
    return api.accountingSvcRequest('POST', '/fundTransfers', payment);
  },
};
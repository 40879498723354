import {api} from './api';

export const courseRenewalFinalStates = {
  PAID: "PAID",
  WONT_CONTINUE: "WONT_CONTINUE"
};

export const dropoutReasons = {
  SLOW_PROGRESS: "SLOW_PROGRESS",
  BORING: "BORING",
  PERSONALITY: "PERSONALITY",
  GOALS_ATTAINED: "GOALS_ATTAINED",
  DISLIKES_PROGRAM: "DISLIKES_PROGRAM",
  TECHNICAL_ISSUE: "TECHNICAL_ISSUE",
  PRICE_TOO_HIGH: "PRICE_TOO_HIGH",
  WANTS_OFFLINE: "WANTS_OFFLINE",
  WANTS_NATIVE: "WANTS_NATIVE",
  PAUSE_FOR_EXAMS: "PAUSE_FOR_EXAMS",
  INTERNAL_TRANSFER: "INTERNAL_TRANSFER"
};

export const courseApi = {
  getCourse: async (courseId) => {
    return await api.courseSvcRequest('GET', `/courses/${courseId}`);
  },

  getAvailableTeacherIds: async (schedule, timezone) => {
    let availableTeacherIds;
    try {
      availableTeacherIds = (await api.courseSvcRequest('GET', `/availableTeachers?schedule=${JSON.stringify(schedule)}&timezone=${timezone}`)).map(teacher => teacher.teacherId);
    } catch (e) {
      availableTeacherIds = [...getForeignTeacherIds(), ...getIndonesianTeacherIds()];
    }
    return availableTeacherIds;
  },

  getActiveCourses: async () => {
    return await api.courseSvcRequest('GET', `/courses?remainingMeetingsGreaterThan=0`);
  },

  getCoursesByCourseProposalId: async (courseProposalId) => {
    return await api.courseSvcRequest('GET', `/courses?courseProposalId=${courseProposalId}`);
  },

  getAllCourses: async () => {
    return await api.courseSvcRequest('GET', `/courses`);
  },

  getCoursesByIds: async (courseIds) => {
    return await api.courseSvcRequest('GET', `/courses?courseIds=${courseIds.join(',')}`);
  },

  createCourse: async (newCourse) => {
    return api.courseSvcRequest('POST', '/courses', newCourse);
  },

  updateCourse: async (course) => {
    return api.courseSvcRequest('PUT', `/courses/${course._id}`, course);
  },

  getOpenRenewals: async () => {
    return api.courseSvcRequest('GET', `/courseRenewals`);
  },

  getActiveTeachers: async () => {
    return api.courseSvcRequest('GET', `/activeTeachers`);
  },

  updateCourseRenewal: async (renewal) => {
    return api.courseSvcRequest('PUT', `/courseRenewals/${renewal._id}`, renewal);
  },

  createPackage: async (newPackage) => {
    return api.courseSvcRequest('POST', '/packages', newPackage);
  },

  updatePackage: async (pkg) => {
    return api.courseSvcRequest('PUT', `/packages/${pkg._id}`, pkg);
  },

  deletePackage: async (packageId) => {
    return api.courseSvcRequest('DELETE', `/packages/${packageId}`);
  },

  searchPackagesByCourseId: async (courseId) => {
    return api.courseSvcRequest('GET', `/packages?course=${courseId}`);
  },

  searchPackagesById: async (ids) => {
    if (ids.length === 0) {
      return [];
    }
    // const commaSepIds = ids.splice(1).reduce((a, b) => a + ',' + b, ids[0]);
    //return api.courseSvcRequest('GET', `/packages?ids=${commaSepIds}`);
    return api.courseSvcRequest('GET', `/packages`); //FIXME, at some point
  },

  createPurchase: async (purchase) => {
    return api.courseSvcRequest('POST', `/purchases`, purchase);
  },

  createTrialPurchase: async (trial) => {
    return api.courseSvcRequest('POST', `/trialPurchases`, trial);
  },
  
  markUserAsReconciled: async (userId, creditBalance, dateMmnt) => {
    return api.courseSvcRequest('POST', `/reconciledUsers`, {userId, creditBalance, dateMillis: dateMmnt.valueOf()});
  },

  createReimbursement: async (reimbursement) => {
    return api.courseSvcRequest('POST', `/reimbursements`, reimbursement);
  },

  createPayslip: async (payslip) => {
    return api.courseSvcRequest('POST', `/payslips`, payslip);
  },

  getPayslip: async (payslipId) => {
    return api.courseSvcRequest('GET', `/payslips/${payslipId}`);
  },

  getPayslipsForTeacher: async (teacherId) => {
    return api.courseSvcRequest('GET', `/payslips?teacherId=${teacherId}`);
  },

  getPayslipsInDateRange: async (fromMmnt, toMmnt) => {
    return api.courseSvcRequest('GET', `/payslips?creationTime[$gt]=${fromMmnt.valueOf()}&creationTime[$lt]=${toMmnt.valueOf()}`);
  },

  cancelPayslip: async (payslipId) => {
    return api.courseSvcRequest('POST', `/payslipCancellations`, {payslipId});
  },

  confirmPayslip: async (payslipId) => {
    return api.courseSvcRequest('POST', `/payslipConfirmations`, {payslipId});
  },

  notifyPayslipToTeacher: async (payslipId) => {
    return api.courseSvcRequest('POST', `/payslipNotifications`, {payslipId});
  },

  searchPurchasesByPackageId: async (packageId) => {
    return api.courseSvcRequest('GET', `/purchases?pkgId=${packageId}`)
  },

  getPurchasesForCourse: async (courseId) => {
    return courseApi.searchPurchasesByCourseIds([courseId]);
  },

  getPurchasesToSgForPayer: async (userId) => {
    return api.courseSvcRequest('GET', `/purchases?userId=${userId}&paidToCompany=SG`);
  },
  
  getTrialPurchasesForCourse: async (courseId) => {
    return api.courseSvcRequest("GET", `/purchases?courseId=${courseId}&pkgId=FREE_TRIAL`);
  },

  searchPurchasesByCourseIds: async (courseIds) => {
    if (courseIds.length === 0) return [];
    return api.courseSvcRequest('GET', `/purchases?courseIds=${courseIds.join(',')}`)
  },

  getAllPurchases: async () => {
    return api.courseSvcRequest('GET', `/purchases?$sort[creationTime]=1`);
  },
  
  getPotentiallyActiveUsersInFinancialYear: async (yearEndMmnt) => {
    return api.courseSvcRequest('GET', `/activeUsersInFinancialYear?yearEndMillis=${yearEndMmnt.valueOf()}`);
  },

  getAllEnrolments: async () => {
    return api.courseSvcRequest('GET', `/enrolments`);
  },

  getEnrolmentsByCourseId: async (courseId) => {
    return await api.courseSvcRequest('GET', `/enrolments?courseIds=${courseId}`);
  },

  getEnrolmentsByCourseIdUserId: async (courseId, userId) => {
    const enrolments = await api.courseSvcRequest('GET', `/enrolments?courseIds=${courseId}&userId=${userId}`);
    return enrolments[0];
  },

  updateEnrolments: async (enrolments) => {
    if (enrolments._id) {
      return api.courseSvcRequest('PUT', `/enrolments/${enrolments._id}`, enrolments);
    }
    return api.courseSvcRequest('POST', `/enrolments`, enrolments);
  },

  searchMeetingsByCourseId: async (courseId) => {
    return api.courseSvcRequest('GET', `/meetings?courseId=${courseId}`);
  },

  searchMeetingsByTeacherIdAndPeriod: async (teacherId, fromMoment, toMoment) => {
    const from = fromMoment.toISOString();
    const to = toMoment.toISOString();
    return [
      ...await api.courseSvcRequest('GET', `/meetings?state[$in]=TAUGHT&state[$in]=PLANNED&teacherId=${teacherId}&startTime[$gt]=${from}&startTime[$lt]=${to}`),
      ...await api.courseSvcRequest('GET', `/scheduledMeetings?teacherId=${teacherId}&startTime[$gt]=${from}&startTime[$lt]=${to}`)
    ];
  },

  getAllMeetings: async () => {
    return api.courseSvcRequest('GET', `/meetings`);
  },

  searchTaughtMeetingsByCourseId: async (courseId) => {
    return api.courseSvcRequest('GET', `/meetings?courseId=${courseId}&state=TAUGHT`);
  },

  getUnpaidMeetingsByTeacherId: async (teacherId) => {
    return api.courseSvcRequest('GET', `/meetings?teacherId=${teacherId}&unpaid=true`);
    /**FIXME: this should filter out meetings that have not been paid by any student. If a teacher decides to
        teach extra, good for her, but if there are no revenues, the teacher must've been warned**/
  },

  getTeacherIdsWithUnpaidMeetings: async () => {
    const meetings = await api.courseSvcRequest('GET', `/meetings?unpaid=true`);
    const teacherIds = meetings.map(m => m.teacherId);
    return Array.from(new Set(teacherIds));
  },

  createMeetingBatch: async (batch) => {
    return api.courseSvcRequest('POST', '/meetingBatches', batch);
  },

  rescheduleMeeting: async (meeting) => {
    return api.courseSvcRequest('PUT', `/meetings/${meeting._id}`, meeting);
  },

  deleteMeeting: async (meeting) => {
    return api.courseSvcRequest('DELETE', `/meetings/${meeting._id}`);
  },
  
  resetMeetingReport: async (meeting) => {
    return api.courseSvcRequest('DELETE', `/reports/dummy?meetingId=${meeting._id}`);
  },

  batchReschedule: async (batchReschedule) => {
    return api.courseSvcRequest('POST', `/meetingBatchReschedulings`, {courseId: batchReschedule.courseId});
  },

  transferCredit: async ({sourceCourseId, destinationCourseId, fromStudentId, toStudentId, numHours}) => {
    return api.courseSvcRequest('POST', `/creditTransfers`, {sourceCourseId, destinationCourseId, fromStudentId, toStudentId, numHours});
  },

  getRecordingsForMeeting: async (meetingId) => {
    return api.courseSvcRequest('GET', `/recordings?startsWith=${meetingId}`);
  },

  listAllRecordings: async () => {
    return api.courseSvcRequest('GET', '/recordings');
  }
};

export function getForeignTeacherIds() {
  return [
    "5dc28d1e42de8614635a34fe",
    "5db7f89f5e617c70e3c44936",
    // "5e37c973a648c105c79fc202", //Daigene //She would ignore guidelines for classes
    "5e3c02d6a648c105c79fc203",
    "5eb9645592ad124eb1629444",
    "5eba734492ad124eb1629446", //Gracie
    "5ebc37851f4ca410608c7cce",
    "5f2b633969272c5e0294de94",
    "5f2d86007b929670e25b26b5",
    "5f2d07c87b929670e25b26b4",
    "5f2db6c07b929670e25b26b6",
    // "5f53c26b0ee2fe92d8119e98",  //Ivy   // Became a software developer
    "5fb6aec3d7be2400479c8de7",  //Garnet  //She used to take too much in, then skip classes
    "5f8d60909a9dd709dee89a7d",  //Joan
    "60125b71d075595bcce4dec4",  //Aicks
    //"601d1bdc4b2631552881e924",  //Sarah
    "60280e1913251116f75069bf",  //Maureen
    // "6052da09a98e58ab3e63dd14", //Kenneth //he canceled classes with half an hour notice for reasons as solid as "it's my mum's birthday", or he would be completely uncontactable
    "605903920f84575c6cf9e87a", //Jeremie //he's stopping to teach every 3 or 4 months, standing students up pretty badly....
    "606349cb8fb7770464b98c0b", //Pauline
    "60682dd742177b4d70b7835e", //Jhamae
    "6089baf24b25e2a6a886778e", //Kim,
    "60916711d600961da026bd1b", //Maldrin
    "60a686beaebcf10423d5142b", //Lury
    "60af7ca2047cce9c04f9e7bd", //Fatima
    "60c6c50dd5a66275799502dd", //Elaine
    "60d45a51aa3748a62e56b4fe", //David Richert
    //"60e14e9dcced2080e4f72ea3", //Jomer    //Moved to Canada
    "60f12c2e1fcdd2c251a26ffe", //Puri
    //"60e2a769cced2080e4f72ea7", //Agnes    //She hasn't taken any courses in ages
    "61262169a8a7b17e684b0836", //Jan //he rejects students after committing to teach them, whatever alternative schedules we propose, without clearly stating that he just doesn't want to teach them
    "613f533a71ecf012d8cd09b3", //Lyn
    "61474e743393319cfadcf87c", //Liberty
    "614e1958fc953303ccb28eb0", //Hannah
    //"61507c9aa0cf524e8df9d6dd", //Maryan Te //Completely unresponsive, skipping classes like there's no tomorrow, yet accepting proposals, then missing the trial class
    "615680e520909a9a6e4267e1", //Erika
    "615b26c5c7de77dc8050c6c6", //Memory
    "6161687335217a39c98a5ead", //Kaeronie
    //"616eca993bb742ef199a6574", //Abednigo
    // "617250f2a99eca24f476696c", //Stella   //I don't know who she is
    //"6158961f486954c292bc310f", //Nancy  //purportedly sick. Then she disappeared completely for a month without any further communication
    // "617a21532c567a723841bd81", //René     //I think she never took any course
    "618691d2362529329428d264", //Vin
    // "618115194e74dfccce0b80ff", //Proverbs //Not sure he ever took any course
    "61810b984e74dfccce0b80fc", //Teresa
    "619b61f158409a21177a9779", //Jane
    //"61c589b1eb8fc732b5edf8dd", //Justine   //Not sure she ever took any course
    "61b02d9f1c2420231fe83469", //Peachy
    //"61de8c3379cf5c57bffd15cc", //Marialla  //Not sure she ever took any course
    "61ddc8fe79cf5c57bffd15c2", //Alexandra
    //"61e78f97100bbb1d6ede36a0", //Shekinah  //Not sure she ever took any course
    //"61ff6a5b1c879e1aeae10e69", //Maria Isabelle  //Not responsive any more
    "61ff66961c879e1aeae10e68", //Lekha
    "6209fdfab744468903b3272c", //Libian
    //"6225d8938c480aab4769bd44", //Nathan He has been rescheduling and skipping classes without prior warning for a while
    "623d70e7e970198dff235bb3", //Zoe
    "624d7f5e29010c474eb82f0b", //Derek
    "626150af8c9f128a4dd73bc1", //Recka
    // "6260285bf1e13164f34bdbe0", //Anni She started by stating that teaching grammar is necessary to teach English, continued by rejecting students after she had accepted them and the trial had happened, 
    // then asking us to hold her balance because otherwise she spent the money, then blocking my phone number because she texted me at 6 am and I didn't reply immediately and then calling me an asshole when I told her
    // that she had reported the same meeting twice, which she had
    "62665ee7b0a75cc52e9280e8", //Van
    "627ca2f68ae28ad9ac01e6ec", //Rowena
    "627a114492dc7daff25a6928", //Alvi
    "628729afcf9a0b9260ede741", //Kel
    //"62c7670ea3a4d6f6a1fa2cea", //Susan She took holidays, then came back, started accepting proposals again, only to quit after one week
    //"62eb57814548b135ddd92eb7", //Mikayla,
    "6461fceb6104760cf039795b", //Iris
    "64f7dce0bcede7b2d7659bd6", //Simon
    "6546ce04a56ed17e8f9bd4c1", //Cyril
    "66389689e36227ec43621424", //Naddie
  ];
}

export function getIndonesianTeacherIds() {
  return [
    "5cae10727a7d425691acf937", //Lisa Octavia
    // "5cae11ac7a7d425691acf93e", //Jeniffer
    // "5f9152ebeacd1de306a36bda", //Nopriyanto //hasn't taken anything in one year
    "5caf0a44f621ae2ee538dd28", //Frederick
    "5d1eda54f9e2b9089ae74ebb", //Robinson
    "5d2dabf73223f176fafff961", //Teddy
    "6063442f8fb7770464b98c0a", //Malti
    //"60f84c866e33f962b5169c28", //Siena
    "60fd26fdbc04c1df5aa5f1ac", //Azka  //He would ignore guidance for teaching approach
    "6116492055c9be72b9f29133", //Winda
    "61279dc1a8a7b17e684b086e", //Vivian
    // "6127a51aa8a7b17e684b0872", //Afif
    "61363783b23d01deabd90b89", //Alberta
    "61362eeeb23d01deabd90b85", //Iliya
    "61431bdf397c204a1a04c69b", //Esther Novita
    "614c1d3e006887f290fdfbb8", //Aliva
    "617257e3a99eca24f476696d", //Anita
    "6195f64c621ec1f86b52ddaa", //Syeny
    "61a31d1690c0da7fe13d3b84", //Cindy Caroline
    "61c578564bd21c25a9eabf1f", //Siti Nur
    "61d86c2c6280812ba91605d4", //Ika
    "61f23048e3c96db9058e3066", //Annysa
    "62664dd9b0a75cc52e9280e6", //Jane Siswanto
    "62774120337a9983a7c01599", //Renna
  ];
}

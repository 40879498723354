import React from 'react';
import {Button} from "../controls/button";
import moment from 'moment-timezone';
import {courseApi} from "../api/courseApi";
import {Spinner} from "../controls/spinner";
import history from "../history/history";

export class MeetingRescheduler extends React.Component {
  constructor (props) {
    super(props);
    if (!props.location.state || !props.location.state.meeting) {
      history.push('/courses');
      return;
    }
    this.meeting = props.location.state.meeting;
    this.tz = props.location.state.timezone;
    if (this.meeting.startTime) {
      const startTime = moment.tz(this.meeting.startTime, this.tz);
      this.state = {
        newDate: startTime.format("YYYY-MM-DD"),
        newTime: startTime.format("HH:mm"),
        newDuration: this.meeting.durationMins,
      };
    } else {
      this.state = {
        newDate: null,
        newTime: null,
        newDuration: this.meeting.durationMins
      }
    }
    this.newDateChanged = this.newDateChanged.bind(this);
    this.newTimeChanged = this.newTimeChanged.bind(this);
    this.newDurationChanged = this.newDurationChanged.bind(this);
    this.reschedule = this.reschedule.bind(this);
  }

  render() {
    return (
      <div>
        <div>Date</div>
        <input type="date" value={this.state.newDate} onChange={this.newDateChanged}/>
        <div>Time (in {this.tz} timezone)</div>
        <input type="time" value={this.state.newTime} onChange={this.newTimeChanged}/>
        <div>Duration (minutes)</div>
        <input type="number" value={this.state.newDuration} onChange={this.newDurationChanged}/>
        <div>
          <Button text={!this.state.submitting && "Reschedule"} onClick={this.reschedule} noArrow={this.state.submitting}>
            {this.state.submitting && <Spinner/>}
          </Button>
        </div>
      </div>
    );
  }

  newDateChanged(ev) {
    this.setState({newDate: ev.target.value});
  }

  newTimeChanged(ev) {
    this.setState({newTime: ev.target.value});
  }

  newDurationChanged(ev) {
    this.setState({newDuration: ev.target.value});
  }

  async reschedule() {
    this.setState({submitting: true});
    const st = this.state;
    const m = this.meeting;
    if (st.newDate) {
      m.startTime = moment.tz(`${st.newDate} ${st.newTime}`, this.tz).utc();
    } else {
      delete(m.startTime);
    }
    m.durationMins = st.newDuration;
    try {
      await courseApi.rescheduleMeeting(m);
      history.push(`/courses/${m.courseId}/meetings`);
    } finally {
      this.setState({submitting: false});
    }
  }
}
import React from "react";

export const supportedPreferredCurrencies = [
  "GHS",
  "IDR",
  "PHP",
  "USD",
  "ZAR",
  "EUR",
];

export const currencyLabels = {
  GHS: "Ghanian Cedis",
  IDR: "Indonesian Rupiah",
  PHP: "Philippine Pesos",
  USD: "United States Dollars",
  ZAR: "South African Rands",
  EUR: "Euros",
};
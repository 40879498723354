import React, {useEffect, useState} from "react";
import homeStyle from "../main/home.scss";
import style from "./itineraryEditor.scss";
import {Menu} from "../menu/menu";
import {Spinner} from "../controls/spinner";
import {conversationApi} from "../api/conversationApi";
import {Button} from "../controls/button";
import {groupById} from "../util/groupById";
import {NeatFieldValue} from "../controls/neatFieldValue";
import {RemoveButton} from "../controls/removeButton";
import history from '../history/history';


export function ItineraryEditor(props) {
  const {itineraryId} = props.match.params;
  const [itinerary, setItinerary] = useState(null);
  const [scenariosById, setScenariosById] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addingStep, setAddingStep] = useState(false);
  const [editingName, setEditingName] = useState(false);
  
  useEffect(() => {
    loadState();
  }, []);
  
  async function loadState() {
    await loadItinerary();
    await loadScenarios();
    setLoading(false);
  }
  
  async function loadItinerary() {
    setItinerary(await conversationApi.getItinerary(itineraryId));
  }
  
  async function loadScenarios() {
    setScenariosById(groupById(await conversationApi.getAllScenarios()));
  }
    
  return (
    <div className={homeStyle.home}>
      <Menu/>
      <div className={homeStyle.rightSidePanel}>
        <div className={homeStyle.list}>
          <div style={{padding: "20px"}}>
            {loading && <Spinner/>}
            <NeatFieldValue label={"Name"}>
              {editingName ?
              <>
                <input
                type="text"
                value={itinerary?.name}
                onChange={async e => {
                  const name = e.target.value;
                  setItinerary({...itinerary, name});
                }}
                />
                <Button text="Save" onClick={() => updateAndReload(itinerary)} noArrow/>
              </> :
              <>
                <div>{itinerary?.name}</div>
                <Button text="Edit" onClick={() => setEditingName(true)} noArrow/>
              </>
              }
            </NeatFieldValue>
            <NeatFieldValue label={"Is active?"}>
              <select 
                value={itinerary?.isActive}
                onChange={async e => {
                  const isActive = JSON.parse(e.target.value);
                  updateAndReload({...itinerary, isActive});
                }}
              >
                <option value={true}>yes</option>
                <option value={false}>no</option>
              </select>
            </NeatFieldValue>
            <NeatFieldValue label={"Scenarios"}>
              {!loading && itinerary?.steps.sort((st1, st2) => st1.ordinal - st2.ordinal).map(st =>
                <div className={style.itineraryStep} key={st._id} onClick={() => history.push(`/scenarios/${st.scenarioId}`)}>
                  <Ordinal itineraryStep={st}/>
                  {scenariosById && scenariosById[st.scenarioId].name}
                  <RemoveButton onClick={() => removeStep(st)}/>
                </div>
              )}
              {!loading && addingStep ?
                <ScenarioPicker onScenarioSelected={scenarioForNewStepSelected}/> :
                <Button onClick={addStep} disabled={addingStep} noArrow>
                  Add scenario
                </Button>
              }
            </NeatFieldValue>
          </div>
        </div>
      </div>
    </div>
  );
  
  async function addStep() {
    setAddingStep(true);
  }
  
  async function removeStep(step) {
    const newItinerary = {...itinerary};
    newItinerary.steps = newItinerary.steps.filter(st => st.scenarioId !== step.scenarioId || st.ordinal !== step.ordinal);
    await updateAndReload(newItinerary);
  }
  
  async function scenarioForNewStepSelected(scenarioId) {
    const newItinerary = {...itinerary}; 
    newItinerary.steps.push({scenarioId, ordinal: 0})
    await updateAndReload(newItinerary);
  }
  
  async function updateAndReload(newItinerary) {
    setLoading(true)
    setAddingStep(false);
    setEditingName(false);
    await conversationApi.updateItinerary(itineraryId, newItinerary);
    await loadItinerary();
    setLoading(false);
  }
  
  function ScenarioPicker({onScenarioSelected}) {
    const [selectedScenarioId, setSelectedScenarioId] = useState(Object.values(scenariosById)[0]._id);
    
    return (<div className={style.scenarioPicker}>
      <select onChange={onSelectionChanged}>
        {Object.values(scenariosById).map(s => <option value={s._id}>{s.name}</option>)}
      </select>
      <Button onClick={() => onScenarioSelected(selectedScenarioId)}>Add</Button>
    </div>);
    
    function onSelectionChanged(ev) {
      setSelectedScenarioId(ev.target.value);
    }
  }
  
  function Ordinal({itineraryStep}) {
    const [editing, setEditing] = useState(false);
    const [newStepOrdinal, setNewStepOrdinal] = useState(itineraryStep.ordinal);
    
    return (
      <div className={style.ordinal} onClick={() => setEditing(true)}>
        {editing && 
          <input autoFocus={true} 
                 type="text" value={newStepOrdinal} 
                 onChange={ev => setNewStepOrdinal(Number(ev.target.value) || 0)}
                 onKeyPress={ev => {
                   if (ev.key === "Enter") ordinalEdited()
                 }}
                 onBlur={() => ordinalEdited()}
          />
        }
        {!editing && <span>{itineraryStep.ordinal}</span>}
      </div>
    );
  
    async function ordinalEdited() {
      itineraryStep.ordinal = newStepOrdinal;
      await conversationApi.updateItinerary(itinerary._id, itinerary);
      loadItinerary();
    }
  }
  
}
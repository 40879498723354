import React from 'react';
import {Button} from "../controls/button";
import {whatsappApi} from "../api/whatsappApi";


export class WhatsappQrCode extends React.Component {
  constructor(props) {
    super(props);
    this.refreshQrCode = this.refreshQrCode.bind(this);
    this.screenshot = this.screenshot.bind(this);
    this.state = {
      currentQrCode: null,
      screenshotUrl: ""
    }
  }
  render() {
    return (
      <div style={{width: "300px"}}>
        <Button text="Refresh" onClick={this.refreshQrCode}/>
        <div dangerouslySetInnerHTML={{__html: this.state.currentQrCode}}/>
        <Button text="Take screenshot" onClick={this.screenshot}/>
        <img src={this.state.screenshotUrl}/>
      </div>
    );
  }

  async refreshQrCode() {
    await whatsappApi.refreshQrCode();
    this.setState({currentQrCode: await whatsappApi.getCurrentQrCode()});
  }

  async screenshot() {
    this.setState({screenshotUrl: await whatsappApi.getScreenshot()}) ;
  }
}


import React from 'react';
import {teacherApi} from "../api/teacherApi";
import {Field} from "formik";
import Select from "react-select";

export class TeacherSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = { teachers: [] };
    teacherApi.searchTeachers()
    .then(teachers => this.setState({teachers: teachers.sort((t1, t2) => t1.name > t2.name ? 1 : -1)}));
  }

  render() {
    const {name, value} = this.props.field;
    const form = this.props.form;
    let selectedOption = null;
    const options = [
      {value: "", label: "Choose a teacher"},
      ...this.state.teachers.map(t => {
        const option = {value: t._id, label: t.name};
        if (t._id === value) selectedOption = option;
        return option;
      })
    ];
    return (
      <Select style={{width: 300}} options={options} value={selectedOption} onChange={v => form.setFieldValue(name, v.value)}/>
    );
  }
}
import {api} from './api';

export const conversationApi = {
  getScenario: async (scenarioId) => {
    return api.conversationSvcRequest('GET', `/scenarios/${scenarioId}`);
  },
  
  updateScenario: async (scenarioId, scenario) => {
    return api.conversationSvcRequest('PUT', `/scenarios/${scenarioId}`, scenario);
  },
  
  createScenario: async (scenario) => {
    return api.conversationSvcRequest('POST', `/scenarios`, scenario);
  },
  
  getAllScenarios: async () => {
    return api.conversationSvcRequest("GET", "/scenarios");
  },
  
  getActiveScenario: async () => {
    return api.conversationSvcRequest("GET", "/scenarios/active");
  },
  
  setActiveScenario: async (s) => {
    return api.conversationSvcRequest("PUT", "/scenarios/active", s);
  },
  
  getItinerary: async (itineraryId) => {
    return api.conversationSvcRequest('GET', `/itineraries/${itineraryId}`);
  },
  
  createItinerary: async (itinerary) => {
    return api.conversationSvcRequest('POST', `/itineraries`, itinerary);
  },
  
  updateItinerary: async (itineraryId, itinerary) => {
    return api.conversationSvcRequest('PUT', `/itineraries/${itineraryId}`, itinerary);
  },
  
  getAllItineraries: async () => {
    return api.conversationSvcRequest("GET", "/itineraries");
  },
  
  getAllCharacters: async () => {
    return api.conversationSvcRequest("GET", "/characters");
  },
  
  getCharacter: async (characterId) => {
    return api.conversationSvcRequest("GET", `/characters/${characterId}`);
  },
  
  createCharacter: async (character) => {
    return api.conversationSvcRequest("POST", "/characters", character);
  },
  
  updateCharacter: async (characterId, character) => {
    return api.conversationSvcRequest("PUT", `/characters/${characterId}`, character);
  },
  
  getConversationsForScenario: async(scenarioId) => {
    return api.conversationSvcRequest("GET", `/conversations?scenarioId=${scenarioId}`)
  }
}

import jwtDecode from 'jwt-decode';

const tokenKey = 'cerah.accessToken';
const userKey = 'cerah.userId';

export const userSession = {
  userChanged: (token) => {
    window.localStorage.setItem(userKey, jwtDecode(token.accessToken).userId);
    window.localStorage.setItem(tokenKey, token.accessToken);
  },

  getUserId: () => {
    return window.localStorage.getItem(userKey);
  },

  getToken: () => {
    return window.localStorage.getItem(tokenKey);
  },

  getUserGroups: () => {
    if (!userSession.getToken()) return [];
    return jwtDecode(userSession.getToken()).groups;
  }
};